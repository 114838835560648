import { S3ObjectParentType } from 'khshared/API';
import { Logger } from 'pino';
import { useEffect, useState } from 'react';

import genChildDocumentURL from './genChildDocumentURL';

type FetchMultipleParams = {
  list: {
    parentID: string;
    parentType: S3ObjectParentType;
    s3ObjectID?: string | null;
  }[];
  parentID?: never;
  parentType?: never;
  s3ObjectID?: never;
  logger: Logger;
};

type FetchSingleParams = {
  list?: never;
  parentID: string | null | undefined;
  parentType: S3ObjectParentType | null | undefined;
  s3ObjectID: string | null | undefined;
  logger: Logger;
};

type FetchFileParams = FetchMultipleParams | FetchSingleParams;

type FetchFileResult<T extends FetchFileParams> = {
  isLoading: boolean;
  error: Error | null;
  result: T extends FetchMultipleParams ? Record<string, string> | null : string | null;
};

/**
 * Hook to fetch signed URLs for S3 objects. Can fetch either a single URL or multiple URLs.
 *
 * @param params.list - Array of objects containing parentID, parentType and s3ObjectID for fetching multiple URLs
 * @param params.parentID - Parent ID for fetching single URL
 * @param params.parentType - Parent type (e.g. TECH, PATIENT) for fetching single URL
 * @param params.s3ObjectID - S3 object ID for fetching single URL
 * @param params.logger - Logger instance
 * @returns Object containing:
 *   - isLoading: boolean indicating if fetch is in progress
 *   - error: Error object if fetch failed, null otherwise
 *   - result: For single URL fetch: URL string or null
 *            For multiple URL fetch: Record mapping s3ObjectID to URL string, or null
 */
export default function useFetchFileURLsByS3ObjectIDs<T extends FetchFileParams>(
  params: T,
): FetchFileResult<T> {
  const [state, setState] = useState<FetchFileResult<T>>({
    isLoading: false,
    error: null,
    result: null,
  });

  const shouldFetchKey =
    params.list != null
      ? params.list.length
        ? `list|${params.list
            .map((item) => item.s3ObjectID)
            .sort()
            .join('|')}`
        : null
      : params.s3ObjectID != null
      ? `single|${params.s3ObjectID}`
      : null;

  useEffect(() => {
    if (!shouldFetchKey) return;
    let fetchFunction;
    if (params.list != null) {
      const { list } = params;
      fetchFunction = async () => {
        if (list == null) return;
        try {
          setState((prev) => ({ ...prev, isLoading: true, error: null }));
          const listWithURLs = (
            await Promise.allSettled(
              list.map(async (item) => {
                if (item.s3ObjectID == null) return null;
                const url = await genChildDocumentURL({
                  parentID: item.parentID,
                  parentType: item.parentType,
                  s3ObjectID: item.s3ObjectID,
                  logger: params.logger,
                });
                return { id: item.s3ObjectID, url };
              }),
            )
          ).filter(
            (r): r is PromiseFulfilledResult<{ id: string; url: string }> =>
              r.status === 'fulfilled',
          );

          const map = listWithURLs.reduce((agg, item) => {
            // eslint-disable-next-line no-param-reassign
            agg[item.value.id] = item.value.url;
            return agg;
          }, {} as Record<string, string>);

          setState({ result: map, isLoading: false, error: null } as FetchFileResult<T>);
        } catch (e: unknown) {
          setState({ error: e as Error, isLoading: false, result: null });
        }
      };
    } else {
      const { parentID, parentType, s3ObjectID } = params;
      if (!s3ObjectID || !parentID || !parentType) return;
      fetchFunction = async () => {
        try {
          setState((prev) => ({ ...prev, isLoading: true, error: null }));
          const url = await genChildDocumentURL({
            parentID,
            parentType,
            s3ObjectID,
            logger: params.logger,
          });
          setState({ result: url, isLoading: false, error: null } as FetchFileResult<T>);
        } catch (e: unknown) {
          setState({ error: e as Error, isLoading: false, result: null } as FetchFileResult<T>);
        }
      };
    }
    void fetchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchKey]);

  return state;
}
