/* eslint-disable react/jsx-props-no-spreading */
import KHColors from 'khshared/KHColors';
import React, { useContext } from 'react';
import { Text, TextStyle } from 'react-native';

import KHThemeContext from './KHThemeContext';

const styles = {
  // Styles
  body: { fontSize: 14, color: KHColors.text } as TextStyle,
  bodyV2: {
    fontSize: 14,
    color: KHColors.text,
    fontFamily: 'Red Hat Display',
    lineHeight: 20,
    letterSpacing: 0.16,
  } as TextStyle,
  caption: { fontSize: 12, color: KHColors.textSecondary } as TextStyle,
  captionContrast: { fontSize: 12, color: KHColors.textSecondaryLight } as TextStyle,
  captionError: { fontSize: 12, color: KHColors.textError } as TextStyle,
  h16: { fontSize: 16, color: KHColors.text } as TextStyle,
  secondary: { fontSize: 14, color: KHColors.textSecondary } as TextStyle,
  // Modifiers
  bold: { fontWeight: '500' } as TextStyle,
  boldV2: { fontWeight: '700' } as TextStyle,
};

type TextProps = React.ComponentProps<typeof Text>;
type TextPropsWithContrast = TextProps & { contrast?: boolean };

function Body({ style, ...textProps }: TextProps): JSX.Element {
  const theme = useContext(KHThemeContext);
  return <Text style={[theme === 'v2' ? styles.bodyV2 : styles.body, style]} {...textProps} />;
}

function BodyBold({ style, ...textProps }: TextProps): JSX.Element {
  const theme = useContext(KHThemeContext);
  return (
    <Text
      style={[styles.body, theme === 'v2' ? styles.boldV2 : styles.bold, style]}
      {...textProps}
    />
  );
}

function Caption({ contrast, style, ...textProps }: TextPropsWithContrast): JSX.Element {
  return (
    <Text style={[contrast ? styles.captionContrast : styles.caption, style]} {...textProps} />
  );
}

function CaptionError({ style, ...textProps }: TextProps): JSX.Element {
  return <Text style={[styles.captionError, style]} {...textProps} />;
}

function H16({ style, ...textProps }: TextProps): JSX.Element {
  return <Text style={[styles.h16, style]} {...textProps} />;
}

function Secondary({ style, ...textProps }: TextProps): JSX.Element {
  return <Text style={[styles.secondary, style]} {...textProps} />;
}

export default { Body, BodyBold, Caption, CaptionError, H16, Secondary };
