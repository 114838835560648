import React, { ReactNode } from 'react';
import { Platform, Text, TextStyle } from 'react-native';

import ErrorBoundary from '../xapis/ErrorBoundaryXAPI';
import { KHButton, KHErrorText, KHMessageView } from '.';

const styles = {
  title: {
    marginVertical: 12,
    fontSize: 20,
  } as TextStyle,
};

interface Props {
  children: ReactNode;
  tags: Record<string, string>;
  includeStackTrace?: boolean;
}

export default function KHErrorBoundary({
  children,
  tags,
  includeStackTrace = false,
}: Props): JSX.Element {
  return (
    <ErrorBoundary
      beforeCapture={(scope) => scope.setTags(tags)}
      fallback={({ error, componentStack, resetError }) => {
        const isChunkLoadingError = /loading chunk \d+ failed/i.test(error.message);
        if (Platform.OS === 'web' && isChunkLoadingError) {
          window.location.reload();
          return <></>;
        }
        return (
          <KHMessageView message="Something went wrong" title="Uh-oh!" icon="alert">
            <KHButton
              onPress={() => {
                if (Platform.OS === 'web') window.location.reload();
                resetError();
              }}
            >
              Try again?
            </KHButton>

            <Text style={styles.title}>Error</Text>

            <KHErrorText error={error} />

            {includeStackTrace && (
              <>
                <Text style={styles.title}>Stack</Text>
                <KHErrorText error={componentStack} />
              </>
            )}
          </KHMessageView>
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
