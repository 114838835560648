import KHColors from 'khshared/KHColors';
import React, { ReactNode, useContext } from 'react';
import { Pressable, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';

import KHButton from './KHButton';
import KHThemeContext from './KHThemeContext';

const styles = {
  view: {
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: 24,
    minWidth: 24,
  } as ViewStyle,
  buttonContainer: {
    alignSelf: 'stretch',
  } as ViewStyle,
  label: {
    marginTop: 2,
    marginBottom: 5,
    marginLeft: 12,
    color: KHColors.checkboxLabel,
  } as TextStyle,
  labelV2: {
    color: KHColors.textInputLabelV2,
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'Red Hat Display',
    lineHeight: 20,
    letterSpacing: 0.16,
  } as TextStyle,
  labelDisabled: {
    color: KHColors.checkboxLabelDisabled,
  } as TextStyle,
};

interface CommonProps {
  disabled?: boolean;
  iconColor?: string;
  label?: ReactNode;
  labelStyle?: StyleProp<TextStyle>;
  isLabelPressable?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  testID?: string;
}

interface CheckboxProps extends CommonProps {
  mode?: 'checkbox';
  status: 'checked' | 'unchecked' | 'indeterminate';
}

interface RadioProps extends CommonProps {
  mode: 'radio';
  status: 'checked' | 'unchecked';
}
function constructTestIDForCheckbox(
  testID: string | undefined,
  label: ReactNode,
): string | undefined {
  if (testID) return testID;
  if (label && typeof label === 'string')
    return `${label.replace(/[\W_]+/g, '')}-checkbox`.toLowerCase();
  return undefined;
}

export default function KHCheckbox({
  disabled = false,
  iconColor,
  label,
  labelStyle,
  isLabelPressable = false,
  mode = 'checkbox',
  onPress,
  status,
  style,
  buttonStyle,
  testID,
}: CheckboxProps | RadioProps): JSX.Element {
  const theme = useContext(KHThemeContext);
  let icon;
  if (mode === 'checkbox') {
    if (status === 'indeterminate') {
      icon = 'checkbox-intermediate';
    } else {
      icon = status === 'unchecked' ? 'checkbox-blank-outline' : 'checkbox-marked';
    }
  } else {
    icon = status === 'checked' ? 'radiobox-marked' : 'radiobox-blank';
  }

  return (
    <Pressable onPress={isLabelPressable ? onPress : undefined} disabled={disabled}>
      <View style={[styles.view, style]} testID={constructTestIDForCheckbox(testID, label)}>
        <View style={styles.buttonContainer}>
          <KHButton
            textOnly
            icon={icon}
            iconColor={
              iconColor ?? (status === 'checked' && !disabled)
                ? KHColors.checkboxSelected
                : KHColors.checkbox
            }
            onPress={onPress}
            disabled={disabled}
            accessibilityRole={mode === 'checkbox' ? 'checkbox' : 'radio'}
            accessibilityState={{ checked: status === 'checked', disabled }}
            accessibilityChecked={status === 'checked'}
            style={buttonStyle}
            testID="kh-checkbox-toggle-button"
          />
        </View>
        {Boolean(label) && (
          <Text
            testID="kh-checkbox-label"
            selectable={false}
            numberOfLines={10}
            style={[
              styles.label,
              theme === 'v2' && styles.labelV2,
              disabled && styles.labelDisabled,
              labelStyle,
            ]}
          >
            {label}
          </Text>
        )}
      </View>
    </Pressable>
  );
}
