const phoneRegex = /[+]?[1]?([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/;

/**
 * This is using terminology from https://linkedphone.com/blog/what-are-the-different-parts-of-a-phone-number-called/
 */
type PhoneFormatTypes = 'text' | 'uri' | 'numbersOnly' | 'areaCode' | 'prefixAndLineNumber';

function formatPhone(phone: string, format?: PhoneFormatTypes): string;
function formatPhone(phone: null | undefined, format?: PhoneFormatTypes): null;
function formatPhone(phone: string | null | undefined, format?: PhoneFormatTypes): string | null;
function formatPhone(phone: string | null | undefined, format?: PhoneFormatTypes): string | null {
  if (!phone) return null;

  if (format === 'uri') return `tel:${phone}`;

  // Adding a check for if the phone number starts with a + but does not continue with a "1".
  // This will account for the phone numbers in our config files.
  if (phone?.[0] === '+' && phone?.[1] !== '1') return phone;

  const parts = phoneRegex.exec(phone);

  // In the case when for some reason, no parts are generated, or if there are not enough parts to
  // process, then return the input value.
  if (
    !parts ||
    parts.length < 3 ||
    parts[1].length !== 3 ||
    parts[2].length !== 3 ||
    parts[3].length !== 4
  ) {
    return phone;
  }

  if (format === 'areaCode') {
    return parts[1];
  }

  if (format === 'prefixAndLineNumber') {
    return `${parts[2]}${parts[3]}`;
  }

  return format === 'numbersOnly'
    ? `${parts[1]}${parts[2]}${parts[3]}`
    : `(${parts[1]}) ${parts[2]}-${parts[3]}`;
}

export default formatPhone;

/**
 * Phone numbers with an area code or central office code of 555 are assumed to be fictitious and ignored: https://en.wikipedia.org/wiki/555_(telephone_number)
 * This explicitly does not filter out Toll-Free numbers as some of our partners utilize them.
 * @param phone
 * @returns {boolean} A boolean representing if the phone number is a "555 number".
 */
export function isPhoneNumber555AreaOrCentralOfficeCode(phone: string): boolean {
  const parts = phoneRegex.exec(phone);
  if (!parts || parts.length < 1 || !parts[1]) return false;

  return parts[1] === '555' || (parts.length > 2 && parts[2] === '555');
}
