import React, { MutableRefObject, forwardRef, useEffect, useRef, useState } from 'react';
import { TextInput } from 'react-native';
import isEmail from 'validator/lib/isEmail';

import useUpdateEffect from '../utils/useUpdateEffect';
import KHTextInput from './KHTextInput';

/* eslint-disable react/require-default-props */
type Props = Omit<React.ComponentProps<typeof KHTextInput>, 'value' | 'onChangeText'> & {
  onChangeEmail?: (email: string | null, hasInvalidEmailInput?: boolean) => void;
  email?: string | null;
  optional?: boolean;
  errorMessage?: string;
};
/* eslint-enable react/require-default-props */

const KHEmailTextInput = forwardRef<TextInput, Props>(
  (
    {
      onChangeEmail,
      email,
      optional,
      label,
      autoComplete,
      errorMessage = 'Please enter a valid email',
      ...khTextInputProps
    }: Props,
    ref: ((instance: TextInput | null) => void) | MutableRefObject<TextInput | null> | null,
  ): JSX.Element => {
    const [emailString, setEmailString] = useState<string>('');
    const [formatError, setFormatError] = useState<string | null>(null);

    const onChangeText = (text: string) => {
      if (isEmail(text)) {
        onChangeEmail?.(text, false);
      } else {
        onChangeEmail?.(null, text.length !== 0);
      }

      setEmailString(text);
      setFormatError(null);
    };

    useEffect(() => {
      if (email != null) setEmailString(email);
    }, [email]);

    return (
      <KHTextInput
        label={label ?? `Email${optional ? ' (optional)' : ''}`}
        placeholder="Email"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...khTextInputProps}
        autoComplete={autoComplete || 'email'}
        textContentType="emailAddress"
        autoCapitalize="none"
        autoCorrect={false}
        keyboardType="email-address"
        onBlur={() => {
          if (!(isEmail(emailString) || (optional && emailString.length === 0)))
            setFormatError(errorMessage);
        }}
        ref={ref}
        value={emailString}
        onFocus={() => setFormatError(null)}
        onChangeText={onChangeText}
        error={formatError ?? khTextInputProps.error}
      />
    );
  },
);

function Debounced({
  email,
  debounceDelayMS = 2000,
  onDebounceStart,
  onChangeEmail,
  hasClearButton,
  onClear,
  right,
  inputReference,
  ...restProps
}: Props & {
  inputReference?: MutableRefObject<TextInput | null>;
  onDebounceStart?: () => void;
  debounceDelayMS?: 500 | 1000 | 2000;
}): JSX.Element {
  const [debouncedValue, setDebouncedValue] = useState<string>(email ?? '');
  const debouncingTimeoutIDRef = useRef<number | null>(null);
  const debouncingTimeoutFunctionRef = useRef<(() => void) | null>(null);

  // Using refs allows us to safely exclude handlers from the useUpdateEffect's deps list below
  // rather than relying on the parent to reliably use useCallback to ensure they don't change.
  const onDebounceStartRef = useRef(onDebounceStart);
  const onChangeTextRef = useRef(onChangeEmail);

  function debounceValueChange(newValue: string | null) {
    if (newValue == null) return;
    setDebouncedValue(newValue);

    if (debouncingTimeoutIDRef.current == null) onDebounceStartRef.current?.();
    else clearTimeout(debouncingTimeoutIDRef.current);

    debouncingTimeoutFunctionRef.current = () => {
      debouncingTimeoutIDRef.current = null;
      onChangeTextRef.current?.(newValue);
    };
    debouncingTimeoutIDRef.current = window.setTimeout(
      debouncingTimeoutFunctionRef.current,
      debounceDelayMS,
    );
  }

  useEffect(() => {
    onDebounceStartRef.current = onDebounceStart;
    onChangeTextRef.current = onChangeEmail;
  });

  useUpdateEffect(() => {
    const newValue = email ?? '';
    if (newValue === debouncedValue) return;

    // Just in case things get out of sync
    if (debouncingTimeoutIDRef.current == null) setDebouncedValue(newValue);
  }, [email]);

  useEffect(
    () => () => {
      if (debouncingTimeoutIDRef.current == null) return;

      // Flush any pending debounced handlers.
      clearTimeout(debouncingTimeoutIDRef.current);
      debouncingTimeoutFunctionRef.current?.();
    },
    [],
  );

  return (
    <KHEmailTextInput
      email={debouncedValue}
      onChangeEmail={debounceValueChange}
      ref={inputReference}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
}

export default Object.assign(KHEmailTextInput, { Debounced });
