import nullThrows from 'nullthrows';

const BLOOD_PRESSURE_REGEX = /[0-9]{2,3}/;

/**
 * Parse blood pressure values that are stored as "<systolic>/<diastolic>" and returns their object
 * representation if they are correctly set as 2 or 3 digits numbers.
 * @param bloodPressure A string of format "<systolic>/<diastolic>""
 * @returns Returns an object representing the systolic and diastolic values. They will contain the original number or null, depending whether they are correctly set as 2 or 3 digits or not.
 */
export default function parseBloodPressure(
  bloodPressure: string,
): { systolic: string | null; diastolic: string | null } {
  // Split the systolic and diastolic values and match both against the RegEx above.
  // If they match the RegEx, we return the original values. Otherwise, we return null.
  const [systolic = null, diastolic = null] = bloodPressure
    .split('/')
    .map((bp) => (BLOOD_PRESSURE_REGEX.exec(bp)?.[0] === bp ? bp : null));

  return {
    systolic,
    diastolic,
  };
}

export function parseBloodPressureOrThrow(
  bloodPressure: string,
): { systolic: string; diastolic: string } {
  // Split the systolic and diastolic values and match both against the RegEx above.
  // If they match the RegEx, we return the original values. Otherwise, we return null.
  const { systolic, diastolic } = parseBloodPressure(bloodPressure);

  return {
    systolic: nullThrows(systolic),
    diastolic: nullThrows(diastolic),
  };
}
