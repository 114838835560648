import getPartnerGroupConfig from './metadata/getPartnerGroupConfig';
import PartnerOrganizationGroup from './metadata/PartnerOrganizationGroup';

export default function formatPartnerOrganizationGroup(
  partnerOrganizationGroup: PartnerOrganizationGroup | undefined | null,
  format: 'displayName',
): string {
  if (partnerOrganizationGroup == null) return '';

  const config = getPartnerGroupConfig(partnerOrganizationGroup);
  switch (format) {
    case 'displayName':
      return config.displayName;
    default:
      return '';
  }
}
