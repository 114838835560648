import formatPartnerOrganizationGroup from 'khshared/formatPartnerOrganizationGroup';
import formatPartnerOrganization from 'khshared/formatters/formatPartnerOrganization';
import KHColors from 'khshared/KHColors';
import getPartnerConfig from 'khshared/metadata/getPartnerConfig';
import React from 'react';
import { Text, TextStyle, View, ViewStyle } from 'react-native';
import { useHistory, useLocation } from 'react-router';

import KHAuth from '../utils/KHAuth';
import KHButton from './KHButton';
import KHConstants from './KHConstants';
import KHIcon from './KHIcon';

const styles = {
  view: {
    marginTop: KHConstants.GUTTER_WIDTH,
    marginHorizontal: 32,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  } as ViewStyle,
  title: {
    fontSize: 32,
    color: KHColors.textDark,
    fontWeight: '500',
  } as TextStyle,
  titleWithBack: {
    fontSize: 18,
    marginLeft: 8,
  } as TextStyle,
  titleRow: {
    flexDirection: 'row',
  } as ViewStyle,
  profileRow: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  profileName: {
    fontWeight: '500',
    marginRight: 12,
  } as ViewStyle,
  subtitle: {
    fontSize: 12,
    fontWeight: '400',
    color: KHColors.textSecondary,
  } as TextStyle,
  subtitleWithBack: {
    marginLeft: 56,
  } as TextStyle,
};

interface Props {
  backDisabled?: boolean;
  title?: string;
  subtitle?: string;
}

export default function KHHeader({
  backDisabled = false,

  title,
  subtitle,
}: Props): JSX.Element {
  const history = useHistory();
  const browserLocation = useLocation<{ from: { [key: string]: string } }>();
  const partnerOrganizations = KHAuth.getPartnerOrganizations();
  const partnerOrganizationName =
    partnerOrganizations.length > 0
      ? partnerOrganizations.length > 1
        ? formatPartnerOrganizationGroup(
            getPartnerConfig(partnerOrganizations[0]).partnerOrganizationGroup,
            'displayName',
          )
        : formatPartnerOrganization(partnerOrganizations[0], 'displayName')
      : '';
  const profileName = KHAuth.getEmailOrNull() ?? partnerOrganizationName;

  return (
    <View style={styles.view}>
      {!backDisabled ? (
        <View>
          <View style={styles.titleRow}>
            <KHButton
              textOnly
              icon="chevron-left"
              onPress={() =>
                // If a router state was passed, we preserve it (goBack does not preserve the state unfortunately)
                browserLocation.state != null && browserLocation.state.from?.pathname != null
                  ? history.push(browserLocation.state.from.pathname, browserLocation.state)
                  : history.goBack()
              }
            />
            {title != null && <Text style={[styles.title, styles.titleWithBack]}>{title}</Text>}
          </View>
          {subtitle != null && (
            <Text style={[styles.subtitle, styles.subtitleWithBack]}>{subtitle}</Text>
          )}
        </View>
      ) : (
        <View>
          {title != null && <Text style={styles.title}>{title}</Text>}
          {subtitle != null && <Text style={styles.subtitle}>{subtitle}</Text>}
        </View>
      )}

      <View style={styles.profileRow}>
        <Text style={styles.profileName}>{profileName}</Text>
        <KHIcon source="account-circle" size={24} />
      </View>
    </View>
  );
}
