/* eslint-disable prettier/prettier */
/* Do not edit! Autogenerated by 'yarn build-palette' */
export default {
  purple5: '#f7f6fc',
  purple10: '#f0edfa',
  purple20: '#e1dbf5',
  purple30: '#d2caf0',
  purple40: '#c3b8ec',
  purple50: '#b4a7e7',
  purple60: '#a595e2',
  purple70: '#9683de',
  purple80: '#8772d9',
  purple90: '#7860d4',
  purple100: '#694fd0',
  purple110: '#5e47bb',
  purple120: '#543fa6',
  purple130: '#493791',
  purple140: '#3f2f7c',
  purple150: '#342768',
  purple160: '#2a1f53',
  purple170: '#1f173e',
  purple180: '#150f29',
  purple190: '#0a0714',
  pink5: '#fbf5fe',
  pink10: '#f7ecfd',
  pink20: '#f0dafb',
  pink30: '#e8c8f9',
  pink40: '#e1b6f7',
  pink50: '#daa4f6',
  pink60: '#d291f4',
  pink70: '#cb7ff2',
  pink80: '#c36df0',
  pink90: '#bc5bee',
  pink100: '#b549ed',
  pink110: '#a241d5',
  pink120: '#903abd',
  pink130: '#7e33a5',
  pink140: '#6c2b8e',
  pink150: '#5a2476',
  pink160: '#481d5e',
  pink170: '#361547',
  pink180: '#240e2f',
  pink190: '#120717',
  red5: '#fef5f4',
  red10: '#fdecea',
  red20: '#fcd9d6',
  red30: '#fbc6c2',
  red40: '#fab3ae',
  red50: '#f9a19a',
  red60: '#f88e86',
  red70: '#f77b72',
  red80: '#f6685e',
  red90: '#f5554a',
  red100: '#f44336',
  red110: '#db3c30',
  red120: '#c3352b',
  red130: '#aa2e25',
  red140: '#922820',
  red150: '#7a211b',
  red160: '#611a15',
  red170: '#491410',
  red180: '#300d0a',
  red190: '#180605',
  deepPurple5: '#f7f5fb',
  deepPurple10: '#efebf7',
  deepPurple20: '#e0d7f0',
  deepPurple30: '#d1c3e9',
  deepPurple40: '#c2b0e2',
  deepPurple50: '#b39cdb',
  deepPurple60: '#a388d3',
  deepPurple70: '#9475cc',
  deepPurple80: '#8561c5',
  deepPurple90: '#764dbe',
  deepPurple100: '#673ab7',
  deepPurple110: '#5c34a4',
  deepPurple120: '#522e92',
  deepPurple130: '#482880',
  deepPurple140: '#3d226d',
  deepPurple150: '#331d5b',
  deepPurple160: '#291749',
  deepPurple170: '#1e1136',
  deepPurple180: '#140b24',
  deepPurple190: '#0a0512',
  indigo5: '#f5f6fb',
  indigo10: '#ebedf7',
  indigo20: '#d8dcf0',
  indigo30: '#c5cae8',
  indigo40: '#b2b9e1',
  indigo50: '#9fa8da',
  indigo60: '#8b96d2',
  indigo70: '#7885cb',
  indigo80: '#6573c3',
  indigo90: '#5262bc',
  indigo100: '#3f51b5',
  indigo110: '#3848a2',
  indigo120: '#324090',
  indigo130: '#2c387e',
  indigo140: '#25306c',
  indigo150: '#1f285a',
  indigo160: '#192048',
  indigo170: '#121836',
  indigo180: '#0c1024',
  indigo190: '#060812',
  blue5: '#f3f9fe',
  blue10: '#e8f4fd',
  blue20: '#d2eafc',
  blue30: '#bcdffb',
  blue40: '#a6d5fa',
  blue50: '#90caf9',
  blue60: '#79c0f7',
  blue70: '#63b5f6',
  blue80: '#4dabf5',
  blue90: '#37a0f4',
  blue100: '#2196f3',
  blue110: '#1d87da',
  blue120: '#1a78c2',
  blue130: '#1769aa',
  blue140: '#135a91',
  blue150: '#104b79',
  blue160: '#0d3c61',
  blue170: '#092d48',
  blue180: '#061e30',
  blue190: '#030f18',
  lightBlue5: '#f2fafe',
  lightBlue10: '#e5f6fd',
  lightBlue20: '#ccedfc',
  lightBlue30: '#b3e5fb',
  lightBlue40: '#9adcfa',
  lightBlue50: '#81d4f9',
  lightBlue60: '#67cbf8',
  lightBlue70: '#4ec2f7',
  lightBlue80: '#35baf6',
  lightBlue90: '#1cb1f5',
  lightBlue100: '#03a9f4',
  lightBlue110: '#0298db',
  lightBlue120: '#0287c3',
  lightBlue130: '#0276aa',
  lightBlue140: '#016592',
  lightBlue150: '#01547a',
  lightBlue160: '#014361',
  lightBlue170: '#003249',
  lightBlue180: '#002130',
  lightBlue190: '#001018',
  cyan5: '#f2fbfc',
  cyan10: '#e5f8fa',
  cyan20: '#ccf1f6',
  cyan30: '#b2eaf2',
  cyan40: '#99e4ed',
  cyan50: '#7fdde9',
  cyan60: '#66d6e5',
  cyan70: '#4cd0e0',
  cyan80: '#33c9dc',
  cyan90: '#19c2d8',
  cyan100: '#00bcd4',
  cyan110: '#00a9be',
  cyan120: '#0096a9',
  cyan130: '#008394',
  cyan140: '#00707f',
  cyan150: '#005e6a',
  cyan160: '#004b54',
  cyan170: '#00383f',
  cyan180: '#00252a',
  cyan190: '#001215',
  teal5: '#f2f9f9',
  teal10: '#e5f4f3',
  teal20: '#cceae7',
  teal30: '#b2dfdb',
  teal40: '#99d5cf',
  teal50: '#7fcac3',
  teal60: '#66c0b7',
  teal70: '#4cb5ab',
  teal80: '#33ab9f',
  teal90: '#19a093',
  teal100: '#009688',
  teal110: '#00877a',
  teal120: '#00786c',
  teal130: '#00695f',
  teal140: '#005a51',
  teal150: '#004b44',
  teal160: '#003c36',
  teal170: '#002d28',
  teal180: '#001e1b',
  teal190: '#000f0d',
  green5: '#f6fbf6',
  green10: '#edf7ed',
  green20: '#dbefdc',
  green30: '#c9e7ca',
  green40: '#b7dfb9',
  green50: '#a5d7a7',
  green60: '#93cf96',
  green70: '#81c784',
  green80: '#6fbf73',
  green90: '#5db761',
  green100: '#4caf50',
  green110: '#449d48',
  green120: '#3c8c40',
  green130: '#357a38',
  green140: '#2d6930',
  green150: '#265728',
  green160: '#1e4620',
  green170: '#163418',
  green180: '#0f2310',
  green190: '#071108',
  lightGreen5: '#f9fcf5',
  lightGreen10: '#f3f9ec',
  lightGreen20: '#e7f3da',
  lightGreen30: '#dcedc8',
  lightGreen40: '#d0e7b6',
  lightGreen50: '#c5e1a4',
  lightGreen60: '#b9db92',
  lightGreen70: '#add580',
  lightGreen80: '#a2cf6e',
  lightGreen90: '#96c95c',
  lightGreen100: '#8bc34a',
  lightGreen110: '#7daf42',
  lightGreen120: '#6f9c3b',
  lightGreen130: '#618833',
  lightGreen140: '#53752c',
  lightGreen150: '#456125',
  lightGreen160: '#374e1d',
  lightGreen170: '#293a16',
  lightGreen180: '#1b270e',
  lightGreen190: '#0d1307',
  lime5: '#fcfdf5',
  lime10: '#fafbeb',
  lime20: '#f5f8d7',
  lime30: '#f0f4c3',
  lime40: '#ebf1af',
  lime50: '#e6ed9c',
  lime60: '#e1ea88',
  lime70: '#dce674',
  lime80: '#d7e360',
  lime90: '#d2df4c',
  lime100: '#cddc39',
  lime110: '#b8c633',
  lime120: '#a4b02d',
  lime130: '#8f9a27',
  lime140: '#7b8422',
  lime150: '#666e1c',
  lime160: '#525816',
  lime170: '#3d4211',
  lime180: '#292c0b',
  lime190: '#141605',
  yellow5: '#fdfcf4',
  yellow10: '#fcf9ea',
  yellow20: '#faf3d6',
  yellow30: '#f8edc2',
  yellow40: '#f6e7ae',
  yellow50: '#f4e19a',
  yellow60: '#f2db85',
  yellow70: '#f0d571',
  yellow80: '#eecf5d',
  yellow90: '#ecc949',
  yellow100: '#eac435',
  yellow110: '#d2b02f',
  yellow120: '#bb9c2a',
  yellow130: '#a38925',
  yellow140: '#8c751f',
  yellow150: '#75621a',
  yellow160: '#5d4e15',
  yellow170: '#463a0f',
  yellow180: '#2e270a',
  yellow190: '#171305',
  amber5: '#fffbf2',
  amber10: '#fff8e6',
  amber20: '#fff2cd',
  amber30: '#ffecb4',
  amber40: '#ffe69b',
  amber50: '#ffe083',
  amber60: '#ffd96a',
  amber70: '#ffd351',
  amber80: '#ffcd38',
  amber90: '#ffc71f',
  amber100: '#ffc107',
  amber110: '#e5ad06',
  amber120: '#cc9a05',
  amber130: '#b28704',
  amber140: '#997304',
  amber150: '#7f6003',
  amber160: '#664d02',
  amber170: '#4c3902',
  amber180: '#332601',
  amber190: '#191300',
  orange5: '#fff9f2',
  orange10: '#fff4e5',
  orange20: '#ffeacc',
  orange30: '#ffe0b2',
  orange40: '#ffd599',
  orange50: '#ffcb7f',
  orange60: '#ffc166',
  orange70: '#ffb64c',
  orange80: '#ffac33',
  orange90: '#ffa219',
  orange100: '#ff9800',
  orange110: '#e58800',
  orange120: '#cc7900',
  orange130: '#b26a00',
  orange140: '#995b00',
  orange150: '#7f4c00',
  orange160: '#663c00',
  orange170: '#4c2d00',
  orange180: '#331e00',
  orange190: '#190f00',
  deepOrange5: '#fff6f3',
  deepOrange10: '#ffeee8',
  deepOrange20: '#ffddd2',
  deepOrange30: '#ffccbc',
  deepOrange40: '#ffbba6',
  deepOrange50: '#ffab90',
  deepOrange60: '#ff9a7a',
  deepOrange70: '#ff8964',
  deepOrange80: '#ff784e',
  deepOrange90: '#ff6738',
  deepOrange100: '#ff5722',
  deepOrange110: '#e54e1e',
  deepOrange120: '#cc451b',
  deepOrange130: '#b23c17',
  deepOrange140: '#993414',
  deepOrange150: '#7f2b11',
  deepOrange160: '#66220d',
  deepOrange170: '#4c1a0a',
  deepOrange180: '#331106',
  deepOrange190: '#190803',
  brown5: '#f8f6f5',
  brown10: '#f1eeec',
  brown20: '#e4ddda',
  brown30: '#d6ccc8',
  brown40: '#c9bbb5',
  brown50: '#bcaaa3',
  brown60: '#ae9991',
  brown70: '#a1887e',
  brown80: '#93776c',
  brown90: '#86665a',
  brown100: '#795548',
  brown110: '#6c4c40',
  brown120: '#604439',
  brown130: '#543b32',
  brown140: '#48332b',
  brown150: '#3c2a24',
  brown160: '#30221c',
  brown170: '#241915',
  brown180: '#18110e',
  brown190: '#0c0807',
  blueGray5: '#f7f8f9',
  blueGray10: '#eff2f3',
  blueGray20: '#dfe5e7',
  blueGray30: '#cfd8dc',
  blueGray40: '#bfcbd0',
  blueGray50: '#afbec5',
  blueGray60: '#9fb1b9',
  blueGray70: '#8fa4ad',
  blueGray80: '#7f97a2',
  blueGray90: '#6f8a96',
  blueGray100: '#607d8b',
  blueGray110: '#56707d',
  blueGray120: '#4c646f',
  blueGray130: '#435761',
  blueGray140: '#394b53',
  blueGray150: '#303e45',
  blueGray160: '#263237',
  blueGray170: '#1c2529',
  blueGray180: '#13191b',
  blueGray190: '#090c0d',
  gray5: '#f4f4f4',
  gray10: '#eaeaea',
  gray20: '#d5d5d5',
  gray30: '#c0c0c0',
  gray40: '#acacac',
  gray50: '#979797',
  gray60: '#828282',
  gray70: '#6e6e6e',
  gray80: '#595959',
  gray90: '#444444',
  gray100: '#303030',
  gray110: '#2b2b2b',
  gray120: '#262626',
  gray130: '#212121',
  gray140: '#1c1c1c',
  gray150: '#181818',
  gray160: '#131313',
  gray170: '#0e0e0e',
  gray180: '#090909',
  gray190: '#040404',
  newBrown: '#A76963',
  newYellow40: '#FEF9E4',
  newYellow10: '#FEF9EE',
  newOrange: '#E98670',
  newDarkOrange: '#AB6610',
  newGray10: '#E5E9E6',
  newGray20: '#BBBBC9',
  newGray80: '#6E7591',
  newGray90: '#757582',
  newPurple90: '#441887',
  newPurple85: '#594494',
  newPurple80: '#543B9A',
  newPurple75: '#502997',
  newPurple10: '#8B64D2',
  newBlueGray: '#ECF0FB',
  newRed: '#CF7272',
  navy: '#2a2a4a',
  black: '#000000',
  white: '#FFFFFF',
  neonGreen: '#D1F37B',
  pantone: '#8D5A54',
  lightbrown: '#764B47',
  tan: '#FFC677',
  newYellow: '#F8f4e4',
  referralPurple: '#272564',
  referralBackground: '#EBEAFF',
  referralText: '#434848',
  referralDisabled: '#cac9d2',
  referralNewYellow: '#F0F3E3',
  referralTextCaption: '#737387',
  emailBackgroundWhiteGray: '#F8F8F8',
  emailBackgroundWhiteGrayDark: '#ECECEC',
  emailBackgroundWhitePurple: '#DED8F0',
  emailTextBlueGray: '#616C83',
  emailTextDarkGray: '#202020',
  emailTextGray: '#333333',
  emailTextLightGray: '#414141',
  bookWithRiskTextRed: '#60071B',
};
