import { useEffect, useRef } from 'react';

/**
 * Returns a ref to a boolean that represents whether the component is currently mounted.
 */
export default function useIsMountedRef(): React.MutableRefObject<boolean> {
  const isMountedRef = useRef(true);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    [],
  );

  return isMountedRef;
}
