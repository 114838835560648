import KHColors from 'khshared/KHColors';
import React, { MutableRefObject, ReactElement, useState } from 'react';
import {
  Pressable,
  ScrollView,
  StyleProp,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import KHIcon from './KHIcon';
import KHTextInput from './KHTextInput';

const styles = {
  emptyMessage: {
    fontSize: 14,
  } as TextStyle,
  item: {
    flexDirection: 'row',
    padding: 8,
    paddingVertical: 5,
    alignItems: 'center',
    backgroundColor: KHColors.backgroundContrast,
  } as ViewStyle,
  itemLabel: {
    fontSize: 14,
    marginLeft: 6,
    marginVertical: 6,
  } as TextStyle,
  inputText: {
    fontSize: 14,
    marginLeft: -2,
  },
  suggestionTextBold: {
    fontWeight: 'bold',
  } as TextStyle,
  suggestionsView: {
    flex: 1,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,
    elevation: 7,
  } as ViewStyle,
  hovered: {
    backgroundColor: KHColors.lookupHovered,
  },
};
interface Props<V> extends Omit<React.ComponentProps<typeof KHTextInput.Debounced>, 'value'> {
  loading?: boolean;
  existingQuery?: string | null;
  disabled?: boolean;
  value: V | null;
  suggestions: readonly V[];
  suggestionsStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  contrast?: boolean;
  isStickyFooter?: boolean;
  caption?: string;
  renderValue: (value: V) => string;
  renderSuggestion: (value: V, onChangeValue: (newValue: V | null) => void) => JSX.Element | null;
  renderSuggestionsEmpty: () => JSX.Element | null;
  renderSuggestionsHeader?: (onChangeValue: (newValue: V | null) => void) => JSX.Element | null;
  renderSuggestionsFooter?: (
    currentValue: string,
    onChangeValue: (newValue: V | null) => void,
  ) => JSX.Element | null;
  onChangeQuery: (newQuery: string) => void;
  onChangeValue: (newValue: V | null) => void;
  onClear?: () => void;
  inputReference?: MutableRefObject<TextInput | null>;
  testID?: string;
}

function KHLookup<V>({
  loading = false,
  disabled = false,
  existingQuery = null,
  value,
  suggestions,
  suggestionsStyle,
  style,
  contrast = false,
  isStickyFooter = true,
  caption,
  renderValue,
  renderSuggestion,
  renderSuggestionsHeader,
  renderSuggestionsFooter,
  renderSuggestionsEmpty,
  onChangeQuery,
  onChangeValue,
  onClear,
  inputReference,
  testID,
  ...khTextInputProps
}: Props<V>): JSX.Element {
  const [query, setQuery] = useState(existingQuery || '');
  const [isFocused, setIsFocused] = useState(false);
  const [isDebouncing, setIsDebouncing] = useState(false);

  function onChangeValueInternal(newValue: V | null) {
    setQuery('');
    onChangeValue?.(newValue);
  }

  return (
    <View style={style}>
      <KHTextInput.Debounced
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...khTextInputProps}
        hasClearButton={!disabled}
        value={value != null ? renderValue(value) : query != null ? query : undefined}
        loading={value == null && (loading || isDebouncing)}
        editable={value == null && !disabled}
        inputTextStyle={[styles.inputText, contrast ? { color: KHColors.textContrast } : {}]}
        left={
          <KHTextInput.Icon
            source="magnify"
            color={contrast ? KHColors.iconContrast : KHColors.iconSecondary}
          />
        }
        onChangeText={(newQuery) => {
          if (value != null) return;

          setQuery(newQuery);
          onChangeQuery(newQuery);
          setIsDebouncing(false);
        }}
        onDebounceStart={() => setIsDebouncing(true)}
        onFocus={(...params) => {
          setIsFocused(true);
          khTextInputProps.onFocus?.(...params);
        }}
        onBlur={(...params) => {
          // TODO(dhruv) Set isFocused to false here
          // Currently, this prevents the renderSuggestionHeader/renderSuggestionFooter click from executing so need to investigate and fix.
          khTextInputProps.onBlur?.(...params);
        }}
        onClear={() => {
          onChangeValue?.(null);
          onClear?.();
          setIsDebouncing(false);
        }}
        contrast={contrast}
        inputReference={inputReference}
        caption={caption}
        testID={testID}
      />

      {(query.length > 0 || isFocused) && value == null && !disabled && (
        <View style={styles.suggestionsView}>
          <ScrollView style={suggestionsStyle}>
            {renderSuggestionsHeader?.(onChangeValueInternal)}
            {suggestions.length > 0
              ? suggestions.map((option) => renderSuggestion(option, onChangeValueInternal))
              : query.length > 0 &&
                !loading &&
                !isDebouncing &&
                value == null &&
                renderSuggestionsEmpty?.()}
            {!isStickyFooter && renderSuggestionsFooter?.(query, onChangeValueInternal)}
          </ScrollView>
          {isStickyFooter && renderSuggestionsFooter?.(query, onChangeValueInternal)}
        </View>
      )}
    </View>
  );
}

function Item({
  disabled = false,
  icon,
  color,
  label,
  labelStyle,
  onPress,
  style,
  testID,
}: {
  disabled?: boolean;
  icon?: React.ComponentProps<typeof KHIcon>['source'];
  color?: string;
  label: React.ReactNode;
  labelStyle?: StyleProp<TextStyle>;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
}): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={[styles.item, isHovered && !disabled && styles.hovered, style]}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      testID={testID}
    >
      {icon != null && <KHIcon size={24} source={icon} color={color ?? KHColors.iconPrimary} />}
      <Text numberOfLines={2} style={[styles.itemLabel, labelStyle]}>
        {label}
      </Text>
    </Pressable>
  );
}

function EmptyMessage({
  label,
  labelStyle,
}: {
  label: string | ReactElement<Text>;
  labelStyle?: StyleProp<TextStyle>;
}) {
  return (
    <Item
      disabled
      label={label}
      color={KHColors.textSecondary}
      labelStyle={[styles.emptyMessage, labelStyle]}
    />
  );
}

function Suggestion({
  label,
  query,
  style,
  ...itemProps
}: { query?: string; style?: StyleProp<ViewStyle> } & React.ComponentProps<typeof Item>) {
  let textToBold = '';
  let formattedSuggestion = label;

  if (typeof formattedSuggestion === 'string' && query != null && query?.length > 0) {
    let indexOfLastMatchingAddressChar = -1;
    query.split('').every((c, i) => {
      // Treat two variants of the same base letter as the same unless they have different accents
      const doesCharMatch =
        c.localeCompare((formattedSuggestion as string)[i], undefined, {
          sensitivity: 'accent',
        }) === 0;
      if (doesCharMatch) indexOfLastMatchingAddressChar = i;
      return doesCharMatch;
    });

    textToBold = formattedSuggestion.slice(0, indexOfLastMatchingAddressChar + 1);
    formattedSuggestion = formattedSuggestion.slice(indexOfLastMatchingAddressChar + 1);
  }

  return (
    <Item
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...itemProps}
      style={style}
      label={
        <Text>
          <Text style={styles.suggestionTextBold}>{textToBold}</Text>
          {formattedSuggestion}
        </Text>
      }
    />
  );
}

export default Object.assign(KHLookup, { EmptyMessage, Suggestion });
