import React, { ReactNode } from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import KHConstants from './KHConstants';
import KHText from './KHText';

const styles = {
  view: {
    marginHorizontal: KHConstants.GUTTER_WIDTH,
    marginVertical: 8,
  },
  viewInline: {
    flexDirection: 'row',
  } as ViewStyle,
  label: {
    marginBottom: 4,
  } as TextStyle,
  labelInline: {
    marginBottom: 0,
    marginRight: 4,
  } as TextStyle,
};

interface Props {
  inline?: boolean;
  label?: ReactNode;
  style?: StyleProp<ViewStyle>;
  text?: ReactNode;
}

export default function KHTextField({ inline = false, style, text, label }: Props): JSX.Element {
  const hasTitle = Boolean(label);
  return (
    <View style={[styles.view, inline && styles.viewInline, style]}>
      {hasTitle && (
        <KHText.BodyBold style={[styles.label, inline && styles.labelInline]}>
          {label}
          {inline && ':'}
        </KHText.BodyBold>
      )}
      {hasTitle ? <KHText.Secondary>{text}</KHText.Secondary> : <KHText.Body>{text}</KHText.Body>}
    </View>
  );
}
