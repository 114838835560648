import { ApolloError } from '@apollo/client';

export default function formatError(
  error: string | ApolloError | { message: string } | unknown | undefined | null,
  verbosity: 'simple' | 'verbose' = __DEV__ && process.env.AWS_BRANCH !== 'demonet'
    ? 'verbose'
    : 'simple',
): string | null {
  if (error == null) return null;

  const isVerbose = verbosity === 'verbose';
  return typeof error === 'string'
    ? error
    : error instanceof ApolloError
    ? `${isVerbose ? `${error.name}: ` : ''}${error.message}${
        isVerbose ? ` [INTERNAL-ONLY] ${JSON.stringify(error)}` : ''
      }`
    : error != null &&
      typeof error === 'object' &&
      typeof (error as { message?: unknown }).message === 'string'
    ? `${(error as { message: string }).message}${
        isVerbose ? ` [INTERNAL-ONLY] ${JSON.stringify(error)}` : ''
      }`
    : `An unexpected error has occured${
        isVerbose ? `. [INTERNAL-ONLY] ${JSON.stringify(error)}` : ''
      }`;
}
