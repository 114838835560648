import { AlertButton } from 'react-native';

export default function AlertXAPI(
  title: string,
  description: string,
  options: AlertButton[],
): void {
  // eslint-disable-next-line no-alert
  const result = window.confirm([title, description].filter(Boolean).join('\n'));
  if (result) {
    const confirmOption = options.find(({ style }: { style?: string }) => style !== 'cancel');
    confirmOption?.onPress?.();
  } else {
    const cancelOption = options.find(({ style }: { style?: string }) => style === 'cancel');
    cancelOption?.onPress?.();
  }
}
