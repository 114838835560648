interface ErrorLoggingFields {
  errorMessage?: string;
  errorName?: string;
}

/**
 * Parses the message and name of an error, if it exists.
 *
 * Important! Make sure to spread this call or else Kibana will drop the event.
 *
 * @example
 *
 * logger.error({eventName:"signInError", ...getErrorLoggingFields(e)})
 *
 */
export default function getErrorLoggingFields(
  error: string | { message: string } | Error | unknown | undefined | null,
): ErrorLoggingFields {
  if (error == null) return {};

  if (typeof error === 'string') {
    return { errorMessage: error };
  }

  if (error instanceof Error) {
    return { errorMessage: error.message, errorName: error.name };
  }

  return { errorMessage: 'Unknown error detected' };
}
