import KHColors from 'khshared/KHColors';
import React, { ReactNode, useContext } from 'react';
import { StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';

import KHButton from './KHButton';
import KHConstants from './KHConstants';
import KHIcon from './KHIcon';
import KHText from './KHText';
import KHThemeContext from './KHThemeContext';

const styles = {
  view: {
    paddingHorizontal: KHConstants.GUTTER_WIDTH,
    paddingVertical: 12,
    marginVertical: 8,
  } as ViewStyle,
  viewV2: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    marginVertical: 0,
    borderRadius: 4,
  } as ViewStyle,
  textAndButtonArea: {
    flexDirection: 'row',
  } as ViewStyle,
  button: {
    alignSelf: 'flex-start',
  } as ViewStyle,
  textArea: {
    flex: 1,
  } as ViewStyle,
  title: {
    marginBottom: 4,
  } as TextStyle,
  icon: {
    marginRight: 8,
    marginTop: -3,
    marginBottom: -3,
  } as ViewStyle,
  iconCentered: {
    alignSelf: 'center',
  } as ViewStyle,
  underline: {
    textDecorationLine: 'underline',
  } as TextStyle,
  children: {
    marginTop: 12,
  },
  messageV2: {
    fontFamily: 'Red Hat Display',
    fontSize: 14,
    fontWeight: '500',
    color: KHColors.referralText,
  } as TextStyle,
  titleV2: {
    fontFamily: 'Red Hat Display',
    fontSize: 14,
    fontWeight: '700',
    color: KHColors.referralText,
  } as TextStyle,
  iconTitle: {
    marginRight: 8,
  } as ViewStyle,
};

const stateStyles = {
  information: {
    background: {
      backgroundColor: KHColors.backgroundInformation,
    } as ViewStyle,
    icon: {
      color: KHColors.iconInformation,
    },
    text: {
      color: KHColors.textInformation,
    } as TextStyle,
  },
  warning: {
    background: {
      backgroundColor: KHColors.backgroundWarning,
    } as ViewStyle,
    icon: {
      color: KHColors.iconWarning,
    },
    text: {
      color: KHColors.textWarning,
    } as TextStyle,
  },
  critical: {
    background: {
      backgroundColor: KHColors.criticalBackground,
    } as ViewStyle,
    icon: {
      color: KHColors.criticalWarning,
    },
    text: {
      color: KHColors.text,
    } as TextStyle,
  },
};

// TODO(referrals): add the rest
const stateStylesV2 = {
  information: {
    background: {
      backgroundColor: KHColors.backgroundFinished,
    } as ViewStyle,
  },
  critical: { background: {} },
  warning: { background: {} },
};

interface Props {
  message?: ReactNode;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
  title?: ReactNode;
  onClosePress?: () => void;
  onLinkPress?: () => void;
  linkLabel?: string;
  icon?: string;
  type: keyof typeof stateStyles;
}

export default function KHMessageField({
  message,
  children,
  style,
  title,
  onClosePress,
  onLinkPress,
  linkLabel,
  icon,
  type,
}: Props): JSX.Element {
  const theme = useContext(KHThemeContext);
  return (
    <View
      style={[
        styles.view,
        theme === 'v2' && styles.viewV2,
        stateStyles[type].background,
        theme === 'v2' && stateStylesV2[type].background,
        style,
      ]}
    >
      {(message != null || onClosePress != null) && (
        <View style={styles.textAndButtonArea}>
          <View style={styles.textArea}>
            {title != null && (
              <KHText.BodyBold
                style={[stateStyles[type].text, styles.title, theme === 'v2' && styles.titleV2]}
              >
                {icon && (
                  <KHIcon
                    source={icon}
                    size={16}
                    style={[styles.iconTitle, stateStyles[type].text]}
                  />
                )}
                {title}
              </KHText.BodyBold>
            )}
            <KHText.Body style={[stateStyles[type].text, theme === 'v2' && styles.messageV2]}>
              {title == null && icon != null && (
                <KHIcon
                  source={icon}
                  size={16}
                  style={[styles.iconTitle, stateStyles[type].text]}
                />
              )}
              {message}
              {onLinkPress && linkLabel != null && (
                <Text onPress={onLinkPress} style={styles.underline}>
                  {linkLabel}
                </Text>
              )}
            </KHText.Body>
          </View>
          {onClosePress != null && (
            <KHButton
              textOnly
              icon="close"
              iconColor={stateStyles[type].icon.color}
              onPress={onClosePress}
              style={styles.button}
            />
          )}
        </View>
      )}
      {children != null && (
        <View style={(message != null || onClosePress != null) && styles.children}>{children}</View>
      )}
    </View>
  );
}
