import { captureException } from '@sentry/react';
import getErrorLoggingFields from 'khshared/getErrorLoggingFields';
import { Logger } from 'pino';

import {
  FileChooserFileType,
  FileChooserResult,
  FileChooserSource,
} from './genChooseFileXAPITypes';

const FILE_TYPE_MIME_TYPE_EXTENSIONS: {
  [fileType in FileChooserFileType]: { [mimeType: string]: string[] };
} = {
  [FileChooserFileType.IMAGE]: {
    'image/jpeg': ['.jpeg'],
    'image/jpg': ['.jpg'],
    'image/png': ['.png'],
    'image/tiff': ['.tif', '.tiff'],
  },
  [FileChooserFileType.PDF]: {
    'application/pdf': ['.pdf'],
  },
  [FileChooserFileType.CSV]: {
    'text/csv': ['.csv'],
  },
};

// An industry-standard value for the max size of a safe file upload could not be found.
// This number was generated with the following justification:
// - Using the S3 cli, the largest file size in prod was found to be 38MB (as of 2/4/22).
// Thus, a number (50 MiB) was picked over this that a good-faith user probably would not hit.
const MAX_FILE_UPLOAD_SIZE_IN_BYTES = 50 * 1024 * 1024;

export default async function genChooseFile(
  acceptedFileTypes: FileChooserFileType[] = [FileChooserFileType.IMAGE, FileChooserFileType.PDF],
  logger: Logger,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _fileSource: FileChooserSource,
): Promise<FileChooserResult | null> {
  const input = document.createElement('input');
  input.style.display = 'none';
  input.setAttribute('type', 'file');
  input.setAttribute(
    'accept',
    acceptedFileTypes
      .flatMap((fileType) => Object.values(FILE_TYPE_MIME_TYPE_EXTENSIONS[fileType]).flat())
      .join(', '),
  );

  document.body.appendChild(input);
  logger.info({ eventName: 'fileSelectionDialogShown' });

  try {
    return await new Promise<FileChooserResult | null>((resolve, reject) => {
      // for some reason we can't check directly if the file upload is cancelled,
      // but we can check if the body returns to focus
      document.body.onfocus = () => {
        setTimeout(() => {
          document.body.onfocus = null;
          if (!input.files || input.files.length === 0) {
            document.body.removeChild(input);
            resolve(null);
            logger.info({ eventName: 'fileSelectionCanceled' });
          }
        }, 1000);
      };

      input.addEventListener('change', () => {
        document.body.removeChild(input);
        if (input.files) {
          const targetFile = input.files[0];
          if (
            !acceptedFileTypes.some(
              (fileType) => FILE_TYPE_MIME_TYPE_EXTENSIONS[fileType][targetFile.type] != null,
            )
          ) {
            reject(new Error('Please choose a valid file type.'));
          }
          if (targetFile.size > MAX_FILE_UPLOAD_SIZE_IN_BYTES) {
            reject(new Error('Please select a file less than 50 MB.'));
          }

          const reader = new FileReader();
          reader.onerror = () => {
            reject(new Error('Failed to read the selected media because the operation failed.'));
          };
          reader.onload = ({ target }) => {
            const uri = target?.result;
            if (typeof uri !== 'string') return;

            const result: FileChooserResult = {
              uri,
              name: targetFile.name,
              size: targetFile.size,
              type: targetFile.type,
            };
            logger.info({
              eventName: 'fileSelectionSuccess',
              contextJSON: JSON.stringify({
                name: result.name,
                size: result.size,
                type: result.type,
              }),
            });
            resolve(result);
          };
          reader.readAsDataURL(targetFile);
        }
      });

      input.dispatchEvent(new MouseEvent('click'));
    });
  } catch (error: unknown) {
    logger.error({ eventName: 'fileSelectionError', ...getErrorLoggingFields(error) });
    captureException(error);
    throw error;
  }
}
