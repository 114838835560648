import { S3ObjectParentType } from 'khshared/API';
import React, { useState } from 'react';
import { Image, ImageStyle, StyleProp } from 'react-native';

import useFetchFileURLsByS3ObjectIDs from '../utils/useFetchFileURLsByS3ObjectIDs';
import useLogger from '../utils/useLogger';

type Props = {
  s3ObjectData?: {
    s3ObjectID?: string | null;
    parentID?: string | null;
    parentType?: S3ObjectParentType | null;
  };
  uri?: string;
  width?: number;
  height?: number;
  style?: StyleProp<ImageStyle>;
  placeholder?: JSX.Element | null;
  caption?: JSX.Element | null;
};

function KHImage({
  uri,
  s3ObjectData,
  width,
  height,
  style,
  placeholder,
  caption,
}: Props): JSX.Element | null {
  const [imageURLFetched, setImageURLFetched] = useState<string | null>(null);

  const logger = useLogger({ eventSource: 'KHImage' });

  useFetchFileURLsByS3ObjectIDs({
    mode: 'fetchSingle',
    s3ObjectID: s3ObjectData?.s3ObjectID,
    parentID: s3ObjectData?.parentID,
    parentType: s3ObjectData?.parentType,
    setFileURL: setImageURLFetched,
    logger,
  });

  const imageURL = uri ?? imageURLFetched;

  return imageURL != null ? (
    <>
      <Image source={{ uri: imageURL, width, height }} style={style} />
      {caption ?? null}
    </>
  ) : (
    placeholder ?? null
  );
}

export default KHImage;
