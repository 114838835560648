import { DropOffOrganization, LabOrganization } from '../API';

type LabOrganizationConfig = {
  displayName: string;
  labType: 'reference' | 'specialty' | 'other';
  dropOffOrganizations: DropOffOrganization[];
  hideInPreReview?: boolean;
  hasServicesSuppliesMap?: boolean;
  requestedVolumeScheme?: 'minimum' | 'preferred';
  requestedVolumeCapacityType?: 'whole' | 'serum';
  isAutofillingRequisitionsAllowed?: boolean;
  // /** for standard labs we order lab organization tests,
  // for referenced labs we order referenced service request from another lab
  // f.e. for SPRINTER_HEALTH INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION -
  // we order the service request type from QUEST 1120 */
  placeLabOrderType?: 'standard' | 'referenced';
};

// Please try to keep this sorted.
const LAB_CONFIGS: {
  [labOrganization in LabOrganization]: LabOrganizationConfig;
} = {
  [LabOrganization.AFFORDABLE_PATERNITY]: {
    displayName: 'Affordable Paternity',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    hideInPreReview: true,
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.BIO_REFERENCE]: {
    displayName: 'BioReference Laboratories',
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.BIO_REFERENCE],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.CYREX]: {
    displayName: 'Cyrex Labs',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.DEMO_LAB]: {
    displayName: 'Widget Labs',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    requestedVolumeScheme: 'minimum',
    hideInPreReview: true,
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.EXACT_SCIENCES]: {
    displayName: 'Exact Sciences',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.FOUNDATION_MEDICINE]: {
    displayName: 'Foundation Medicine',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.GRAIL]: {
    displayName: 'GRAIL',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.GUARDANT]: {
    displayName: 'Guardant',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.LABCORP]: {
    displayName: 'Labcorp',
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.LABCORP],
    hasServicesSuppliesMap: true,
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.LETS_GET_CHECKED_LAB]: {
    displayName: 'LetsGetChecked',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.USPS],
  },
  [LabOrganization.MARSHALL_LABORATORY]: {
    displayName: 'Marshall Laboratory',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.MARSHALL_LABORATORY],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.MUIRLAB]: {
    displayName: 'MuirLab',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.MUIRLAB],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.NATERA]: {
    displayName: 'Natera',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.NAVERIS]: {
    displayName: 'Naveris',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.PULSE_DEMO]: {
    displayName: 'Pulse Demo Labs',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    requestedVolumeScheme: 'minimum',
    hideInPreReview: true,
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.QUEST]: {
    displayName: 'Quest Labs',
    labType: 'reference',
    hasServicesSuppliesMap: true,
    dropOffOrganizations: [DropOffOrganization.QUEST],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
    isAutofillingRequisitionsAllowed: true,
    placeLabOrderType: 'standard',
  },
  [LabOrganization.RUPA_HEALTH]: {
    displayName: 'Rupa Health',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.FEDEX],
    hideInPreReview: true,
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.SPRINTER_HEALTH]: {
    displayName: 'Sprinter Health',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.SPRINTER_HEALTH],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
    placeLabOrderType: 'referenced',
  },
  [LabOrganization.STANFORD_CHILDRENS_HOSPITAL]: {
    displayName: "Stanford Children's Hospital",
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.STANFORD],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.STANFORD]: {
    displayName: 'Stanford',
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.STANFORD],
    requestedVolumeScheme: 'preferred',
    hasServicesSuppliesMap: true,
    requestedVolumeCapacityType: 'whole',
  },
  [LabOrganization.STANFORD_CANCER_CENTER]: {
    displayName: 'Stanford Cancer Center',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.STANFORD_CANCER_CENTER],
    requestedVolumeScheme: 'preferred',
    hasServicesSuppliesMap: true,
    requestedVolumeCapacityType: 'whole',
  },
  [LabOrganization.STANFORD_RESEARCH]: {
    displayName: 'Stanford Research',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.STANFORD_RESEARCH],
    hasServicesSuppliesMap: true,
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.UCSF_BLOOD_DRAW_LAB]: {
    displayName: 'UCSF Blood Draw Lab',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.UCSF_BLOOD_DRAW_LAB],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.WEST_PAC]: {
    displayName: 'WestPac Labs',
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.WEST_PAC],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.DOTHOUSE_LAB]: {
    displayName: 'DotHouse Lab',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.DOTHOUSE_LAB],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.CODMAN_LAB]: {
    displayName: 'Codman Lab',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.CODMAN_LAB],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.BMC_LAB]: {
    displayName: 'BMC Lab',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.BMC_LAB],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'whole',
    hasServicesSuppliesMap: true,
  },
  [LabOrganization.ROSLINDALE_LAB]: {
    displayName: 'Roslindale Lab',
    labType: 'specialty',
    dropOffOrganizations: [DropOffOrganization.ROSLINDALE_LAB],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.KAISER]: {
    displayName: 'Kaiser',
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.KAISER],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.INTERMOUNTAIN]: {
    displayName: 'Intermountain',
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.INTERMOUNTAIN],
    hasServicesSuppliesMap: true,
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'whole',
  },
  [LabOrganization.OTHER]: {
    displayName: 'Other',
    labType: 'other',
    dropOffOrganizations: [],
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'serum',
  },
  [LabOrganization.INTERMOUNTAIN_CANCER_CENTER]: {
    displayName: 'Intermountain Cancer Center',
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.INTERMOUNTAIN_CANCER_CENTER],
    hasServicesSuppliesMap: true,
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'whole',
    isAutofillingRequisitionsAllowed: true,
  },
  [LabOrganization.TRAINING_PARTNER_LAB]: {
    displayName: 'Training Partner Lab',
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.TRAINING_PARTNER],
    hasServicesSuppliesMap: false,
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'whole',
  },
  [LabOrganization.UCI_LAB]: {
    displayName: 'UCI Lab',
    labType: 'reference',
    dropOffOrganizations: [DropOffOrganization.UCI_LAB],
    hasServicesSuppliesMap: true,
    requestedVolumeScheme: 'minimum',
    requestedVolumeCapacityType: 'whole',
  },
};

export const STANDARD_REFERENCE_LAB_ORGANIZATIONS = Object.values(LabOrganization).filter(
  (l) => LAB_CONFIGS[l].labType === 'reference',
);

export const STANDARD_REFERENCE_LAB_ORGANIZATION = LabOrganization.BIO_REFERENCE;

export default LAB_CONFIGS;
