import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { Amplify, Auth } from 'aws-amplify';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import nullthrows from 'nullthrows';

import awsExports from '../aws-exports';
import cloneAsInput from './cloneAsInput';
import KHAuth from './KHAuth';

Amplify.configure(awsExports);

const url = awsExports.aws_appsync_graphqlEndpoint;
const region = awsExports.aws_project_region;

let apolloClient: ApolloClient<NormalizedCacheObject> | null;

// Prefer using useApolloClient whenever possible. This is only intended as an escape hatch for when
// using useApolloClient would lead to an old client (typically before sign-in) being enclosed
// by a callback that is invoked on sign-in.
export function getApolloClientOrThrowDONOTUSE(): ApolloClient<NormalizedCacheObject> {
  return nullthrows(apolloClient);
}

function createOmitTypenameLink() {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = cloneAsInput(operation.variables);
    }

    return forward(operation);
  });
}

export function initializeApolloClient(): ApolloClient<NormalizedCacheObject> {
  apolloClient = new ApolloClient({
    link: ApolloLink.from([
      // From https://github.com/apollographql/apollo-client/issues/2160#issuecomment-643357153
      createOmitTypenameLink(),
      createAuthLink({
        url,
        region,
        auth: KHAuth.isSignedIn()
          ? {
              type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS as AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
              jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
            }
          : {
              type: AUTH_TYPE.AWS_IAM as AUTH_TYPE.AWS_IAM,
              credentials: fromCognitoIdentityPool({
                identityPoolId: awsExports.aws_cognito_identity_pool_id,
                clientConfig: { region: awsExports.aws_cognito_region },
              }),
            },
      }) as ApolloLink,
      createHttpLink({ uri: url }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Escalation: {
          merge: false, // We don't want to merge values, and only use fresh information for escalations
        },
      },
    }),
  });
  if (KHAuth.isSignedIn()) {
    // Get the viewer's partner organizations, which also validates that they're all from the same
    // partner organization group. If they're not, it's a very dangerous security issue, so we should
    // allow the validation error to bubble up and (intentionally) prevent initialization of the Apollo client.
    KHAuth.getPartnerOrganizations();
  }

  return apolloClient;
}
