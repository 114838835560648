import KHColors from 'khshared/KHColors';
import React, { ReactNode } from 'react';
import { Pressable, StyleProp, View, ViewStyle } from 'react-native';

const styles = {
  card: {
    backgroundColor: KHColors.card,
    borderRadius: 8,
  } as ViewStyle,
  cardFullWidth: {
    borderRadius: 0,
  } as ViewStyle,
  content: {
    margin: 16,
  } as ViewStyle,
};

interface Props {
  children?: ReactNode;
  fullWidth?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

export default function KHCard({
  children,
  fullWidth = false,
  onPress,
  style,
}: Props): JSX.Element {
  const Component = onPress != null ? Pressable : View;
  return (
    <Component style={[styles.card, fullWidth && styles.cardFullWidth, style]} onPress={onPress}>
      {children}
    </Component>
  );
}

interface ContentProps {
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
}

KHCard.Content = function Content({ children, style }: ContentProps): JSX.Element {
  return <View style={[styles.content, style]}>{children}</View>;
};
