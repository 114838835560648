import KHColors from 'khshared/KHColors';
import React, { ReactNode, useContext } from 'react';
import {
  GestureResponderEvent,
  Image,
  ImageStyle,
  Pressable,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';
import { Route, useHistory } from 'react-router';

import logo from '../assets/sh-icon-light-gray.png';
import KHAuth from '../utils/KHAuth';
import useGenNavigate from '../utils/useGenNavigate';
import useLogger from '../utils/useLogger';
import useTooltipRef from '../utils/useTooltipRef';
import generateURI from '../xapis/generateURIXAPI';
import KHIcon from './KHIcon';
import KHThemeContext from './KHThemeContext';

const logoURI = generateURI(logo);

const styles = {
  navBar: {
    width: 48,
    backgroundColor: KHColors.navBarBackground,
    height: '100%',
    paddingBottom: 12,
  } as ViewStyle,
  navBarV2: {
    backgroundColor: KHColors.teamAvailabilityTitleBarBackground,
  } as ViewStyle,
  logo: {
    width: 48,
    height: 48,
    resizeMode: 'contain',
    marginBottom: 12,
  } as ImageStyle,
  item: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 8,
  } as ViewStyle,
  itemSelected: {
    backgroundColor: KHColors.navBarSelected,
  } as ViewStyle,
  itemSelectedV2: {
    backgroundColor: KHColors.teamAvailabilityNavBarItemSelected,
  } as ViewStyle,
  spring: {
    flex: 1,
  } as ViewStyle,
  section: {
    marginVertical: 12,
  } as ViewStyle,
};

function Item({
  iconSource,
  icon,
  onPress,
  tooltip,
  selected = false,
  style,
}: {
  iconSource?: Parameters<typeof KHIcon>[0]['iconSource'];
  icon: string;
  onPress?: (e: GestureResponderEvent) => void;
  tooltip?: string;
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
}): JSX.Element {
  const tooltipRef = useTooltipRef<View>(tooltip);
  const context = useContext(KHThemeContext);

  return (
    <Pressable
      ref={tooltipRef}
      onPress={onPress}
      style={[
        styles.item,
        selected && styles.itemSelected,
        selected && context === 'v2' && styles.itemSelectedV2,
        style,
      ]}
    >
      <KHIcon
        size={24}
        iconSource={iconSource}
        source={icon}
        color={
          selected
            ? context === 'v2'
              ? KHColors.teamAvailabilityNavBarItemSelectedIcon
              : KHColors.navBarBackground
            : KHColors.iconContrast
        }
      />
    </Pressable>
  );
}

function RouteItem({
  iconSource,
  icon,
  to,
  exact = false,
  tooltip,
  selected,
  style,
}: {
  iconSource?: Parameters<typeof KHIcon>[0]['iconSource'];
  icon: string;
  to: string;
  exact?: boolean;
  tooltip?: string;
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
}): JSX.Element {
  const genNavigate = useGenNavigate();
  return (
    <Route path={to} exact={exact}>
      {({ match }) => (
        <Item
          selected={selected != null ? selected : match != null}
          iconSource={iconSource}
          icon={icon}
          tooltip={tooltip}
          onPress={(event) => {
            void genNavigate(to, { event });
          }}
          style={style}
        />
      )}
    </Route>
  );
}

function Section({ children }: { children: ReactNode }) {
  return <View style={styles.section}>{children}</View>;
}

function KHNavBar({ children, footer }: { children: ReactNode; footer?: ReactNode }): JSX.Element {
  const history = useHistory();
  const logger = useLogger();
  const context = useContext(KHThemeContext);
  return (
    <View style={[styles.navBar, context === 'v2' && styles.navBarV2]}>
      <Pressable onPress={() => history.push('/')}>
        <Image source={{ uri: logoURI }} style={styles.logo} />
      </Pressable>

      {children}
      <View style={styles.spring} />

      <Item
        onPress={async () => {
          await KHAuth.genSignOut(logger);
          history.push('/signin');
        }}
        icon="exit-to-app"
        tooltip="Sign out"
      />

      {footer}
    </View>
  );
}

export default Object.assign(KHNavBar, { Item, RouteItem, Section });
