import { Component, MutableRefObject, useEffect, useRef } from 'react';

/**
 * Returns a `ref` object that can be used to decorate a component with browser-level tooltips.
 *
 * @param tooltip
 * @returns The `ref` object to pass to the tooltipped component's `ref` prop
 */
export default function useTooltipRef<T extends Component>(
  tooltip?: string,
): MutableRefObject<T | null> {
  const ref = useRef<T>(null);
  useEffect(() => {
    // For the life of me, I could not figure out how to make this type work :(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
    if (tooltip) (ref.current as any)?.setNativeProps({ title: tooltip });
  }, [tooltip]);
  return ref;
}
