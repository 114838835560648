// Disabling this since TypeScript is able to handle cyclical dependencies that only involve types / constants.
// Resolving this would make the separation of i18nConstants and i18nTypes inconsistent.
// eslint-disable-next-line import/no-cycle
import { FullKeyMappingByNamespaceByLocale } from './i18nTypes';
import cms_outreach_sms_en from './locales/en/cmsOutreachSms.json';
import outreach_email_en from './locales/en/outreachEmail.json';
import outreach_sms_en from './locales/en/outreachSms.json';
import referrals_flow_en from './locales/en/referralsFlow.json';
import transactional_email_en from './locales/en/transactionalEmail.json';
import transactional_sms_en from './locales/en/transactionalSms.json';
import cms_outreach_sms_es from './locales/es/cmsOutreachSms.json';
import outreach_email_es from './locales/es/outreachEmail.json';
import outreach_sms_es from './locales/es/outreachSms.json';
import referrals_flow_es from './locales/es/referralsFlow.json';
import transactional_email_es from './locales/es/transactionalEmail.json';
import transactional_sms_es from './locales/es/transactionalSms.json';

export const BCP47_LANGUAGE_EN = 'en';
export const BCP47_LANGUAGE_ES = 'es';
export const I18N_FALLBACK_LNG = BCP47_LANGUAGE_EN;

export const I18N_SUPPORTED_LNGS_BACKEND: string[] = [BCP47_LANGUAGE_EN, BCP47_LANGUAGE_ES];
export const I18N_SUPPORTED_LNGS_FRONTEND: string[] = [BCP47_LANGUAGE_EN, BCP47_LANGUAGE_ES];

export const I18N_ALL_SUPPORTED_LNGS: string[] = [
  ...new Set([...I18N_SUPPORTED_LNGS_FRONTEND, ...I18N_SUPPORTED_LNGS_BACKEND]),
];

export const I18N_SUPPORTED_NAMESPACES_BACKEND: string[] = [
  'cmsOutreachSms',
  'outreachEmail',
  'transactionalEmail',
  'outreachSms',
  'transactionalSms',
];
export const I18N_SUPPORTED_NAMESPACES_FRONTEND: string[] = ['referralsFlow', 'cmsOutreachSms'];

export const I18N_ALL_SUPPORTED_NAMESPACES: string[] = [
  ...new Set([...I18N_SUPPORTED_NAMESPACES_FRONTEND, ...I18N_SUPPORTED_NAMESPACES_BACKEND]),
];
export const I18N_ALL_SUPPORTED_NAMESPACE_FILENAMES: string[] = I18N_ALL_SUPPORTED_NAMESPACES.map(
  (namespace) => `${namespace}.json`,
);

export const CMS_OUTREACH_KEYS: FullKeyMappingByNamespaceByLocale = {
  [BCP47_LANGUAGE_EN]: {
    'cmsOutreachSms.json': cms_outreach_sms_en,
  },
  [BCP47_LANGUAGE_ES]: {
    'cmsOutreachSms.json': cms_outreach_sms_es,
  },
};

export const CMS_OUTREACH_NAMESPACES_FILES = Object.keys(CMS_OUTREACH_KEYS.en);

export const I18N_FRONTEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE: Record<
  string,
  Record<string, Record<string, string>>
> = {
  [BCP47_LANGUAGE_EN]: { referralsFlow: referrals_flow_en, cmsOutreachSms: cms_outreach_sms_en },
  [BCP47_LANGUAGE_ES]: { referralsFlow: referrals_flow_es, cmsOutreachSms: cms_outreach_sms_es },
};

export const I18N_BACKEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE: Record<
  string,
  Record<string, Record<string, string>>
> = {
  [BCP47_LANGUAGE_EN]: {
    cmsOutreachSms: cms_outreach_sms_en,
    outreachSms: outreach_sms_en,
    transactionalSms: transactional_sms_en,
    outreachEmail: outreach_email_en,
    transactionalEmail: transactional_email_en,
  },
  [BCP47_LANGUAGE_ES]: {
    cmsOutreachSms: cms_outreach_sms_es,
    outreachSms: outreach_sms_es,
    transactionalSms: transactional_sms_es,
    outreachEmail: outreach_email_es,
    transactionalEmail: transactional_email_es,
  },
};

export const getAllNamespaceJsonObjectsByLanguage = (): Record<
  string,
  Record<string, Record<string, string>>
> => {
  const allNamespaceJsonObjectsByLanguage: Record<
    string,
    Record<string, Record<string, string>>
  > = {};
  for (const lng of I18N_ALL_SUPPORTED_LNGS) {
    allNamespaceJsonObjectsByLanguage[lng] = {
      ...I18N_FRONTEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE[lng],
      ...I18N_BACKEND_NAMESPACE_JSON_OBJECTS_BY_LANGUAGE[lng],
    };
  }
  return allNamespaceJsonObjectsByLanguage;
};

export const LOKALISE_DEV_PROJECT_ID = '3460092864a87400d01623.47375839';
export const LOKALISE_PROD_PROJECT_ID = '9028054764af3a84674431.19892128';

export const LOKALISE_KEY_LIST_MAX_NUM_OF_KEYS_PER_RESPONSE = 500;

// Rate limit is 6 requests per second, but we are being conservative and waiting 1/3 of a second between requests
// Since it's possible developers or our cron may be using the Lokalise API at the same time
export const LOKALISE_RATE_LIMIT_WAIT_TIME_IN_MILLISECONDS = 1000 / 3;
