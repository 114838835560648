import KHPalette from './KHPalette';
import {
  ColorActionTaskActiveType,
  ColorBackgroundDefault,
  ColorBackgroundPaper,
  ColorBrandTertiaryDarkest,
  ColorButtonPrimaryActive,
  ColorCaptionFillCritical,
  ColorCaptionFillHighlight,
  ColorCaptionFillNeutral,
  ColorCaptionFillWarning,
  ColorCaptionFontCritical,
  ColorCaptionFontHighlight,
  ColorCaptionFontNeutral,
  ColorCaptionFontWarning,
  ColorCloseButton,
  ColorCriticalDark,
  ColorCriticalDarker,
  ColorCriticalLight,
  ColorCriticalLightest,
  ColorFilterButtonV2BackgroundSelected,
  ColorFilterFillDefault,
  ColorFilterFillDisabled,
  ColorFilterFillHover,
  ColorFilterFillSelected,
  ColorFilterFontDefault,
  ColorFilterFontDisabled,
  ColorFilterFontHover,
  ColorFilterFontSelected,
  ColorFilterStrokeDefault,
  ColorFilterStrokeDisabled,
  ColorFilterStrokeHover,
  ColorFilterStrokeSelected,
  ColorHighlightDarker,
  ColorHighlightDarkest,
  ColorHighlightLight,
  ColorHighlightLightest,
  ColorLightPine,
  ColorNeutralDark,
  ColorNeutralDarkest,
  ColorNeutralLighter,
  ColorNeutralLightest,
  ColorNeutralMain,
  ColorPine,
  ColorPrimaryDark,
  ColorPrimaryDarker,
  ColorPrimaryFillDefault,
  ColorPrimaryFillDefaultSubtle,
  ColorPrimaryFillDisabled,
  ColorPrimaryFillDisabledSubtle,
  ColorPrimaryFillHover,
  ColorPrimaryFillHoverSubtle,
  ColorPrimaryFillSelected,
  ColorPrimaryFillSelectedSubtle,
  ColorPrimaryFontDefault,
  ColorPrimaryFontDefaultSubtle,
  ColorPrimaryFontDisabled,
  ColorPrimaryFontDisabledSubtle,
  ColorPrimaryFontHover,
  ColorPrimaryFontHoverSubtle,
  ColorPrimaryFontSelected,
  ColorPrimaryFontSelectedSubtle,
  ColorPrimaryLightest,
  ColorPrimaryStrokeDefault,
  ColorPrimaryStrokeDefaultSubtle,
  ColorPrimaryStrokeDisabled,
  ColorPrimaryStrokeDisabledSubtle,
  ColorPrimaryStrokeHover,
  ColorPrimaryStrokeHoverSubtle,
  ColorPrimaryStrokeSelected,
  ColorPrimaryStrokeSelectedSubtle,
  ColorSecondaryDark,
  ColorSecondaryDarkest,
  ColorSecondaryFillDefault,
  ColorSecondaryFillDefaultSubtle,
  ColorSecondaryFillDisabled,
  ColorSecondaryFillDisabledSubtle,
  ColorSecondaryFillHover,
  ColorSecondaryFillHoverSubtle,
  ColorSecondaryFillSelected,
  ColorSecondaryFillSelectedSubtle,
  ColorSecondaryFontDefault,
  ColorSecondaryFontDefaultSubtle,
  ColorSecondaryFontDisabled,
  ColorSecondaryFontDisabledSubtle,
  ColorSecondaryFontHover,
  ColorSecondaryFontHoverSubtle,
  ColorSecondaryFontSelected,
  ColorSecondaryFontSelectedSubtle,
  ColorSecondaryLight,
  ColorSecondaryLighter,
  ColorSecondaryLightest,
  ColorSecondaryMain,
  ColorSecondaryNav,
  ColorSecondaryStrokeDefault,
  ColorSecondaryStrokeDefaultSubtle,
  ColorSecondaryStrokeDisabled,
  ColorSecondaryStrokeDisabledSubtle,
  ColorSecondaryStrokeHover,
  ColorSecondaryStrokeHoverSubtle,
  ColorSecondaryStrokeSelected,
  ColorSecondaryStrokeSelectedSubtle,
  ColorTabsTextActive,
  ColorTaskFontSelected,
  ColorTertiaryFillDefault,
  ColorTertiaryFillDefaultSubtle,
  ColorTertiaryFillDisabled,
  ColorTertiaryFillDisabledSubtle,
  ColorTertiaryFillHover,
  ColorTertiaryFillHoverSubtle,
  ColorTertiaryFillSelected,
  ColorTertiaryFillSelectedSubtle,
  ColorTertiaryFontDefault,
  ColorTertiaryFontDefaultSubtle,
  ColorTertiaryFontDisabled,
  ColorTertiaryFontDisabledSubtle,
  ColorTertiaryFontHover,
  ColorTertiaryFontHoverSubtle,
  ColorTertiaryFontSelected,
  ColorTertiaryFontSelectedSubtle,
  ColorTertiaryStrokeDefault,
  ColorTertiaryStrokeDefaultSubtle,
  ColorTertiaryStrokeDisabled,
  ColorTertiaryStrokeDisabledSubtle,
  ColorTertiaryStrokeHover,
  ColorTertiaryStrokeHoverSubtle,
  ColorTertiaryStrokeSelected,
  ColorTertiaryStrokeSelectedSubtle,
  ColorTextInputFontFocus,
  ColorTextInputStrokeDefault,
  ColorTextInputStrokeFocus,
  ColorTextLinkNew,
  ColorToastFill,
  ColorToastFont,
  ColorToastStroke,
  ColorTypeDark,
  ColorTypeDarker,
  ColorTypeInverse,
  ColorWarningDark,
  ColorWarningDarker,
  ColorWarningDarkest,
  ColorWarningLight,
  ColorWarningLightest,
  RainbowBilobaDarker,
} from './KHTokens';

// Please keep sorted.
export default {
  appBar: KHPalette.white,
  appointmentNeedsRequisitionText: KHPalette.pantone,
  appointmentNotStartedText: KHPalette.gray80,
  appointmentInProgressText: KHPalette.orange100,
  appointmentCompletedText: KHPalette.purple100,
  appointmentNotPerformedText: KHPalette.red100,
  faxConfirmationStatusIncorrect: KHPalette.gray80,
  faxConfirmationStatusPending: KHPalette.orange100,
  faxConfirmationStatusUnattempted: KHPalette.pantone,
  faxConfirmationStatusConfirmed: KHPalette.green100,
  background: KHPalette.gray10,
  backgroundAccent: KHPalette.cyan130,
  backgroundAlternatingRow: KHPalette.gray5,
  backgroundContrast: KHPalette.white,
  backgroundInformation: KHPalette.purple5,
  backgroundLight: KHPalette.gray5,
  backgroundLightPurple: KHPalette.purple20,
  backgroundPrimary: KHPalette.purple100,
  backgroundPrimaryLight: KHPalette.purple30,
  backgroundPrimaryDark: KHPalette.purple130,
  backgroundSecondary: KHPalette.indigo70,
  backgroundSecondaryDark: KHPalette.indigo90,
  backgroundSecondaryGray: KHPalette.gray20,
  backgroundSecondaryDarkGray: KHPalette.newGray80,
  backgroundSideBar: KHPalette.white,
  backgroundSubNavBar: KHPalette.purple10,
  backgroundWarning: KHPalette.yellow10,
  backgroundFinished: KHPalette.referralBackground,
  boneDensityScanIcon: KHPalette.blueGray80,
  topicalFluorideIcon: KHPalette.blueGray80,
  borderPrimary: KHPalette.newPurple10,
  bottomNavigation: KHPalette.gray40,
  bottomNavigationBackground: KHPalette.white,
  bottomNavigationSelected: KHPalette.purple100,
  bookWarning: KHPalette.newRed,
  bookWithRiskText: KHPalette.bookWithRiskTextRed,
  borderContrast: KHPalette.white,
  buttonDefault: KHPalette.gray20,
  buttonDisabled: KHPalette.gray10,
  buttonDisabledLabel: KHPalette.gray70,
  buttonHighlight: `${KHPalette.purple100}33`,
  buttonLabel: KHPalette.white,
  buttonOutlinePrimary: KHPalette.purple100,
  buttonPrimary: KHPalette.purple100,
  card: KHPalette.white,
  cardBackgroundInvalid: KHPalette.red20,
  cardBackgroundAttentionStatus: KHPalette.newBrown,
  cardBackgroundNormalStatus: KHPalette.white,
  cardBackgroundPreStatus: KHPalette.newYellow40,
  cardTextAttentionStatus: KHPalette.newYellow10,
  cardTextDetail: KHPalette.newPurple10,
  cardTextNormalStatus: KHPalette.newGray10,
  cardTextPreStatus: KHPalette.newOrange,
  checkbox: KHPalette.gray50,
  checkboxContrast: KHPalette.white,
  checkboxDisabled: KHPalette.gray70,
  checkboxLabel: KHPalette.black,
  checkboxLabelDisabled: KHPalette.gray70,
  checkboxSelected: KHPalette.purple100,
  checklistItemSectionDone: KHPalette.green100,
  checklistItemHeaderCheckboxBackground: KHPalette.white,
  checklistItemHeaderActiveIndicator: KHPalette.purple100,
  checklistItemHeaderBackgroundDone: KHPalette.gray70,
  checklistItemHeaderBackgroundDoneDesktop: KHPalette.purple100,
  checklistItemHeaderBackgroundDefault: '#8B64D2',
  checklistItemHeaderIconDone: KHPalette.white,
  checklistItemHeaderTitle: KHPalette.white,
  checklistSectionBackgroundDone: KHPalette.white,
  chipBackgroundSelected: KHPalette.purple10,
  chipBackgroundSelectedDisabled: KHPalette.gray10,
  chipBorder: KHPalette.gray20,
  chipBorderDisabled: KHPalette.gray50,
  chipBorderSelected: KHPalette.purple100,
  chipBorderSelectedDisabled: KHPalette.gray40,
  chipIconSelected: KHPalette.purple100,
  chipLabelDisabled: KHPalette.gray50,
  chipLabelSelected: KHPalette.purple100,
  chipLabelSelectedDisabled: KHPalette.gray70,
  criticalWarning: KHPalette.newRed,
  criticalBackground: KHPalette.red20,
  dialogBackground: KHPalette.white,
  dropOffResult: KHPalette.navy,
  dropdownBackgroundDisabled: KHPalette.gray10,
  dropDownHovered: KHPalette.gray10,
  dropOffHovered: KHPalette.gray10,
  darkRed: KHPalette.red140,
  emailBackgroundWhite: KHPalette.white,
  emailBackgroundWhiteGray: KHPalette.emailBackgroundWhiteGray,
  emailBackgroundWhiteGrayDark: KHPalette.emailBackgroundWhiteGrayDark,
  emailBackgroundWhitePurple: KHPalette.emailBackgroundWhitePurple,
  emailPartnerButton: KHPalette.newPurple80,
  emailPatientButton: KHPalette.newPurple85,
  emailTextBlueGray: KHPalette.emailTextBlueGray,
  emailTextDarkGray: KHPalette.emailTextDarkGray,
  emailTextGray: KHPalette.emailTextGray,
  emailTextLightGray: KHPalette.emailTextLightGray,
  emailTextNavyBlue: KHPalette.referralPurple,
  emailTextPurple: KHPalette.newPurple75,
  emailCalloutTableBackground: KHPalette.referralBackground,
  emailCalloutTableSeparator: KHPalette.purple30,
  emailTitleWarningText: KHPalette.deepOrange140,
  emailTitleWarningBackground: KHPalette.red10,
  hovered: KHPalette.gray10,
  icon: KHPalette.black,
  iconDisabled: KHPalette.gray80,
  iconDisabledContrast: KHPalette.gray30,
  iconInformation: KHPalette.purple130,
  iconPrimary: KHPalette.purple100,
  iconPrimaryDark: KHPalette.newPurple90,
  iconPrimaryLight: KHPalette.purple70,
  iconSecondary: KHPalette.gray60,
  iconSecondaryLight: KHPalette.newGray80,
  iconWarning: KHPalette.yellow100,
  iconContrast: KHPalette.white,
  iconSpecialtyLab: KHPalette.deepOrange60,
  languageSelectDropDownBackground: KHPalette.white,
  languageSelectDropDownBorder: KHPalette.purple20,
  languageSelectDropDownButtonActive: KHPalette.purple20,
  languageSelectDropDownShadow: KHPalette.black,
  languageSelectIconColor: KHPalette.newPurple80,
  labelBloodSpecimensFieldInput: KHPalette.blue130,
  listItemSelected: KHPalette.gray80,
  link: KHPalette.blue110,
  logoPlus: KHPalette.newGray90,
  lookupHovered: KHPalette.gray10,
  mapMarker: KHPalette.gray80,
  mapMarkerDark: KHPalette.navy,
  mapMarkerContrast: KHPalette.newPurple10,
  monofilamentTestHasSensationBackground: KHPalette.pantone,
  monofilamentTestHasSensationPrimary: KHPalette.neonGreen,
  monofilamentTestHasWoundBackground: KHPalette.red110,
  monofilamentTestHasWoundOrMissingPrimary: KHPalette.white,
  monofilamentTestMissingBackground: KHPalette.newGray80,
  monofilamentTestNoSensationPrimary: KHPalette.lightbrown,
  monofilamentTestNoSensationBackground: KHPalette.tan,
  navBarBackground: KHPalette.navy,
  navBarSelected: KHPalette.purple10,
  overlayBackground: `${KHPalette.black}33`,
  onboardTechButtonBackground: KHPalette.navy,
  painScreeningIcon: KHPalette.red110,
  patientHighlight: KHPalette.purple20,
  phoneBorder: KHPalette.gray150,
  productTypeCardiacAuscultationIcon: KHPalette.gray50,
  productTypeDiabeticFootScreeningIcon: KHPalette.brown80,
  productTypeDiabeticRetinaScanIcon: KHPalette.green100,
  productTypeECGIcon: KHPalette.lightBlue100,
  productTypeFitKitPickupIcon: KHPalette.orange110,
  productTypeLabTestIcon: KHPalette.red120,
  productTypeRapidCovidTestIcon: KHPalette.green110,
  productTypeVitalsIcon: KHPalette.blueGray80,
  productTypeXrayIcon: KHPalette.gray50,
  productTypeCareNavigationDropInIcon: KHPalette.blueGray80,
  productTypeQuestionnaires: KHPalette.blueGray80,
  productTypeSDoHResourceGuidanceIcon: KHPalette.blueGray80,
  productTypeKitDeliveryAndEducation: KHPalette.blueGray80,
  productTypeKitDelivery: KHPalette.blueGray80,
  productTypeDEXAScanCareNavigationBookingIcon: KHPalette.newGray80,
  productTypeDEXAScanCareNavigationConfirmationIcon: KHPalette.newGray80,
  productTypeMammogramCareNavigationBookingIcon: KHPalette.newGray80,
  productTypeMammogramCareNavigationConfirmationIcon: KHPalette.newGray80,
  productTypePCPVisitCareNavigationBookingIcon: KHPalette.newGray80,
  productTypePCPVisitCareNavigationConfirmationIcon: KHPalette.newGray80,
  productTypeHeightAndWeightCollectionIcon: KHPalette.cyan110,
  productTypeAnnualWellnessVisitProviderCallIcon: KHPalette.pink100,
  productTypeStatinAdherenceAssessmentIcon: KHPalette.red130,
  productTypeWeightCounselingForChildrenIcon: KHPalette.newGray80,
  progressBar: KHPalette.purple100,
  progressBarTrack: KHPalette.gray5,
  progressBarInactive: KHPalette.gray40,
  referralBookingButton: KHPalette.newPurple80,
  referralBookingContactText: KHPalette.newPurple80,
  referralBookingTitle: KHPalette.indigo150,
  referralDisabledButton: KHPalette.referralDisabled,
  referralDoctorInfoHeaderText: ColorNeutralDark,
  referralIcon: KHPalette.newPurple85,
  referralOutline: KHPalette.gray20,
  referralPrimaryColor: KHPalette.indigo150,
  referralPurple: KHPalette.referralPurple,
  referralRebookButton: KHPalette.newPurple85,
  referralSeparatorBar: KHPalette.newGray20,
  referralSeparatorCompletedText: KHPalette.newPurple85,
  referralSeparatorPendingText: KHPalette.newGray20,
  referralServicesBackground: KHPalette.referralBackground,
  referralText: KHPalette.referralText,
  referralVisitBackground: KHPalette.referralNewYellow,
  routePrimary: KHPalette.newPurple90,
  sectionHeaderBackground: KHPalette.white,
  selectedRow: KHPalette.gray5,
  separator: KHPalette.gray20,
  sideBarSelected: KHPalette.purple100,
  searchBackground: KHPalette.white,
  suggestedSlotsBackground: ColorHighlightLightest,
  suggestedSlotsText: ColorHighlightDarker,
  discouragedSlotsBackground: ColorWarningLightest,
  discouragedSlotsText: ColorWarningDarker,
  riskSlotsBackground: ColorCriticalLightest,
  riskSlotsText: ColorCriticalDarker,
  tabButtonTextV2: ColorTabsTextActive,
  tabButtonTextSelected: KHPalette.purple100,
  tabButtonTextSelectedV2: ColorSecondaryDark,
  tabButtonUnderline: KHPalette.gray20,
  tabButtonUnderlineSelected: KHPalette.purple100,
  text: KHPalette.gray130,
  textAccent: KHPalette.purple100,
  textAccentNew: KHPalette.newPurple10,
  textBrown: KHPalette.brown90,
  textChecklistTitle: KHPalette.black,
  textDark: KHPalette.black,
  textContrast: KHPalette.white,
  textContrastNeonGreen: KHPalette.neonGreen,
  textError: KHPalette.red110,
  textInformation: KHPalette.purple130,
  textInputBackgroundV2: KHPalette.white,
  textInputBackgroundDisabled: KHPalette.gray10,
  textInputLabelV2: KHPalette.referralText,
  textInputCaptionV2: KHPalette.referralTextCaption,
  textInputOutline: KHPalette.gray40,
  textInputOutlineError: KHPalette.red100,
  textInputOutlineFocused: KHPalette.gray80,
  textInputTextV2: KHPalette.navy,
  textInputTextDisabled: KHPalette.gray80,
  textInputTextContrast: KHPalette.white,
  textNavy: KHPalette.navy,
  textPlaceholder: KHPalette.gray40,
  textSecondary: KHPalette.gray80,
  textSecondaryLight: KHPalette.gray30,
  textSecondaryMedium: KHPalette.gray60,
  textSecondaryDark: KHPalette.gray100,
  textSuccess: KHPalette.green100,
  textWarning: KHPalette.yellow160,
  textWarningWithoutBackground: KHPalette.newDarkOrange,
  timeLine: KHPalette.orange100,
  toastBackground: KHPalette.blue20,
  toastBackgroundError: KHPalette.red20,
  toastLabel: KHPalette.blue110,
  verticalSeparator: KHPalette.gray40,
  warning: KHPalette.orange80,
  escalationReportAbnormalText: KHPalette.orange80,
  escalationReportCriticalText: KHPalette.newRed,
  escalationOutstandingText: KHPalette.newRed,
  escalationResolvedText: KHPalette.gray80,
  rainbowPalette: [
    '#18a3a5',
    '#dd7927',
    '#615fdd',
    '#d2649f',
    '#9348ad',
    '#1d87da',
    '#517c2a',
    '#a13978',
    '#e5ad06',
    '#62d644',
    '#e27867',
    '#19c2d8',
    '#dd4981',
    '#8bc34a',
    '#5e82d2',
    '#f5554a',
    '#4ccaa2',
    '#607d8b',
    '#449d48',
    '#86665a',
  ],
  designSystem: {
    grays: {
      black: '#2A2A4A',
    },
    action: {
      main: '#502997',
    },
    primary: {
      main: '#554494',
    },
  },
  success: ColorPine,
  successBackground: ColorLightPine,

  tagHighlightColorText: ColorHighlightDarkest,
  tagHighlightColorBackground: ColorHighlightLightest,
  tagWarningColorText: ColorWarningDarkest,
  tagWarningColorBackground: ColorWarningLightest,

  alertHighlightColorText: ColorHighlightDarkest,
  alertHighlightColorBackground: ColorHighlightLightest,
  alertHighlightColorStroke: ColorHighlightLight,
  alertWarningColorText: ColorWarningDarkest,
  alertWarningColorBackground: ColorWarningLightest,
  alertWarningColorStroke: ColorWarningLight,
  alertCriticalColorText: ColorCriticalDarker,
  alertCriticalColorBackground: ColorCriticalLightest,
  alertCriticalColorStroke: ColorCriticalLight,

  teamAvailabilityPageBackground: ColorBackgroundDefault,
  teamAvailabilityChartBackground: ColorBackgroundPaper,
  teamAvailabilityHeaderText: ColorTypeDarker,
  teamAvailabilityDottedLine: ColorSecondaryMain,
  teamAvailabilityChartBorder: ColorSecondaryDark,
  teamAvailabilityChartDayBorder: ColorSecondaryLightest,
  teamAvailabilitySprinterNameText: ColorActionTaskActiveType,
  teamAvailabilityServiceRegionNameText: ColorTypeDarker,
  teamAvailabilityCalendarHeaderDateText: ColorTypeDarker,
  teamAvailabilityCalendarHeaderMonthText: ColorTypeDarker,
  teamAvailabilityCalendarHeaderWeekText: ColorNeutralDark,
  teamAvailabilityChartAppointment: ColorNeutralDark,
  teamAvailabilityChartConflict: ColorCriticalDark,
  teamAvailabilityChartPreviewAvailable: ColorPrimaryLightest,
  teamAvailabilityChartPreviewBorder: ColorPrimaryDarker,
  teamAvailabilitySearchInputText: ColorTypeInverse,
  teamAvailabilityCalendarBottomBorder: ColorSecondaryMain,
  teamAvailabilityCurrentDayIndicatorBackground: ColorPrimaryDark,
  teamAvailabilityCurrentDayIndicatorText: ColorTypeInverse,
  teamAvailabilityWeekSeparator: ColorSecondaryLight,
  teamAvailabilityDrawerBackground: ColorBackgroundPaper,
  teamAvailabilityDrawerHeaderText: ColorTypeDarker,
  teamAvailabilityDrawerHeaderSeparator: ColorSecondaryLightest,
  teamAvailabilityDrawerDeleteEntryButton: ColorCloseButton,
  teamAvailabilityDrawerEditedFormButtonsBackground: ColorBackgroundDefault,
  teamAvailabilityDrawerEditedFormButtonsTopBorder: ColorSecondaryLight,
  teamAvailabilityDrawerImpactedAppointmentCountText: ColorCriticalDarker,

  teamAvailabilityNavBarItemSelected: ColorBackgroundDefault,
  teamAvailabilityNavBarItemSelectedIcon: ColorSecondaryDark,
  teamAvailabilityTitleBarBackground: ColorSecondaryMain,
  teamAvailabilityTitleBarTitleShadow: ColorTypeDarker,
  teamAvailabilityFilterButtonBackgroundSelected: ColorFilterButtonV2BackgroundSelected,
  teamAvailabilityFilterButtonShadow: ColorNeutralDarkest,
  teamAvailabilityFilterButtonAvatarBorder: ColorSecondaryLighter,
  teamAvailabilityTitleBarIconButtonBackgroundHover: ColorButtonPrimaryActive,
  teamAvailabilitySearchBarBorder: ColorSecondaryMain,
  teamAvailabilityIconColorNonInverted: ColorTypeDark,
  teamAvailabilitySwitchLabel: ColorTypeDarker,
  teamAvailabilitySwitchBorder: ColorTypeDark,
  teamAvailabilitySwitchBackgroundActiveOff: ColorNeutralLightest,
  teamAvailabilitySwitchBackgroundDisabledOff: ColorNeutralLighter,
  teamAvailabilitySwitchBackgroundActiveOn: ColorSecondaryDark,
  teamAvailabilitySwitchBackgroundDisabledOn: ColorNeutralMain,
  teamAvailabilityAvatarDefaultBackground: ColorSecondaryDark,
  teamAvailabilityInputText: ColorTypeDarker,
  teamAvailabilityCreateSprinterButton: ColorButtonPrimaryActive,

  filterButtonBackgroundColor: ColorFilterFillDefault,
  filterButtonBackgroundColorHover: ColorFilterFillHover,
  filterButtonBackgroundColorSelected: ColorFilterFillSelected,
  filterButtonBackgroundColorDisabled: ColorFilterFillDisabled,
  filterButtonBorderColor: ColorFilterStrokeDefault,
  filterButtonBorderColorHover: ColorFilterStrokeHover,
  filterButtonBorderColorSelected: ColorFilterStrokeSelected,
  filterButtonBorderColorDisabled: ColorFilterStrokeDisabled,
  filterButtonTextColor: ColorFilterFontDefault,
  filterButtonTextColorHover: ColorFilterFontHover,
  filterButtonTextColorSelected: ColorFilterFontSelected,
  filterButtonTextColorDisabled: ColorFilterFontDisabled,

  iconButtonPrimaryBackgroundDefault: ColorPrimaryFillDefault,
  iconButtonPrimaryIconDefault: ColorPrimaryFontDefault,
  iconButtonPrimaryBorderDefault: ColorPrimaryStrokeDefault,
  iconButtonSecondaryBackgroundDefault: ColorSecondaryFillDefault,
  iconButtonSecondaryIconDefault: ColorSecondaryFontDefault,
  iconButtonSecondaryBorderDefault: ColorSecondaryStrokeDefault,
  iconButtonTertiaryBackgroundDefault: ColorTertiaryFillDefault,
  iconButtonTertiaryIconDefault: ColorTertiaryFontDefault,
  iconButtonTertiaryBorderDefault: ColorTertiaryStrokeDefault,
  iconButtonPrimaryBackgroundHover: ColorPrimaryFillHover,
  iconButtonPrimaryIconHover: ColorPrimaryFontHover,
  iconButtonPrimaryBorderHover: ColorPrimaryStrokeHover,
  iconButtonSecondaryBackgroundHover: ColorSecondaryFillHover,
  iconButtonSecondaryIconHover: ColorSecondaryFontHover,
  iconButtonSecondaryBorderHover: ColorSecondaryStrokeHover,
  iconButtonTertiaryBackgroundHover: ColorTertiaryFillHover,
  iconButtonTertiaryIconHover: ColorTertiaryFontHover,
  iconButtonTertiaryBorderHover: ColorTertiaryStrokeHover,
  iconButtonPrimaryBackgroundSelected: ColorPrimaryFillSelected,
  iconButtonPrimaryIconSelected: ColorPrimaryFontSelected,
  iconButtonPrimaryBorderSelected: ColorPrimaryStrokeSelected,
  iconButtonSecondaryBackgroundSelected: ColorSecondaryFillSelected,
  iconButtonSecondaryIconSelected: ColorSecondaryFontSelected,
  iconButtonSecondaryBorderSelected: ColorSecondaryStrokeSelected,
  iconButtonTertiaryBackgroundSelected: ColorTertiaryFillSelected,
  iconButtonTertiaryIconSelected: ColorTertiaryFontSelected,
  iconButtonTertiaryBorderSelected: ColorTertiaryStrokeSelected,
  iconButtonPrimaryBackgroundDisabled: ColorPrimaryFillDisabled,
  iconButtonPrimaryIconDisabled: ColorPrimaryFontDisabled,
  iconButtonPrimaryBorderDisabled: ColorPrimaryStrokeDisabled,
  iconButtonSecondaryBackgroundDisabled: ColorSecondaryFillDisabled,
  iconButtonSecondaryIconDisabled: ColorSecondaryFontDisabled,
  iconButtonSecondaryBorderDisabled: ColorSecondaryStrokeDisabled,
  iconButtonTertiaryBackgroundDisabled: ColorTertiaryFillDisabled,
  iconButtonTertiaryIconDisabled: ColorTertiaryFontDisabled,
  iconButtonTertiaryBorderDisabled: ColorTertiaryStrokeDisabled,

  iconButtonPrimaryBackgroundDefaultSubtle: ColorPrimaryFillDefaultSubtle,
  iconButtonPrimaryIconDefaultSubtle: ColorPrimaryFontDefaultSubtle,
  iconButtonPrimaryBorderDefaultSubtle: ColorPrimaryStrokeDefaultSubtle,
  iconButtonSecondaryBackgroundDefaultSubtle: ColorSecondaryFillDefaultSubtle,
  iconButtonSecondaryIconDefaultSubtle: ColorSecondaryFontDefaultSubtle,
  iconButtonSecondaryBorderDefaultSubtle: ColorSecondaryStrokeDefaultSubtle,
  iconButtonTertiaryBackgroundDefaultSubtle: ColorTertiaryFillDefaultSubtle,
  iconButtonTertiaryIconDefaultSubtle: ColorTertiaryFontDefaultSubtle,
  iconButtonTertiaryBorderDefaultSubtle: ColorTertiaryStrokeDefaultSubtle,
  iconButtonPrimaryBackgroundHoverSubtle: ColorPrimaryFillHoverSubtle,
  iconButtonPrimaryIconHoverSubtle: ColorPrimaryFontHoverSubtle,
  iconButtonPrimaryBorderHoverSubtle: ColorPrimaryStrokeHoverSubtle,
  iconButtonSecondaryBackgroundHoverSubtle: ColorSecondaryFillHoverSubtle,
  iconButtonSecondaryIconHoverSubtle: ColorSecondaryFontHoverSubtle,
  iconButtonSecondaryBorderHoverSubtle: ColorSecondaryStrokeHoverSubtle,
  iconButtonTertiaryBackgroundHoverSubtle: ColorTertiaryFillHoverSubtle,
  iconButtonTertiaryIconHoverSubtle: ColorTertiaryFontHoverSubtle,
  iconButtonTertiaryBorderHoverSubtle: ColorTertiaryStrokeHoverSubtle,
  iconButtonPrimaryBackgroundSelectedSubtle: ColorPrimaryFillSelectedSubtle,
  iconButtonPrimaryIconSelectedSubtle: ColorPrimaryFontSelectedSubtle,
  iconButtonPrimaryBorderSelectedSubtle: ColorPrimaryStrokeSelectedSubtle,
  iconButtonSecondaryBackgroundSelectedSubtle: ColorSecondaryFillSelectedSubtle,
  iconButtonSecondaryIconSelectedSubtle: ColorSecondaryFontSelectedSubtle,
  iconButtonSecondaryBorderSelectedSubtle: ColorSecondaryStrokeSelectedSubtle,
  iconButtonTertiaryBackgroundSelectedSubtle: ColorTertiaryFillSelectedSubtle,
  iconButtonTertiaryIconSelectedSubtle: ColorTertiaryFontSelectedSubtle,
  iconButtonTertiaryBorderSelectedSubtle: ColorTertiaryStrokeSelectedSubtle,
  iconButtonPrimaryBackgroundDisabledSubtle: ColorPrimaryFillDisabledSubtle,
  iconButtonPrimaryIconDisabledSubtle: ColorPrimaryFontDisabledSubtle,
  iconButtonPrimaryBorderDisabledSubtle: ColorPrimaryStrokeDisabledSubtle,
  iconButtonSecondaryBackgroundDisabledSubtle: ColorSecondaryFillDisabledSubtle,
  iconButtonSecondaryIconDisabledSubtle: ColorSecondaryFontDisabledSubtle,
  iconButtonSecondaryBorderDisabledSubtle: ColorSecondaryStrokeDisabledSubtle,
  iconButtonTertiaryBackgroundDisabledSubtle: ColorTertiaryFillDisabledSubtle,
  iconButtonTertiaryIconDisabledSubtle: ColorTertiaryFontDisabledSubtle,
  iconButtonTertiaryBorderDisabledSubtle: ColorTertiaryStrokeDisabledSubtle,

  searchInputIcon: ColorSecondaryNav,
  searchInputBorder: ColorTextInputStrokeDefault,
  searchInputBackgroundDefault: ColorBackgroundPaper,
  searchInputBackgroundDefaultInverted: ColorSecondaryLighter,
  searchInputBackgroundFocus: ColorBackgroundPaper,
  searchInputBorderFocus: ColorTextInputStrokeFocus,
  searchInputTextColorFocus: ColorTextInputFontFocus,

  captionColorNeutral: ColorCaptionFontNeutral,
  captionBackgroundNeutral: ColorCaptionFillNeutral,
  captionColorHighlight: ColorCaptionFontHighlight,
  captionBackgroundHighlight: ColorCaptionFillHighlight,
  captionColorWarning: ColorCaptionFontWarning,
  captionBackgroundWarning: ColorCaptionFillWarning,
  captionColorCritical: ColorCaptionFontCritical,
  captionBackgroundCritical: ColorCaptionFillCritical,

  linkColor: ColorTextLinkNew,

  toastBorderDS24: ColorToastStroke,
  toastBackgroundDS24: ColorToastFill,
  toastFontDS24: ColorToastFont,

  taskMenuBackground: ColorSecondaryLighter,
  taskButtonActiveText: ColorTabsTextActive,
  taskButtonSelectedText: ColorTaskFontSelected,
  taskButtonDisabledText: ColorNeutralDark,
  taskButtonActiveBackground: ColorSecondaryLighter,
  taskButtonHoveredBackground: ColorPrimaryLightest,

  patientEncounterSummarySectionHeaderText: ColorPrimaryDark,
  patientEncounterSummarySectionHeaderTextRight: ColorTypeDarker,
  patientEncounterSummarySectionHeaderUnderline: ColorSecondaryLighter,
  patientEncounterSummaryRowHeaderText: ColorTypeDarker,
  patientEncounterSummaryContentText: ColorNeutralDarkest,
  patientEncounterSummaryVitalsTableText: ColorTypeDarker,
  patientEncounterSummaryCriticalText: ColorCriticalDarker,
  patientEncounterSummaryCriticalBackground: ColorCriticalLightest,
  patientEncounterSummaryAbnormalText: ColorWarningDarker,
  patientEncounterSummaryAbnormalBackground: ColorWarningLightest,
  patientEncounterSummaryNormalText: ColorHighlightDarkest,
  patientEncounterSummaryNormalBackground: ColorHighlightLightest,
  patientEncounterSummaryStatusPillText: ColorNeutralDarkest,
  patientEncounterSummaryStatusPillIcon: ColorTypeDarker,
  patientEncounterSummaryStatusPillBackground: ColorNeutralLightest,
  patientEncounterSummaryStatusPillTextEmphasized: ColorPrimaryDarker,
  patientEncounterSummaryStatusPillBackgroundEmphasized: ColorPrimaryLightest,
  patientEncounterSummaryLogoPlusIcon: ColorTypeDarker,
  patientEncounterSummaryLinkText: ColorPrimaryDarker,
  patientEncounterSummaryPainScreeningHeaderText: ColorButtonPrimaryActive,
  patientEncounterSummaryPainScreeningEmergencyRoomHeader: ColorCriticalDarker,
  patientEncounterSummaryPainScreeningUrgentCareHeader: ColorWarningDark,
  patientEncounterSummaryPainScreeningTableHeaderText: ColorPrimaryDarker,
  patientEncounterSummaryPainScreeningPainValueText: ColorPrimaryDarker,
  patientEncounterSummaryPainScreeningPainValueDescriptionText: ColorTypeDarker,

  patientEncounterSummaryHeaderHorizontalDividerColor: ColorNeutralMain,
  patientEncounterSummaryBoneDensityScanImageLabelColor: ColorBrandTertiaryDarkest,

  postReviewQueueBackgroundColor: ColorBackgroundDefault,

  labelQuote: ColorTypeDark,
  labelText: ColorTypeDarker,

  visitTypeIconColor: RainbowBilobaDarker,
  verticalDividerColor: ColorSecondaryDarkest,
};
