import React from 'react';
import { Linking } from 'react-native';

import { KHModalDialog } from '../../khui';
import getAndroidAppInstallURL from '../../utils/getAndroidAppInstallURL';
import useAppVersion from '../../utils/useAppVersion';
import useLogger from '../../utils/useLogger';

export default function AppVersionWarningModal(): JSX.Element {
  const [showWarning, dismissWarning] = useAppVersion();

  const logger = useLogger();

  return showWarning ? (
    <KHModalDialog>
      <KHModalDialog.Title>New update available!</KHModalDialog.Title>
      <KHModalDialog.Content>
        <KHModalDialog.Paragraph>
          There is a new version available, please update the app to receive new features and bug
          fixes.
        </KHModalDialog.Paragraph>

        <KHModalDialog.Actions
          primary={
            <KHModalDialog.Action
              primary
              onPress={() => {
                dismissWarning();
                void Linking.openURL(getAndroidAppInstallURL());
              }}
              logger={logger}
              loggerEventName="appVersionWarningUpdateNowButtonClick"
            >
              Update now
            </KHModalDialog.Action>
          }
          secondary={
            <KHModalDialog.Action
              onPress={() => dismissWarning()}
              logger={logger}
              loggerEventName="appVersionWarningDismissButtonClick"
            >
              I'll update later
            </KHModalDialog.Action>
          }
        />
      </KHModalDialog.Content>
    </KHModalDialog>
  ) : (
    <></>
  );
}
