// currently these values were manually added as needed
// in the future this file will be autogenerated based on an export from figma

export const ColorSecondaryDarkest = '#2C4556';
export const ColorSecondaryDark = '#7393a5';
export const ColorSecondaryMain = '#93b0c0';
export const ColorSecondaryLight = '#b0cad9';
export const ColorSecondaryLighter = '#d8e3e9';
export const ColorSecondaryLightest = '#e9eef0';
export const ColorSecondaryNav = '#3B5E72';
export const ColorNeutralDarkest = '#1e1e1e';
export const ColorNeutralDark = '#7e849d';
export const ColorNeutralMain = '#a5a9bb';
export const ColorNeutralLighter = '#d9dadd';
export const ColorNeutralLightest = '#f3f4f5';
export const ColorTypeDarkest = '#1e1e1e';
export const ColorTypeDarker = '#5c677e';
export const ColorTypeDark = '#7e849d';
export const ColorTypeInverse = '#ffffff';
export const ColorBackgroundPaper = '#ffffff';
export const ColorBackgroundDefault = '#f5f5f5';
export const ColorActionTaskActiveType = '#425365';
export const ColorButtonPrimaryActive = '#502997';
export const ColorPrimaryDarker = '#5d4b9f';
export const ColorPrimaryDark = '#806ad1';
export const ColorPrimaryLightest = '#f7f1ff';
export const ColorCriticalDarker = '#9b2c13';
export const ColorCriticalLight = '#efb6b6';
export const ColorCriticalLightest = '#ffe8e8';
export const ColorWarningDarkest = '#664010';
export const ColorWarningDarker = '#714918';
export const ColorWarningDark = '#af6d09';
export const ColorWarningMain = '#cfa05a';
export const ColorWarningLight = '#f5bc67';
export const ColorWarningLightest = '#ffefc5';
export const ColorHighlightDarkest = '#42512a';
export const ColorHighlightMain = '#9ab46f';
export const ColorHighlightLight = '#B9CB9B';
export const ColorHighlightLightest = '#e8f0ca';
export const ColorTabsTextActive = '#3b5e72';
export const ColorCloseButton = '#605f78';

export const ColorTextLinkNew = '#543B9A';

export const ColorPrimaryFillDefault = '#502997';
export const ColorPrimaryStrokeDefault = '#502997';
export const ColorPrimaryFontDefault = '#FFFFFF';
export const ColorPrimaryFillHover = '#9257FE';
export const ColorPrimaryStrokeHover = '#9257FE';
export const ColorPrimaryFontHover = '#FFFFFF';
export const ColorPrimaryFillSelected = '#7393A5';
export const ColorPrimaryStrokeSelected = '#7393A5';
export const ColorPrimaryFontSelected = '#FFFFFF';
export const ColorSecondaryFillDefault = 'transparent';
export const ColorSecondaryStrokeDefault = '#502997';
export const ColorSecondaryFontDefault = '#502997';
export const ColorSecondaryFillHover = 'transparent';
export const ColorSecondaryStrokeHover = '#9257FE';
export const ColorSecondaryFontHover = '#9257FE';
export const ColorSecondaryFillSelected = 'transparent';
export const ColorSecondaryStrokeSelected = '#7393A5';
export const ColorSecondaryFontSelected = '#7393A5';
export const ColorTertiaryFillDefault = 'transparent';
export const ColorTertiaryStrokeDefault = 'transparent';
export const ColorTertiaryFontDefault = '#502997';
export const ColorTertiaryFillHover = 'transparent';
export const ColorTertiaryStrokeHover = 'transparent';
export const ColorTertiaryFontHover = '#9257FE';
export const ColorTertiaryFillSelected = 'transparent';
export const ColorTertiaryStrokeSelected = 'transparent';
export const ColorTertiaryFontSelected = '#7393A5';

export const ColorPrimaryFillDefaultSubtle = '#93B0C0';
export const ColorPrimaryStrokeDefaultSubtle = '#93B0C0';
export const ColorPrimaryFontDefaultSubtle = '#FFFFFF';
export const ColorPrimaryFillHoverSubtle = '#9257FE';
export const ColorPrimaryStrokeHoverSubtle = '#9257FE';
export const ColorPrimaryFontHoverSubtle = '#FFFFFF';
export const ColorPrimaryFillSelectedSubtle = '#7393A5';
export const ColorPrimaryStrokeSelectedSubtle = '#7393A5';
export const ColorPrimaryFontSelectedSubtle = '#FFFFFF';
export const ColorSecondaryFillDefaultSubtle = 'transparent';
export const ColorSecondaryStrokeDefaultSubtle = '#93B0C0';
export const ColorSecondaryFontDefaultSubtle = '#3B5E72';
export const ColorSecondaryFillHoverSubtle = 'transparent';
export const ColorSecondaryStrokeHoverSubtle = '#9257FE';
export const ColorSecondaryFontHoverSubtle = '#9257FE';
export const ColorSecondaryFillSelectedSubtle = 'transparent';
export const ColorSecondaryStrokeSelectedSubtle = '#7393A5';
export const ColorSecondaryFontSelectedSubtle = '#7393A5';
export const ColorTertiaryFillDefaultSubtle = 'transparent';
export const ColorTertiaryStrokeDefaultSubtle = 'transparent';
export const ColorTertiaryFontDefaultSubtle = '#3B5E72';
export const ColorTertiaryFillHoverSubtle = 'transparent';
export const ColorTertiaryStrokeHoverSubtle = 'transparent';
export const ColorTertiaryFontHoverSubtle = '#9257FE';
export const ColorTertiaryFillSelectedSubtle = 'transparent';
export const ColorTertiaryStrokeSelectedSubtle = 'transparent';
export const ColorTertiaryFontSelectedSubtle = '#7393A5';

export const ColorFilterStrokeDefault = '#3b5e72';
export const ColorFilterStrokeHover = '#D8E3E9';
export const ColorFilterStrokeSelected = '#D8E3E9';
export const ColorFilterStrokeDisabled = '#C0C4CC';
export const ColorFilterFontDefault = '#3b5e72';
export const ColorFilterFontHover = '#FFFFFF';
export const ColorFilterFontSelected = '#FFFFFF';
export const ColorFilterFontDisabled = '#5C677E';
export const ColorFilterFillDefault = '#D8E3E9';
export const ColorFilterFillHover = '#9E7FE8';
export const ColorFilterFillSelected = '#7393A5';
export const ColorFilterFillDisabled = '#D8E3E9';

export const ColorCaptionFontNeutral = '#1E1E1E';
export const ColorCaptionFillNeutral = 'transparent';
export const ColorCaptionFontHighlight = '#42512a';
export const ColorCaptionFillHighlight = '#E8F0CA';
export const ColorCaptionFontWarning = '#664010';
export const ColorCaptionFillWarning = '#FFEFC5';
export const ColorCaptionFontCritical = '#83220C';
export const ColorCaptionFillCritical = '#FFE8E8';

export const ColorFilterButtonV2BackgroundHover = '#9257fe';
export const ColorFilterButtonV2BackgroundSelected = '#3b5e72';
export const ColorTaskFontSelected = '#2c4556';

export const ColorToastStroke = '#5C677E';
export const ColorToastFill = '#7E849D';
export const ColorToastFont = '#FFFFFF';

export const ColorTextInputStrokeFocus = '#9257FE';
export const ColorTextInputStrokeDefault = '#5C677E';
export const ColorTextInputFontFocus = '#1E1E1E';

export const ColorLightPine = '#D4DBBC';
export const ColorPine = '#69724B';
export const ColorBrandTertiaryDarkest = '#663E1A';

export const RainbowBilobaDarker = '#7663a8';

export const Rainbow = {
  sienna: { lighter: '#ffd5ba', dark: '#CC6324', darker: '#b04e13', darkest: '#7E3103' },
  apricot: { lighter: '#ffcab1', dark: '#E7593C', darker: '#be3014', darkest: '#8A1801' },
  brandy: { lighter: '#e9cac8', dark: '#B67973', darker: '#99554e', darkest: '#683F3B' },
  orchid: { lighter: '#f7c0dd', dark: '#BA76AB', darker: '#985789', darkest: '#6B365F' },
  biloba: { lighter: '#dbd0f9', dark: '#9682C9', darker: '#7663a8', darkest: '#50407A' },
  portage: { lighter: '#c6cdef', dark: '#7687DA', darker: '#4d64d3', darkest: '#2D409E' },
  danube: { lighter: '#b2d5ef', dark: '#4980AA', darker: '#315b7b', darkest: '#18496E' },
  glacier: { lighter: '#a6d4f7', dark: '#5F90B6', darker: '#37709c', darkest: '#144B74' },
  neptune: { lighter: '#aedae8', dark: '#7AAEBE', darker: '#387386', darkest: '#0E495B' },
  gulf: { lighter: '#b6ecec', dark: '#7FB4B4', darker: '#4b7070', darkest: '#305050' },
  bay: { lighter: '#c0d7cc', dark: '#75B492', darker: '#0d703f', darkest: '#05542D' },
  tea: { lighter: '#c0d7ae', dark: '#709A4C', darker: '#57773b', darkest: '#3C5228' },
  chelsea: { lighter: '#d9e9a9', dark: '#849358', darker: '#636e43', darkest: '#434932' },
  husk: { lighter: '#d9da9b', dark: '#84860C', darker: '#6e7004', darkest: '#4D4E1D' },
  mongoose: { lighter: '#ece19e', dark: '#938D5F', darker: '#71692a', darkest: '#524800' },
  jasmine: { lighter: '#f8df8c', dark: '#AD8504', darker: '#7f6204', darkest: '#5B4706' },
  gold: { lighter: '#fada94', dark: '#BD8204', darker: '#916403', darkest: '#624300' },
  twine: { lighter: '#f5d6ab', dark: '#BF7C2A', darker: '#a05d0a', darkest: '#6B3B00' },
  zorba: { lighter: '#e5d1c3', dark: '#917056', darker: '#745c48', darkest: '#584638' },
  pearl: { lighter: '#d9dadd', dark: '#7E849D', darker: '#5c677e', darkest: '#1E1E1E' },
};

// colors for each sprinter on the team availability page
export const SprinterColors = [
  { lighter: Rainbow.sienna.lighter, darker: Rainbow.sienna.darker },
  { lighter: Rainbow.orchid.lighter, darker: Rainbow.orchid.darker },
  { lighter: Rainbow.danube.lighter, darker: Rainbow.danube.darker },
  { lighter: Rainbow.gulf.lighter, darker: Rainbow.gulf.darker },
  { lighter: Rainbow.chelsea.lighter, darker: Rainbow.chelsea.darker },
  { lighter: Rainbow.jasmine.lighter, darker: Rainbow.jasmine.darker },
  { lighter: Rainbow.zorba.lighter, darker: Rainbow.zorba.darker },
  { lighter: Rainbow.apricot.lighter, darker: Rainbow.apricot.darker },
  { lighter: Rainbow.biloba.lighter, darker: Rainbow.biloba.darker },
  { lighter: Rainbow.glacier.lighter, darker: Rainbow.glacier.darker },
  { lighter: Rainbow.bay.lighter, darker: Rainbow.bay.darker },
  { lighter: Rainbow.husk.lighter, darker: Rainbow.husk.darker },
  { lighter: Rainbow.gold.lighter, darker: Rainbow.gold.darker },
  { lighter: Rainbow.pearl.lighter, darker: Rainbow.pearl.darker },
  { lighter: Rainbow.brandy.lighter, darker: Rainbow.brandy.darker },
  { lighter: Rainbow.portage.lighter, darker: Rainbow.portage.darker },
  { lighter: Rainbow.neptune.lighter, darker: Rainbow.neptune.darker },
  { lighter: Rainbow.tea.lighter, darker: Rainbow.tea.darker },
  { lighter: Rainbow.mongoose.lighter, darker: Rainbow.mongoose.darker },
  { lighter: Rainbow.twine.lighter, darker: Rainbow.twine.darker },
];
