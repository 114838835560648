import { PartnerOrganization } from '../API';
import formatPhone from '../formatPhone';
import getPartnerConfig from '../metadata/getPartnerConfig';

export default function formatPartnerOrganization(
  partnerOrganization: PartnerOrganization | undefined | null,
  format:
    | 'displayName'
    | 'fullPartnerDisplayNameForMessaging'
    | 'shortPartnerDisplayNameForMessaging'
    | 'email'
    | 'mailto'
    | 'phone'
    | 'tel'
    | 'zendeskTag'
    | 'fullContactDetails',
  overrideWithBrandName?: string | null,
): string {
  if (partnerOrganization == null) return '';

  const config = getPartnerConfig(partnerOrganization);
  switch (format) {
    case 'displayName':
      return overrideWithBrandName ?? config.displayName;
    case 'fullPartnerDisplayNameForMessaging':
      return (
        overrideWithBrandName ?? config.fullPartnerDisplayNameForMessaging ?? config.displayName
      );
    case 'shortPartnerDisplayNameForMessaging':
      return (
        overrideWithBrandName ??
        config.shortPartnerDisplayNameForMessaging ??
        formatPartnerOrganization(partnerOrganization, 'fullPartnerDisplayNameForMessaging')
      );
    case 'email':
      return config.patientSupportEmail ?? '';
    case 'mailto':
      return `mailto:${config.patientSupportEmail ?? ''}`;
    case 'phone':
      return formatPhone(config.patientSupportPhoneE164) ?? '';
    case 'tel':
      return `tel:${config.patientSupportPhoneE164 ?? ''}`;
    case 'zendeskTag':
      return `partner_${partnerOrganization.toLowerCase()}`;
    case 'fullContactDetails': {
      const formattedDisplayName =
        overrideWithBrandName != null
          ? overrideWithBrandName
          : config.displayName
          ? `${config.displayName} at `
          : '';

      const email = config.patientSupportEmail;
      const phone = formatPhone(config.patientSupportPhoneE164);
      if (email && phone) {
        return `${formattedDisplayName}${email} or ${phone}`;
      }
      if (email || phone) {
        return `${formattedDisplayName}${email || phone || ''}`; // The final || '' must be there to remove a lint error
      }
      return overrideWithBrandName ?? config.displayName;
    }
    default:
      return '';
  }
}
