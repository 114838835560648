/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://hwuxnserjbdstallqjeanwlxiq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "wwwREST",
            "endpoint": "https://0tnxl1quu6.execute-api.us-west-2.amazonaws.com/prod",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:d4633661-af62-451a-8298-61b479bd8ee3",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_LPAPJr2vw",
    "aws_user_pools_web_client_id": "2msae2j7of4gktkkpisti6firq",
    "oauth": {
        "domain": "www-identity-sprinter-health-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "openid",
            "email",
            "profile"
        ],
        "redirectSignIn": "https://e2e-one.d1j7gv4p0wvp5e.amplifyapp.com,https://clitestnet.dysrf8h3m670v.amplifyapp.com/,https://app.sprinterhealth.com/,http://localhost:3000/,https://jamesnet.d1j7gv4p0wvp5e.amplifyapp.com/,https://testnet-latest.d1j7gv4p0wvp5e.amplifyapp.com/,https://testnet.d1j7gv4p0wvp5e.amplifyapp.com/,https://testnet-ii.d1j7gv4p0wvp5e.amplifyapp.com/",
        "redirectSignOut": "https://e2e-one.d1j7gv4p0wvp5e.amplifyapp.com,https://clitestnet.dysrf8h3m670v.amplifyapp.com,https://app.sprinterhealth.com/,http://localhost:3000/,https://jamesnet.d1j7gv4p0wvp5e.amplifyapp.com/,https://testnet-latest.d1j7gv4p0wvp5e.amplifyapp.com/,https://testnet.d1j7gv4p0wvp5e.amplifyapp.com/,https://testnet-ii.d1j7gv4p0wvp5e.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "www42faeb05513049ec9991b79ddefc34d5111115-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
