import { captureException } from '@sentry/react';
import { useCallback, useState } from 'react';

import useIsMountedRef from './useIsMountedRef';

export class ReentrancyError extends Error {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(fn: Function) {
    super(
      `Async action ${fn.name || '[anonymous]'} was called again while still in progress. This ` +
        'probably indicates a race condition. Either use isDoingAction to prevent reentrant ' +
        'calls or, if support for reentrance is desired, switch to useReentrantAsyncAction.',
    );
    this.name = 'ReentrancyError';
  }
}

/**
 * Decorates the specified async function with processing and error states.
 *
 * While a call is still processing, additional reentrant calls will throw, so it is recommended
 * to use `isDoingAction` to prevent such calls, for example using a button's `disabled` prop. If
 * reentrancy is desired, use `useReentrantAsyncAction` instead.
 *
 * `clearErrorDoingAction` function identity is guaranteed to be stable and won’t change on
 * re-renders.
 *
 * @throws {ReentrancyError} if `genDoActionWithState` is called again while still in progress.
 *
 * @example
 * function UserProfile({id}) {
 *   const [user, setUser] = useState();
 *   const [genUser, isGenningUser, errorGenningUser, clearErrorGenningUser] =
 *     useAsyncAction(async () => {
 *       setUser(await axios.get(`/user/${id}`));
 *     });
 *
 *   useEffect(() => {
 *     let timeoutID;
 *     if (errorGenningUser) {
 *       timeoutID = setTimeout(() => {
 *         clearErrorGenningUser();
 *       }, 3000);
 *     }
 *     return () => clearTimeout(timeoutID);
 *   }, [errorGenningUser]);
 *
 *   return (
 *     <div className={errorGenningUser != null && 'error'}>
 *       {user == null && (
 *         <button disabled={isGenningUser} onClick={genUser}>Fetch</button>
 *       )}
 *       {isGenningUser ? 'Loading…' :
 *         errorGenningUser != null ? errorGenningUser.message :
 *         user.name}
 *     </div>
 *   );
 * }
 */
export function useAsyncAction<A extends unknown[], R>(
  doAction: (...args: A) => Promise<R>,
): [
  genDoActionWithState: (...args: A) => Promise<R>,
  isDoingAction: boolean,
  errorDoingAction: unknown | null,
  clearErrorDoingAction: () => void,
] {
  const isMountedRef = useIsMountedRef();
  const [isDoingAction, setIsDoingAction] = useState<boolean>(false);
  const [errorDoingAction, setErrorDoingAction] = useState<unknown | null>(null);

  const genDoActionWithState = useCallback(
    async (...args: A): Promise<R> => {
      setIsDoingAction((prevIsDoingAction) => {
        if (prevIsDoingAction) {
          throw new ReentrancyError(doAction);
        }
        return true;
      });
      setErrorDoingAction(null);

      try {
        return await doAction(...args);
      } catch (e: unknown) {
        if (isMountedRef.current) setErrorDoingAction(e);
        captureException(e);
        throw e;
      } finally {
        if (isMountedRef.current) setIsDoingAction(false);
      }
    },
    [doAction, isMountedRef],
  );

  const clearErrorDoingAction = useCallback(() => setErrorDoingAction(null), []);

  return [genDoActionWithState, isDoingAction, errorDoingAction, clearErrorDoingAction];
}

/**
 * Decorates the specified reentrant async function with processing and error states.
 *
 * In the case of reentrant action calls, only the last error caught will be stored in state and
 * will only be cleared by subsequent action calls, not by successful fulfillments of other
 * outstanding calls.
 *
 * `clearErrorDoingAction` function identity is guaranteed to be stable and won’t change on
 * re-renders.
 *
 * @example
 * function UserProfile({users}) {
 *   const [genDeleteUser, pendingDeleteUserCalls, errorDeletingUser, clearErrorDeletingUser] =
 *     useAsyncAction(async (id) => {
 *       await axios.delete(`/user/${id}`));
 *     });
 *
 *   useEffect(() => {
 *     let timeoutID;
 *     if (errorDeletingUser) {
 *       timeoutID = setTimeout(() => {
 *         clearErrorDeletingUser();
 *       }, 3000);
 *     }
 *     return () => clearTimeout(timeoutID);
 *   }, [errorDeletingUser]);
 *
 *   return (
 *     <div>
 *       <ul>
 *         {users.map(user => (
 *           <li>
 *             {user.name}
 *             <button onClick={() => genDeleteUser(user.id)}>Delete</button>
 *           </li>
 *         ))}
 *       </ul>
 *       {pendingDeleteUserCalls > 0 && (
 *         <div>Deleting {pendingDeleteUserCalls} user(s)…</div>
 *       )}
 *       {errorDeletingUser != null && (
 *         <div className="error">{errorDeletingUser.message}</div>
 *       )}
 *     </div>
 *   );
 * }
 */
export function useReentrantAsyncAction<A extends unknown[], R>(
  doAction: (...args: A) => Promise<R>,
): [
  genDoActionWithState: (...args: A) => Promise<R>,
  pendingActions: number,
  errorDoingAction: unknown | null,
  clearErrorDoingAction: () => void,
] {
  const isMountedRef = useIsMountedRef();
  const [pendingActions, setPendingActions] = useState<number>(0);
  const [errorDoingAction, setErrorDoingAction] = useState<unknown | null>(null);

  const genDoActionWithState = useCallback(
    async (...args: A): Promise<R> => {
      setErrorDoingAction(null);
      setPendingActions((prevPendingActions) => prevPendingActions + 1);

      try {
        return await doAction(...args);
      } catch (e: unknown) {
        if (isMountedRef.current) setErrorDoingAction(e);
        throw e;
      } finally {
        if (isMountedRef.current) setPendingActions((prevPendingActions) => prevPendingActions - 1);
      }
    },
    [doAction, isMountedRef],
  );

  const clearErrorDoingAction = useCallback(() => setErrorDoingAction(null), []);

  return [genDoActionWithState, pendingActions, errorDoingAction, clearErrorDoingAction];
}
