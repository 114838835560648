import KHColors from 'khshared/KHColors';
import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

const styles = {
  view: {
    backgroundColor: KHColors.separator,
  } as ViewStyle,
  horizontal: {
    height: StyleSheet.hairlineWidth,
  } as ViewStyle,
  vertical: {
    width: StyleSheet.hairlineWidth,
  } as ViewStyle,
};

interface Props {
  style?: StyleProp<ViewStyle>;
  vertical?: boolean;
}

export default function KHSeparator({ style, vertical = false }: Props): JSX.Element {
  return <View style={[styles.view, vertical ? styles.vertical : styles.horizontal, style]} />;
}
