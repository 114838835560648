import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { Redirect, Route } from 'react-router';

export default function DevRoute({
  desktopOnly,
  ...props
}: React.ComponentProps<typeof Route> & { desktopOnly?: boolean }): JSX.Element {
  useEffect(() => {
    const rootTag = Platform.OS === 'web' ? document.getElementById('root') : null;
    if (!rootTag) return;

    rootTag.classList[desktopOnly ? 'remove' : 'add']('pillarbox');

    // Intentionally skip cleaning up to avoid a flash of pillarboxed content when navigating
    // between desktop-only pages (e.g. the partner/scheduler portals).
  }, [desktopOnly]);

  if (!__DEV__) {
    return <Redirect to="/" />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...props} />;
}
