import React from 'react';
import { StyleProp, TextStyle } from 'react-native';

import formatError from '../utils/formatError';
import KHText from './KHText';

interface Props {
  error: Parameters<typeof formatError>[0];
  style?: StyleProp<TextStyle>;
  verbose?: boolean;
}

export default function KHErrorText({
  error,
  style,
  verbose = __DEV__ && process.env.AWS_BRANCH !== 'demonet',
}: Props): JSX.Element | null {
  return error == null ? null : (
    <KHText.CaptionError style={style}>
      {formatError(error, verbose ? 'verbose' : 'simple')}
    </KHText.CaptionError>
  );
}
