import { TimeRangeRuleInclusion, TimeRangeRuleInput, TimeRangeRulePeriod } from '../API';

// Returns time range rules that include between `startTime` and `endTime` on weekdays.
export default function createWeekdaysTimeRangeRules(
  uuid: string,
  startTime: string,
  endTime: string,
): TimeRangeRuleInput[] {
  return [
    {
      id: `weekday1${uuid.slice(8)}`,
      inclusion: TimeRangeRuleInclusion.INCLUDE,
      start: startTime,
      end: endTime,
      recurrenceStart: '2021-01-04', // Monday.
      recurrenceMultiple: 1,
      recurrencePeriod: TimeRangeRulePeriod.WEEK,
    },
    {
      id: `weekday2${uuid.slice(8)}`,
      inclusion: TimeRangeRuleInclusion.INCLUDE,
      start: startTime,
      end: endTime,
      recurrenceStart: '2021-01-05', // Tuesday.
      recurrenceMultiple: 1,
      recurrencePeriod: TimeRangeRulePeriod.WEEK,
    },
    {
      id: `weekday3${uuid.slice(8)}`,
      inclusion: TimeRangeRuleInclusion.INCLUDE,
      start: startTime,
      end: endTime,
      recurrenceStart: '2021-01-06', // Wednesday.
      recurrenceMultiple: 1,
      recurrencePeriod: TimeRangeRulePeriod.WEEK,
    },
    {
      id: `weekday4${uuid.slice(8)}`,
      inclusion: TimeRangeRuleInclusion.INCLUDE,
      start: startTime,
      end: endTime,
      recurrenceStart: '2021-01-07', // Thursday.
      recurrenceMultiple: 1,
      recurrencePeriod: TimeRangeRulePeriod.WEEK,
    },
    {
      id: `weekday5${uuid.slice(8)}`,
      inclusion: TimeRangeRuleInclusion.INCLUDE,
      start: startTime,
      end: endTime,
      recurrenceStart: '2021-01-01', // Friday.
      recurrenceMultiple: 1,
      recurrencePeriod: TimeRangeRulePeriod.WEEK,
    },
  ];
}
