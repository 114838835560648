import { LabOrganization, ProductType, QualificationType, ServiceType } from '../API';
import { MINUTE_IN_SECONDS } from '../constants';
import isNotNullish from '../isNotNullish';
import ConditionalFields from '../types/ConditionalFields';

const DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW = 18;
type ProductTypeConfig = {
  minPatientsYearsOfAge?: number; // inclusive
  maxPatientsYearsOfAge?: number; // inclusive (ie. 20 -> 20 year olds allowed, 21 year olds not allowed)
  estimatedServiceDurationSeconds: number;
  serviceType: ServiceType[];
  defaultSprinterHealthExternalID: string;
  isDropOffRequired?: boolean;
  isCareNavigationProductType?: boolean;
} & ConditionalFields<
  { isEligibleToSkipPrereview: true },
  {
    eligibleServicesToSkipPrereview: { [key in LabOrganization]?: string[] };
    minAgeToSkipPrereview: number; // exclusive (ie. if the age limit is 18, then 18 year olds are allowed to skip)
  }
> &
  ConditionalFields<
    { hasRemoteComponent: true }, // whether or not this service includes a video call with a remote tech
    { remoteTechQualificationTypeRequired: QualificationType }
  >;

export const PRODUCT_TYPE_CONFIGS: {
  [productType in ProductType]: ProductTypeConfig;
} = {
  [ProductType.CARDIAC_AUSCULTATION]: {
    estimatedServiceDurationSeconds: 4 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.CARDIAC_AUSCULTATION],
    defaultSprinterHealthExternalID: 'CARDIAC-AUSCULTATION',
    isEligibleToSkipPrereview: false,
    hasRemoteComponent: false,
  },
  [ProductType.DIABETIC_FOOT_SCREENING]: {
    estimatedServiceDurationSeconds: 6 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.DIABETIC_FOOT_SCREENING],
    defaultSprinterHealthExternalID: 'DIABETIC-FOOT-SCREENING',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['DIABETIC-FOOT-SCREENING'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.DIABETIC_RETINAL_SCAN]: {
    estimatedServiceDurationSeconds: 10 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.DIABETIC_RETINAL_SCAN],
    defaultSprinterHealthExternalID: 'DIABETIC-RETINAL-SCAN',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['DIABETIC-RETINAL-SCAN'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.ECG]: {
    estimatedServiceDurationSeconds: 15 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.ECG],
    defaultSprinterHealthExternalID: 'ELECTROCARDIOGRAM-12-LEAD',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['ELECTROCARDIOGRAM-12-LEAD'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.LAB_TEST]: {
    estimatedServiceDurationSeconds: 10 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.BLOOD, ServiceType.URINE, ServiceType.CAPILLARY_BLOOD_DRAW],
    isDropOffRequired: true,
    defaultSprinterHealthExternalID: '',
    // In general, lab tests (e.g., blood or urine services) are not eligible to skip pre-review because the test codes
    // must be entered into the requisition during pre-review. However, for specialty labs and some exceptions (lead screening), we do allow the skipping of pre-review.
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: { QUEST: ['39027'] },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: 13,
  },
  [ProductType.RAPID_COVID_TEST]: {
    estimatedServiceDurationSeconds: 15 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.RAPID_COVID_TEST],
    defaultSprinterHealthExternalID: '',
    isEligibleToSkipPrereview: false,
    hasRemoteComponent: false,
  },
  [ProductType.VITALS]: {
    estimatedServiceDurationSeconds: 3 * MINUTE_IN_SECONDS,
    minPatientsYearsOfAge: 18,
    serviceType: [ServiceType.VITALS],
    defaultSprinterHealthExternalID: 'VITALS-CHECK',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: { SPRINTER_HEALTH: ['VITALS-CHECK', 'BLOOD-PRESSURE-CHECK'] },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.XRAY]: {
    estimatedServiceDurationSeconds: 20 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.XRAY],
    defaultSprinterHealthExternalID: '',
    isEligibleToSkipPrereview: false,
    hasRemoteComponent: false,
  },
  [ProductType.FIT_KIT_PICKUP]: {
    estimatedServiceDurationSeconds: 3 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.FIT_KIT_PICKUP],
    isDropOffRequired: true,
    defaultSprinterHealthExternalID: 'FIT-KIT-PICKUP',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: { SPRINTER_HEALTH: ['FIT-KIT-PICKUP'] },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.CARE_NAVIGATION_DROP_IN]: {
    estimatedServiceDurationSeconds: 10 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.CARE_NAVIGATION],
    isDropOffRequired: false,
    defaultSprinterHealthExternalID: 'PRACTITIONER-APPOINTMENT-SCHEDULING',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: { SPRINTER_HEALTH: ['PRACTITIONER-APPOINTMENT-SCHEDULING'] },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.KIT_DELIVERY_AND_EDUCATION]: {
    estimatedServiceDurationSeconds: 10 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.KIT_DELIVERY_AND_EDUCATION],
    isDropOffRequired: false,
    defaultSprinterHealthExternalID: 'INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['INSURE-ONE-FIT-KIT-DELIVERY-AND-EDUCATION'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.QUESTIONNAIRES]: {
    estimatedServiceDurationSeconds: 5 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.RACE_AND_ETHNICITY, ServiceType.SDOH_QUESTIONNAIRE],
    isDropOffRequired: false,
    defaultSprinterHealthExternalID: '',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['RACE-AND-ETHNICITY-QUESTIONNAIRE', 'SDOH-QUESTIONNAIRE'],
    },
    hasRemoteComponent: false,
    // this is a service that should not be done alone generally and should not block other services from being auto pre-reviewed
    minAgeToSkipPrereview: 0,
  },
  [ProductType.SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE]: {
    estimatedServiceDurationSeconds: 10 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE],
    isDropOffRequired: false,
    defaultSprinterHealthExternalID: 'PRAPARE_SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['PRAPARE_SDOH_CARE_NAVIGATION_RESOURCE_GUIDANCE'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.KIT_DELIVERY]: {
    estimatedServiceDurationSeconds: 5 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.KIT_DELIVERY],
    isDropOffRequired: false,
    defaultSprinterHealthExternalID: 'CONSULT-FECAL-OCCULT-BLOOD-TEST-KIT-DELIVERY',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['CONSULT-FECAL-OCCULT-BLOOD-TEST-KIT-DELIVERY'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.DEXA_SCAN_CARE_NAVIGATION_BOOKING]: {
    estimatedServiceDurationSeconds: 0 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.DEXA_SCAN_CARE_NAVIGATION_BOOKING],
    defaultSprinterHealthExternalID: 'DEXA_SCAN_CARE_NAVIGATION_BOOKING',
    isEligibleToSkipPrereview: false,
    isCareNavigationProductType: true,
    hasRemoteComponent: false,
  },
  [ProductType.DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION]: {
    estimatedServiceDurationSeconds: 0 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION],
    defaultSprinterHealthExternalID: 'DEXA_SCAN_CARE_NAVIGATION_CONFIRMATION',
    isEligibleToSkipPrereview: false,
    isCareNavigationProductType: true,
    hasRemoteComponent: false,
  },
  [ProductType.MAMMOGRAM_CARE_NAVIGATION_BOOKING]: {
    estimatedServiceDurationSeconds: 0 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.MAMMOGRAM_CARE_NAVIGATION_BOOKING],
    defaultSprinterHealthExternalID: 'MAMMOGRAM_CARE_NAVIGATION_BOOKING',
    isEligibleToSkipPrereview: false,
    isCareNavigationProductType: true,
    hasRemoteComponent: false,
  },
  [ProductType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION]: {
    estimatedServiceDurationSeconds: 0 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION],
    defaultSprinterHealthExternalID: 'MAMMOGRAM_CARE_NAVIGATION_CONFIRMATION',
    isEligibleToSkipPrereview: false,
    isCareNavigationProductType: true,
    hasRemoteComponent: false,
  },
  [ProductType.PCP_VISIT_CARE_NAVIGATION_BOOKING]: {
    estimatedServiceDurationSeconds: 0 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.PCP_VISIT_CARE_NAVIGATION_BOOKING],
    defaultSprinterHealthExternalID: 'PCP_VISIT_CARE_NAVIGATION_BOOKING',
    isEligibleToSkipPrereview: false,
    isCareNavigationProductType: true,
    hasRemoteComponent: false,
  },
  [ProductType.PCP_VISIT_CARE_NAVIGATION_CONFIRMATION]: {
    estimatedServiceDurationSeconds: 0 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.PCP_VISIT_CARE_NAVIGATION_CONFIRMATION],
    defaultSprinterHealthExternalID: 'PCP_VISIT_CARE_NAVIGATION_CONFIRMATION',
    isEligibleToSkipPrereview: false,
    isCareNavigationProductType: true,
    hasRemoteComponent: false,
  },
  [ProductType.HEIGHT_AND_WEIGHT_COLLECTION]: {
    estimatedServiceDurationSeconds: 5 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.HEIGHT_AND_WEIGHT_COLLECTION],
    defaultSprinterHealthExternalID: 'HEIGHT_AND_WEIGHT_COLLECTION',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['HEIGHT_AND_WEIGHT_COLLECTION'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.COLOGUARD_KIT_EDUCATION]: {
    estimatedServiceDurationSeconds: 10 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.COLOGUARD_KIT_EDUCATION],
    defaultSprinterHealthExternalID: 'COLOGUARD-KIT-EDUCATION',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['COLOGUARD-KIT-EDUCATION'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.COLOGUARD_KIT_ORDERING]: {
    estimatedServiceDurationSeconds: 0 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.COLOGUARD_KIT_ORDERING],
    defaultSprinterHealthExternalID: 'COLOGUARD_KIT_ORDERING',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['COLOGUARD_KIT_ORDERING'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.ANNUAL_WELLNESS_VISIT_PROVIDER_CALL]: {
    estimatedServiceDurationSeconds: 30 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.ANNUAL_WELLNESS_VISIT_PROVIDER_CALL],
    defaultSprinterHealthExternalID: 'ANNUAL_WELLNESS_VISIT_PROVIDER_CALL',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['ANNUAL_WELLNESS_VISIT_PROVIDER_CALL'],
    },
    hasRemoteComponent: true,
    remoteTechQualificationTypeRequired: QualificationType.NURSE_PRACTITIONER,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.PAIN_SCREENING]: {
    estimatedServiceDurationSeconds: 1 * MINUTE_IN_SECONDS, // TODO(Efficiency): verify if one minute makes sense
    serviceType: [ServiceType.PAIN_SCREENING],
    defaultSprinterHealthExternalID: 'PAIN_SCREENING',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['PAIN_SCREENING'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.BONE_DENSITY_SCAN]: {
    estimatedServiceDurationSeconds: 60 * MINUTE_IN_SECONDS,
    serviceType: [ServiceType.BONE_DENSITY_SCAN],
    defaultSprinterHealthExternalID: 'MINI_OMNI_BONE_DENSITY_SCAN',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['MINI_OMNI_BONE_DENSITY_SCAN'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.TOPICAL_FLUORIDE]: {
    estimatedServiceDurationSeconds: 10 * MINUTE_IN_SECONDS,
    minPatientsYearsOfAge: 1,
    maxPatientsYearsOfAge: 20,
    serviceType: [ServiceType.TOPICAL_FLUORIDE],
    defaultSprinterHealthExternalID: 'TOPICAL_FLUORIDE',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['TOPICAL_FLUORIDE'],
    },
    hasRemoteComponent: false,
    minAgeToSkipPrereview: 1, // we expect 1 year olds to be doing this service
  },
  [ProductType.STATIN_ADHERENCE_ASSESSMENT]: {
    estimatedServiceDurationSeconds: 0, // Service duration is covered by the AWV
    minPatientsYearsOfAge: 21,
    maxPatientsYearsOfAge: 75,
    serviceType: [ServiceType.STATIN_ADHERENCE_ASSESSMENT],
    defaultSprinterHealthExternalID: 'STATIN_ADHERENCE_ASSESSMENT',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['STATIN_ADHERENCE_ASSESSMENT'],
    },
    hasRemoteComponent: true,
    remoteTechQualificationTypeRequired: QualificationType.NURSE_PRACTITIONER,
    minAgeToSkipPrereview: DEFAULT_MIN_PATIENT_AGE_TO_SKIP_PREREVIEW,
  },
  [ProductType.WEIGHT_COUNSELING_FOR_CHILDREN]: {
    estimatedServiceDurationSeconds: 30 * MINUTE_IN_SECONDS,
    minPatientsYearsOfAge: 3,
    maxPatientsYearsOfAge: 17,
    serviceType: [ServiceType.WEIGHT_COUNSELING_FOR_CHILDREN],
    defaultSprinterHealthExternalID: 'WEIGHT_COUNSELING_FOR_CHILDREN',
    isEligibleToSkipPrereview: true,
    eligibleServicesToSkipPrereview: {
      SPRINTER_HEALTH: ['WEIGHT_COUNSELING_FOR_CHILDREN'],
    },
    hasRemoteComponent: true,
    remoteTechQualificationTypeRequired: QualificationType.NURSE_PRACTITIONER,
    minAgeToSkipPrereview: 3,
  },
};

/**
 * Returns the product type config for the given product type.
 * Helpful for determining the minimum age of the patient for a product type or similar constraints such as the service delivery window.
 * @param productType - The product type to get the config for
 */
export default function getProductTypeConfig(productType: ProductType): ProductTypeConfig {
  return PRODUCT_TYPE_CONFIGS[productType];
}

export const getMinPatientsYearsOfAgeFromProductTypes = (
  productTypesForPatient: ProductType[],
): number => {
  let minPatientsYearsOfAgeFromProductTypes: number | null = null;

  for (const productType of productTypesForPatient) {
    const { minPatientsYearsOfAge } = getProductTypeConfig(productType);

    if (
      isNotNullish(minPatientsYearsOfAge) &&
      minPatientsYearsOfAge < (minPatientsYearsOfAgeFromProductTypes ?? Infinity)
    ) {
      minPatientsYearsOfAgeFromProductTypes = minPatientsYearsOfAge;
    }
  }

  return minPatientsYearsOfAgeFromProductTypes ?? -Infinity;
};

/**
 * Returns the maximum of the minimum ages that patients are allowed to skip pre-review for the given product types
 * For product types with no minimum age, the minimum age is Infinity (because the field minAgeToSkipPrereview is required if isEligibleToSkipPrereview is true)
 */
export function getMinAgeToSkipPrereviewFromProductTypes(productTypes: ProductType[]): number {
  let maxPatientsAgeYearsAllowedToSkipPrereview: number | null = null;

  for (const productType of productTypes) {
    const { minAgeToSkipPrereview } = getProductTypeConfig(productType);

    if (
      minAgeToSkipPrereview == null ||
      maxPatientsAgeYearsAllowedToSkipPrereview == null ||
      minAgeToSkipPrereview > maxPatientsAgeYearsAllowedToSkipPrereview
    ) {
      maxPatientsAgeYearsAllowedToSkipPrereview = minAgeToSkipPrereview ?? null;
    }
  }

  return maxPatientsAgeYearsAllowedToSkipPrereview == null
    ? Infinity
    : maxPatientsAgeYearsAllowedToSkipPrereview;
}
