import KHColors from 'khshared/KHColors';
import React, { ReactNode, useContext, useRef } from 'react';
import {
  AccessibilityProps,
  ActivityIndicator,
  Animated,
  GestureResponderEvent,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import { LoggerProps } from '../utils/useLogger';
import useTooltipRef from '../utils/useTooltipRef';
import KHIcon from './KHIcon';
import KHThemeContext, { Theme } from './KHThemeContext';

const BORDER_RADIUS = 20;

const styles = {
  button: {
    alignItems: 'center',
    borderRadius: BORDER_RADIUS,
    borderStyle: 'solid',
    flexDirection: 'row',
    justifyContent: 'center',
    WebkitUserSelect: 'none',
    userSelect: 'none',
  } as ViewStyle,
  buttonPadding: {
    height: 40,
    minWidth: 40,
  } as ViewStyle,
  compact: {
    height: 'auto',
    minWidth: 'auto',
  } as ViewStyle,
  highlight: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: KHColors.buttonHighlight,
  } as ViewStyle,
  iconLeft: {
    marginLeft: 12,
    marginRight: -4,
  } as ViewStyle,
  label: {
    fontWeight: '500',
    marginHorizontal: 16,
    marginVertical: 9,
    textAlign: 'center',
  } as TextStyle,
  compactLabel: {
    marginHorizontal: 8,
  } as TextStyle,
  labelV2: {
    fontFamily: 'Red Hat Display',
    fontWeight: '500',
  } as TextStyle,
};

type StylesRules = {
  borderColor?: string;
  borderWidth?: number;
  backgroundColor?: string;
  highlight?: string;
  color: string;
};

const filledSeverityRules = {
  warning: {
    backgroundColor: KHColors.iconWarning,
    highlight: `${KHColors.iconWarning}33`,
    color: KHColors.textWarning,
  },
  critical: {
    backgroundColor: KHColors.criticalWarning,
    highlight: `${KHColors.criticalWarning}33`,
    color: KHColors.buttonLabel,
  },
  default: {
    backgroundColor: KHColors.buttonPrimary,
    highlight: `${KHColors.buttonPrimary}33`,
    color: KHColors.buttonLabel,
  },
  success: {
    backgroundColor: KHColors.success,
    highlight: `${KHColors.success}33`,
    color: KHColors.buttonLabel,
  },
};

const filledSeverityRulesV2 = {
  ...filledSeverityRules,
  default: {
    ...filledSeverityRules.default,
    backgroundColor: KHColors.referralBookingButton,
  },
};

const outlinedSeverityRules = {
  warning: {
    borderColor: KHColors.iconWarning,
    highlight: `${KHColors.iconWarning}33`,
    color: KHColors.textWarningWithoutBackground,
  },
  critical: {
    borderColor: KHColors.criticalWarning,
    highlight: `${KHColors.criticalWarning}33`,
    color: KHColors.criticalWarning,
  },
  default: {
    borderColor: KHColors.buttonPrimary,
    highlight: `${KHColors.buttonPrimary}33`,
    color: KHColors.buttonPrimary,
  },
  success: {
    borderColor: KHColors.success,
    highlight: `${KHColors.success}33`,
    color: KHColors.success,
  },
};
const textOnlySeverityRules = {
  warning: {
    color: KHColors.textWarningWithoutBackground,
  },
  critical: {
    color: KHColors.criticalWarning,
  },
  default: {
    color: KHColors.buttonPrimary,
  },
  success: {
    color: KHColors.success,
  },
};

export type Props<E extends `${string}ButtonClick`> = LoggerProps<E> & {
  children?: ReactNode;
  compact?: boolean;
  disabled?: boolean;
  icon?: string | ImageSourcePropType;
  iconSource?: Parameters<typeof KHIcon>[0]['iconSource'];
  iconColor?: string;
  labelStyle?: StyleProp<TextStyle>;
  loading?: boolean;
  tooltip?: string;
  onPress?: (e: GestureResponderEvent) => void;
  style?: StyleProp<ViewStyle>;
  severity?: 'default' | 'warning' | 'critical' | 'success';
  primary?: boolean;
  textOnly?: boolean;
  testID?: string;
} & AccessibilityProps & { accessibilityChecked?: boolean };

function getButtonStyle(settings: {
  primary: boolean;
  textOnly: boolean | undefined;
  uninteractable: boolean;
  severity: 'default' | 'warning' | 'critical' | 'success';
  theme: Theme;
}): StylesRules {
  let buttonStyles: StylesRules = {
    color: KHColors.buttonPrimary,
  };
  const { primary, textOnly, uninteractable, severity, theme } = settings;
  if (primary) {
    if (uninteractable) {
      buttonStyles = {
        ...buttonStyles,
        backgroundColor: KHColors.buttonDisabled,
        color: KHColors.buttonDisabledLabel,
      };
    } else {
      buttonStyles = {
        ...buttonStyles,
        ...filledSeverityRules[severity],
        ...(theme === 'v2' && filledSeverityRulesV2[severity]),
      };
    }
  } else if (!textOnly) {
    buttonStyles = { ...buttonStyles, borderWidth: StyleSheet.hairlineWidth };
    if (uninteractable) {
      buttonStyles = {
        ...buttonStyles,
        borderColor: KHColors.buttonDisabled,
        color: KHColors.buttonDisabledLabel,
      };
    } else {
      buttonStyles = { ...buttonStyles, ...outlinedSeverityRules[severity] };
    }
  } else if (uninteractable) {
    buttonStyles = { ...buttonStyles, color: KHColors.buttonDisabledLabel };
  } else {
    buttonStyles = { ...buttonStyles, ...textOnlySeverityRules[severity] };
  }

  return buttonStyles;
}

function constructTestIDForButton<E extends `${string}ButtonClick`>(
  props: Props<E>,
): string | undefined {
  if (props.testID) return props.testID;
  if (typeof props.children === 'string' && props.children.length > 0)
    return `${props.children}-button`.toLowerCase();
  if (
    Array.isArray(props.children) &&
    props.children.length > 0 &&
    typeof props.children[0] === 'string' &&
    props.children[0].length > 0
  ) {
    return `${props.children[0]}-button`.toLowerCase();
  }
  return undefined;
}

function KHButton<E extends `${string}ButtonClick`>(props: Props<E>): JSX.Element {
  const {
    children,
    compact = false,
    disabled = false,
    icon,
    iconSource,
    iconColor,
    labelStyle,
    loading = false,
    logger,
    loggerEventName,
    logCampaignEvent,
    severity = 'default',
    primary = false,
    tooltip,
    onPress,
    style,
    textOnly,
    ...rest
  } = props;

  const theme = useContext(KHThemeContext);

  const textOnlyAndNonPrimary = primary ? false : textOnly;

  const highlightOpacity = useRef(new Animated.Value(0)).current;
  const uninteractable = disabled || loading;

  const tooltipRef = useTooltipRef<View>(tooltip);

  const onPressIn = () => {
    highlightOpacity?.setValue(1);
  };

  const onPressOut = () => {
    if (uninteractable) {
      highlightOpacity?.setValue(0);
      return;
    }

    Animated.timing(highlightOpacity, {
      toValue: 0,
      duration: 200,
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  };

  const { highlight, color, ...buttonStyle } = getButtonStyle({
    primary,
    textOnly: textOnlyAndNonPrimary,
    uninteractable,
    severity,
    theme,
  });

  return (
    <Pressable
      ref={tooltipRef}
      disabled={uninteractable}
      style={(state) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        highlightOpacity?.setValue(Platform.OS === 'web' && (state as any).hovered ? 1 : 0); // The ? was added as highlightOpacity can be null in the test environment

        return [
          styles.button,
          !(children == null && textOnlyAndNonPrimary) && styles.buttonPadding,
          compact && styles.compact,
          buttonStyle,
          style,
        ] as ViewStyle[];
      }}
      onPress={(e) => {
        if (loggerEventName != null) {
          logger?.info({ eventName: loggerEventName });
          logCampaignEvent?.({ eventName: loggerEventName });
        }
        onPress?.(e);
      }}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      testID={constructTestIDForButton(props)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {!textOnlyAndNonPrimary && (
        <Animated.View
          style={{
            ...StyleSheet.absoluteFillObject,
            backgroundColor: highlight,
            opacity: highlightOpacity,
            borderRadius:
              StyleSheet.flatten(style)?.borderRadius ??
              BORDER_RADIUS - (buttonStyle.borderWidth ?? 0),
          }}
        />
      )}
      {icon && !loading && (
        <KHIcon
          source={icon}
          iconSource={iconSource}
          size={children == null ? 24 : 16}
          color={iconColor ?? color}
          style={children != null && styles.iconLeft}
        />
      )}
      {loading && (
        <ActivityIndicator
          size="small"
          color={color}
          style={children != null && styles.iconLeft}
          testID={`loading-${constructTestIDForButton(props) ?? ''}`}
        />
      )}
      {children != null && (
        <Text
          selectable={false}
          numberOfLines={1}
          style={[
            styles.label,
            { color },
            compact && styles.compactLabel,
            theme === 'v2' && styles.labelV2,
            labelStyle,
          ]}
        >
          {children}
        </Text>
      )}
    </Pressable>
  );
}

export default Object.assign(KHButton, { BORDER_RADIUS });
