import KHColors from 'khshared/KHColors';
import React from 'react';
import { Platform, Pressable, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import { Route, useHistory, useLocation } from 'react-router';

import filterFalseyChildren from '../utils/filterFalseyChildren';
import useGenNavigate from '../utils/useGenNavigate';
import useTooltipRef from '../utils/useTooltipRef';
import KHButton, { Props as KHButtonProps } from './KHButton';
import KHIcon from './KHIcon';
import KHSeparator from './KHSeparator';

const styles = {
  action: {
    width: 48,
  } as ViewStyle,
  textAction: {
    width: Platform.OS === 'web' ? 'default' : undefined,
  } as ViewStyle,
  item: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    paddingHorizontal: 8,
    marginTop: 4,
    paddingBottom: 4, // To counteract the offset of the top margin so that icons remain centered vertically.
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  } as ViewStyle,
  itemSelected: {
    backgroundColor: KHColors.navBarSelected,
  } as ViewStyle,
  content: {
    paddingHorizontal: 12,
  } as ViewStyle,
  section: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 12,
  } as ViewStyle,
  separator: {
    alignSelf: 'stretch',
    marginVertical: 12,
    marginHorizontal: 8,
  } as ViewStyle,
  spring: {
    flex: 1,
  } as ViewStyle,
  subtitle: {
    height: 16,
    fontSize: 12,
    marginTop: 2,
    color: KHColors.textSecondaryLight,
  } as TextStyle,
  titleBar: {
    backgroundColor: KHColors.navBarBackground,
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    overflowX: 'auto',
  } as ViewStyle,
  spacing: {
    width: 48,
  } as ViewStyle,
  title: {
    color: KHColors.textContrast,
    fontSize: 15,
    fontWeight: 'bold',
  } as ViewStyle,
};

function Action<E extends `${string}ButtonClick`>({
  style,
  ...restProps
}: Omit<KHButtonProps<E>, 'primary'>): JSX.Element {
  return (
    <KHButton<E>
      textOnly
      iconColor={KHColors.iconContrast}
      style={[styles.action, style]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
}

function TextAction<E extends `${string}ButtonClick`>({
  style,
  ...restProps
}: Omit<KHButtonProps<E>, 'primary'>): JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <KHButton<E> textOnly style={[styles.textAction, style]} {...restProps} />;
}

function RouteItem({
  icon,
  to,
  exact = false,
  tooltip,
  disabled = false,
  selected,
  style,
  testID,
}: {
  icon: string;
  to: string;
  exact?: boolean;
  tooltip?: string;
  disabled?: boolean;
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
  testID?: string;
}): JSX.Element {
  const tooltipRef = useTooltipRef<View>(tooltip);
  const genNavigate = useGenNavigate();

  return (
    <Route path={to} exact={exact}>
      {({ match }) => {
        const matchesOrIsSelected = selected != null ? selected : match != null;

        return (
          <Pressable
            ref={tooltipRef}
            disabled={disabled}
            onPress={(event) => {
              void genNavigate(to, { event });
            }}
            style={[styles.item, matchesOrIsSelected && styles.itemSelected, style]}
            testID={testID}
          >
            <KHIcon
              size={24}
              source={icon}
              color={
                matchesOrIsSelected
                  ? KHColors.navBarBackground
                  : disabled
                  ? KHColors.iconDisabledContrast
                  : KHColors.iconContrast
              }
            />
          </Pressable>
        );
      }}
    </Route>
  );
}

function BackAction<E extends `${string}ButtonClick`>(
  props: Omit<KHButtonProps<E>, 'primary'>,
): JSX.Element {
  const history = useHistory();
  const browserLocation = useLocation<{ from: { [key: string]: string } }>();
  return (
    <Action<E>
      icon={Platform.OS === 'ios' ? 'chevron-left' : 'arrow-left'}
      onPress={() =>
        // If a router state was passed, we preserve it (goBack does not preserve the state unfortunately)
        browserLocation.state != null && browserLocation.state.from?.pathname != null
          ? history.push(browserLocation.state.from.pathname, browserLocation.state)
          : history.goBack()
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

function Content({
  subtitle,
  contentStyle,
  title,
  titleStyle,
  ...restProps
}: Omit<React.ComponentPropsWithoutRef<typeof View>, 'children'> & {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  contentStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<ViewStyle>;
}): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <View {...restProps} style={[styles.content, contentStyle]}>
      {React.isValidElement(title) ? (
        title
      ) : (
        <>
          <Text style={[styles.title, titleStyle]} numberOfLines={1}>
            {title}
          </Text>
          {subtitle ? (
            <Text style={styles.subtitle} numberOfLines={1}>
              {subtitle}
            </Text>
          ) : null}
        </>
      )}
    </View>
  );
}

function Section({ children }: { children: React.ReactNode }) {
  return <View style={styles.section}>{children}</View>;
}

function Separator<E extends `${string}ButtonClick`>({
  style,
  ...restProps
}: KHButtonProps<E>): JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <KHSeparator vertical style={[styles.separator, style]} {...restProps} />;
}

function Spring(): JSX.Element {
  return <View style={styles.spring} />;
}

type Props = Partial<React.ComponentPropsWithRef<typeof View>> & {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

function KHTitleBar({ children, style, ...restProps }: Props): JSX.Element {
  let shouldCenterContent = false;
  let shouldAddLeftSpacing = false;
  let shouldAddRightSpacing = false;
  if (Platform.OS === 'ios') {
    let hasTitleBarContent = false;
    let leftItemsCount = 0;
    let rightItemsCount = 0;

    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === Content) {
          hasTitleBarContent = true;
        } else if (hasTitleBarContent) {
          rightItemsCount++;
        } else {
          leftItemsCount++;
        }
      }
    });

    shouldCenterContent = hasTitleBarContent && leftItemsCount < 2 && rightItemsCount < 2;
    shouldAddLeftSpacing = shouldCenterContent && leftItemsCount === 0;
    shouldAddRightSpacing = shouldCenterContent && rightItemsCount === 0;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <View style={[styles.titleBar, style]} {...restProps}>
      {shouldAddLeftSpacing ? <View style={styles.spacing} /> : null}
      {filterFalseyChildren(children).map((child, i) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Type 'string' is not assignable to type
        if (!React.isValidElement(child) || ![Action, BackAction, Content].includes(child.type)) {
          return child;
        }

        const props: { style?: StyleProp<ViewStyle> } = {};
        if (child.type === Content) {
          props.style = [
            // Since content is not first item, add extra left margin
            i !== 0 && { marginLeft: 8 },
            shouldCenterContent && { alignItems: 'center' },
          ];
        }
        return React.cloneElement(child, props);
      })}
      {shouldAddRightSpacing ? <View style={styles.spacing} /> : null}
    </View>
  );
}

export default Object.assign(KHTitleBar, {
  Action,
  BackAction,
  Content,
  RouteItem,
  TextAction,
  Section,
  Separator,
  Spring,
});
