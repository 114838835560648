import { TypedDocumentNode, gql, useLazyQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import { Storage } from 'aws-amplify';
import { S3ObjectInput, S3ObjectParentType } from 'khshared/API';
import { hasSchedulerGroup, hasTechGroup } from 'khshared/cognitoUtils';
import getErrorLoggingFields from 'khshared/getErrorLoggingFields';
import {
  KHPhotoCardUploadGetPUTS3ObjectPresignedURLQuery,
  KHPhotoCardUploadGetPUTS3ObjectPresignedURLQueryVariables,
  KHPhotoCard_s3Object,
} from 'khshared/graphql/types';
import KHColors from 'khshared/KHColors';
import uploadFileToURL from 'khshared/utils/uploadFileToURL';
import nullthrows from 'nullthrows';
import { PDFDocument } from 'pdf-lib';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Image,
  ImageResizeMode,
  ImageStyle,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import awsExports from '../aws-exports';
import genChildDocumentURL from '../utils/genChildDocumentURL';
import KHAuth from '../utils/KHAuth';
import { useAsyncAction } from '../utils/useAsyncAction';
import useIsMountedRef from '../utils/useIsMountedRef';
import { useLoggerRef } from '../utils/useLogger';
import genChooseFileXAPI from '../xapis/genChooseFileXAPI';
import { FileChooserFileType, FileChooserSource } from '../xapis/genChooseFileXAPITypes';
import genResizedImageURLOrNull from '../xapis/genResizedImageURLOrNullXAPI';
import openFileURL from '../xapis/openFileXAPI';
import KHButton from './KHButton';
import KHErrorText from './KHErrorText';
import KHIcon from './KHIcon';
import KHProgressBar from './KHProgressBar';
import KHText from './KHText';

const styles = {
  border: {
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: KHColors.chipBorder,
    borderRadius: KHButton.BORDER_RADIUS,
    overflow: 'hidden',
  } as ViewStyle,
  uploadZone: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  } as ViewStyle,
  uploadZoneSizingOuter: {
    width: '100%',
  } as ViewStyle,
  /*
    aspect ratio  | padding-bottom value
    --------------|----------------------
        16:9      |       56.25%
        1:1       |       100%
        4:3       |       75%
        3:2       |       66.66%
        8:5       |       62.5%
        3:4       |       133%
  */
  uploadZoneThreeByFour: {
    paddingTop: '133%',
  } as ViewStyle,
  uploadZoneFourByThree: {
    paddingTop: '75%',
  } as ViewStyle,
  uploadZoneSquare: {
    paddingTop: '100%',
  } as ViewStyle,
  uploadZoneSizingInner: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  } as ViewStyle,
  image: {
    width: '90%',
    height: '90%',
  } as ImageStyle,
  icon: {
    marginHorizontal: 56,
    marginVertical: 32,
  } as TextStyle,
  uploadSuccessIcon: {
    marginRight: 4,
  } as TextStyle,
  caption: {
    marginTop: 8,
    marginHorizontal: 0,
    textAlign: 'center',
  } as TextStyle,
  error: {
    marginTop: 8,
    marginHorizontal: 0,
    textAlign: 'center',
  } as TextStyle,
  iframe: {
    borderWidth: 0,
    flex: 1,
  },
  buttons: {
    marginTop: 9,
    flex: 1,
    flexWrap: 'wrap',
  } as ViewStyle,
  buttonsRow: {
    flexDirection: 'row',
  } as ViewStyle,
  button: {
    flex: 1,
    marginRight: 12,
    marginBottom: 12,
  } as ViewStyle,
  progressBar: {
    marginTop: 8,
    width: '100%',
    maxWidth: 200,
    height: 6,
  } as ViewStyle,
  centerView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${KHColors.backgroundContrast}cc`,
  } as ViewStyle,
};

enum FileType {
  IMAGE = 'image',
  PDF = 'pdf',
  UNSUPPORTED = 'unsupported',
}

// These are arbitrary values for resizing images.
const DEFAULT_IMAGE_RESIZE = { width: 1200, height: 1200, quality: 50 };

function toFileType(contentType: string | null) {
  switch (contentType) {
    case null:
      return null;
    case 'application/pdf':
      return FileType.PDF;
    case 'image/tiff':
      return FileType.UNSUPPORTED;
    default:
      return FileType.IMAGE;
  }
}

type Props = {
  caption?: ReactNode;
  disabled?: boolean;
  border?: boolean;
  displayMode?: 'mobile' | 'desktop';
  onUploadPhotoStart?: () => void;
  onUploadPhotoComplete: (s3Object: S3ObjectInput, s3ObjectID?: string | null) => void;
  aspectRatio?: 'portrait' | 'square' | 'landscape';
  imageResizeMode?: ImageResizeMode;
  privacyLevel?: 'private' | 'public';
  imageStyle?: StyleProp<ImageStyle>;
  style?: StyleProp<ViewStyle>;
  pdfPageLimit?: {
    quantity: number;
    limitExceededMessage: string;
  };
  parentID: string;
  parentType: S3ObjectParentType;
  // following 2 props should be removed if we refactor the UploadLabResultsField
  // component since it is unfortunate that these are on a core KH component
  // because we show automatically fetched document from change healthcare which
  // we store in encounter documents but if we manually reupload a lab results
  // we store it in the post-review checklist which belongs to the appointment
  parentIDOverrideForWriting?: string;
  parentTypeOverrideForWriting?: S3ObjectParentType;
  testID?: string;
  onDeletePhoto?: () => void;
  compressPhotoUploads?: 'compress' | 'no-compress';
  s3ObjectID?: string | null;
} & (
  | {
      // For new files, specify just the S3 key to use when uploading.
      defaultS3Key: string;
      s3Object?: null | undefined;
    }
  | {
      // For existing files, pass the existing S3 object and the key will be reused.
      defaultS3Key?: string | null | undefined; // Ignored to preserve S3 object history.
      s3Object: KHPhotoCard_s3Object | S3ObjectInput;
    }
);

//  fetch is unreliable at accessing the local file store on android devices so we use a raw XHR request
const genBlobByUrl = (url: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => resolve(xhr.response);
    xhr.onerror = () => reject(new TypeError('Request failed'));
    xhr.responseType = 'blob';

    xhr.open('GET', url, true);
    xhr.send(null);
  });
};

const GENERATE_PUT_PRESIGNED_URL: TypedDocumentNode<
  KHPhotoCardUploadGetPUTS3ObjectPresignedURLQuery,
  KHPhotoCardUploadGetPUTS3ObjectPresignedURLQueryVariables
> = gql`
  query KHPhotoCardUploadGetPUTS3ObjectPresignedURL(
    $accessLevel: String!
    $key: String!
    $identityID: String!
    $contentType: String!
    $parentID: String!
    $parentType: S3ObjectParentType!
  ) {
    getPUTS3ObjectPresignedURL(
      accessLevel: $accessLevel
      key: $key
      identityID: $identityID
      contentType: $contentType
      parentID: $parentID
      parentType: $parentType
    ) {
      url
      s3ObjectID
    }
  }
`;

export default function KHPhotoCard({
  defaultS3Key,
  s3Object,
  s3ObjectID,
  caption,
  disabled = false,
  border = true,
  displayMode = 'mobile',
  onUploadPhotoStart,
  onUploadPhotoComplete,
  aspectRatio = 'portrait',
  imageResizeMode = 'contain',
  privacyLevel = 'private',
  imageStyle,
  style,
  onDeletePhoto,
  parentID,
  parentType,
  parentIDOverrideForWriting,
  parentTypeOverrideForWriting,
  testID,
  pdfPageLimit,
  compressPhotoUploads = 'no-compress',
}: Props): JSX.Element {
  const isMountedRef = useIsMountedRef();
  const [photoSourceLocalURL, setPhotoSourceLocalURL] = useState<string | null>(null);
  const [photoSourceRemoteURL, setPhotoSourceRemoteURL] = useState<string | null>(null);
  const [lastUploadS3Key, setLastUploadS3Key] = useState<string | null>(null);
  const [filePathToUpload, setFilePathToUpload] = useState<string | null>(null);
  const [fileType, setFileType] = useState<FileType | null>(null);
  const [progress, setProgress] = useState<number | null>(null);
  const [customError, setCustomError] = useState<string | null>(null);
  const groups = KHAuth.getGroupsOrNull();

  const isTech = hasTechGroup(groups ?? []);
  const isScheduler = hasSchedulerGroup(groups ?? []);
  const isTechNotScheduler = isTech && !isScheduler;

  // Techs can see the photo card buttons on start so they can upload from the camera or the gallery right away
  const [showButtons, setShowButtons] = useState<boolean>(isTechNotScheduler);

  const photoSourceURL = photoSourceRemoteURL ?? photoSourceLocalURL;

  const loggerRef = useLoggerRef({
    photoSourceLocalURL,
    photoSourceRemoteURL,
    filePathToUploadDigest: filePathToUpload?.substring(0, 200),
    fileType,
  });

  const [genPUTPresignedURL] = useLazyQuery(GENERATE_PUT_PRESIGNED_URL, {
    fetchPolicy: 'no-cache',
  });

  const [genChooseFile, isChoosingFile, errorChoosingFile, clearErrorChoosingFile] = useAsyncAction(
    async (fileSource: FileChooserSource) => {
      // Techs can always continute to see the buttons if the photo source is null
      // Partners and Schedulers should not
      if (isTechNotScheduler) setShowButtons(photoSourceURL === null);
      else setShowButtons(false);

      setProgress(0.01);

      let fileChoice;
      try {
        fileChoice = await genChooseFileXAPI(
          Platform.OS === 'web'
            ? [FileChooserFileType.IMAGE, FileChooserFileType.PDF]
            : [FileChooserFileType.IMAGE],
          loggerRef.current,
          fileSource,
        );
      } catch (e) {
        loggerRef.current.error({
          eventName: 'KHPhotoCardError',
          subEventName: 'chooseFile',
          ...getErrorLoggingFields(e),
        });
        captureException(e);
        throw e;
      } finally {
        if (isMountedRef.current) setProgress(null);
      }

      return fileChoice;
    },
  );

  const [
    genUploadFile,
    isUploadingFile,
    errorUploadingFile,
    clearErrorUploadingFile,
  ] = useAsyncAction(async (url: string) => {
    setFilePathToUpload(url);
    setProgress(0.01);

    const blob = await genBlobByUrl(url);

    if (toFileType(blob.type) === FileType.PDF && pdfPageLimit != null) {
      const blobArrayBuffer = await blob.arrayBuffer();
      const pdf = await PDFDocument.load(blobArrayBuffer);
      if (pdf.getPageCount() > pdfPageLimit.quantity) {
        setProgress(1);
        setCustomError(pdfPageLimit.limitExceededMessage);
        return;
      }
    }

    const resizedImageURL = await genResizedImageURLOrNull(
      url,
      DEFAULT_IMAGE_RESIZE.width,
      DEFAULT_IMAGE_RESIZE.height,
      DEFAULT_IMAGE_RESIZE.quality,
    );
    const resizedBlob = resizedImageURL != null ? await genBlobByUrl(resizedImageURL) : null;

    if (!isMountedRef.current) return;
    setPhotoSourceRemoteURL(null);
    setPhotoSourceLocalURL(resizedImageURL ?? URL.createObjectURL(blob));
    setFileType(toFileType(blob.type));

    try {
      const key = nullthrows(s3Object == null ? defaultS3Key : s3Object.key);
      onUploadPhotoStart?.();
      const blobToUpload = compressPhotoUploads === 'compress' ? resizedBlob ?? blob : blob;

      const fileIdentityID = KHAuth.getViewerIdentityIDOrThrow();
      const bucket = awsExports.aws_user_files_s3_bucket;
      let s3ObjectIDCreated = null;
      try {
        const putURLResponse = await genPUTPresignedURL({
          variables: {
            accessLevel: privacyLevel,
            identityID: fileIdentityID,
            key,
            parentID: parentIDOverrideForWriting ?? parentID,
            parentType: parentTypeOverrideForWriting ?? parentType,
            contentType: blobToUpload.type,
          },
        });

        const putURL = putURLResponse.data?.getPUTS3ObjectPresignedURL.url ?? null;
        s3ObjectIDCreated = putURLResponse.data?.getPUTS3ObjectPresignedURL.s3ObjectID ?? null;
        if (putURL == null) throw new Error('Failed to generate PUT upload URL');

        await uploadFileToURL(blobToUpload, putURL, blobToUpload.type, setProgress);

        loggerRef.current.info({
          eventName: 'KHPhotoCardUpload',
          subEventName: 'putURLFileUploadSuccess',
        });
      } catch (error) {
        await Storage.put(key, blobToUpload, {
          contentType: blobToUpload.type,
          level: privacyLevel,
          progressCallback: (p: { loaded: number; total: number }) => {
            setProgress(p.loaded / p.total);
          },
        });

        loggerRef.current.error({
          eventName: 'KHPhotoCardUpload',
          subEventName: 'putURLFileUploadFail',
          ...getErrorLoggingFields(error),
        });
        captureException(error);
      }

      if (!isMountedRef.current) return;
      setLastUploadS3Key(key);
      setShowButtons(false);

      // If we don't wait for the next event loop, the parent could update this component's props
      // before this call of the async action has completed, triggering the useEffect below and
      // causing the second async action call to throw a ReentrancyError.
      setTimeout(() => {
        onUploadPhotoComplete(
          {
            key,
            identityID: fileIdentityID,
            bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
          },
          s3ObjectIDCreated,
        );
      }, 0);
    } catch (e) {
      captureException(e);
      loggerRef.current.error({
        eventName: 'KHPhotoCardError',
        subEventName: 'uploadFile',
        ...getErrorLoggingFields(e),
      });
      throw e;
    }
  });

  const s3ObjectKey = s3Object?.key;
  const s3ObjectIdentityID = s3Object?.identityID ?? KHAuth.getViewerIdentityIDOrThrow();
  const [
    genFetchRemoteURL,
    isFetchingRemoteURL,
    errorFetchingRemoteURL,
    clearErrorFetchingRemoteURL,
  ] = useAsyncAction(
    useCallback(async () => {
      clearErrorChoosingFile();
      clearErrorUploadingFile();

      // Compare against the last upload key to prevent refetching files that were just uploaded.
      // TODO (iron_bucket) - update logic here to use just s3ObjectID
      if (s3ObjectKey == null || s3ObjectKey === lastUploadS3Key) return;
      setProgress(0.01);

      let remoteURL;

      if (s3ObjectID != null) {
        try {
          remoteURL = await genChildDocumentURL({
            s3ObjectID,
            parentID,
            parentType,
            logger: loggerRef.current,
          });
          loggerRef.current.info({
            eventName: 'KHPhotoCardUpload',
            subEventName: 'useChildDocumentURLResolverSuccess',
          });
        } catch (e) {
          loggerRef.current.error({
            eventName: 'KHPhotoCardUpload',
            subEventName: 'useChildDocumentURLResolverFail',
            ...getErrorLoggingFields(e),
          });
          const source = await Storage.get(s3ObjectKey, {
            level: privacyLevel,
            identityId: s3ObjectIdentityID,
          });
          remoteURL = typeof source === 'string' ? source : null;
        }
      } else {
        const source = await Storage.get(s3ObjectKey, {
          level: privacyLevel,
          identityId: s3ObjectIdentityID,
        });
        remoteURL = typeof source === 'string' ? source : null;
        loggerRef.current.error({
          eventName: 'KHPhotoCardUpload',
          subEventName: 'useStorageGet',
        });
      }
      const response = remoteURL ? await fetch(remoteURL) : null;

      if (!isMountedRef.current) return;
      const contentType = response?.headers.get('content-type');
      if (contentType == null) throw new Error('Network Error');
      setFileType(toFileType(contentType));

      if (!isMountedRef.current) return;
      setProgress(0.5);

      const resizedRemoteURL = remoteURL
        ? await genResizedImageURLOrNull(
            remoteURL,
            DEFAULT_IMAGE_RESIZE.width,
            DEFAULT_IMAGE_RESIZE.height,
            DEFAULT_IMAGE_RESIZE.quality,
          )
        : null;

      if (!isMountedRef.current) return;
      setPhotoSourceRemoteURL(resizedRemoteURL ?? remoteURL);
      setProgress(1);
    }, [
      clearErrorChoosingFile,
      clearErrorUploadingFile,
      s3ObjectKey,
      lastUploadS3Key,
      s3ObjectID,
      isMountedRef,
      parentID,
      parentType,
      privacyLevel,
      s3ObjectIdentityID,
      loggerRef,
    ]),
  );

  useEffect(() => {
    void genFetchRemoteURL();
  }, [genFetchRemoteURL]); // Because of its useCallback's deps, this will trigger anytime we receive a new S3 object or privacyLevel.

  useEffect(() => {
    if (s3Object == null && isMountedRef.current) {
      setPhotoSourceLocalURL(null);
      setPhotoSourceRemoteURL(null);
    }
  }, [s3Object, isMountedRef]);

  async function genChooseAndUploadFile(fileSource: FileChooserSource) {
    clearErrorChoosingFile();
    clearErrorUploadingFile();
    clearErrorFetchingRemoteURL();

    const fileChoice = await genChooseFile(fileSource);
    if (fileChoice == null) return;

    await genUploadFile(fileChoice.uri);
  }

  const shouldDisable = isChoosingFile || isUploadingFile || isFetchingRemoteURL || disabled;
  const hasRetriableError = errorUploadingFile != null || errorFetchingRemoteURL != null;
  const hasProgress = progress != null && progress !== 1;

  return (
    <View style={style} testID={testID}>
      <View style={border && styles.border}>
        <View
          style={[
            styles.uploadZoneSizingOuter,
            aspectRatio === 'landscape'
              ? styles.uploadZoneFourByThree
              : aspectRatio === 'square'
              ? styles.uploadZoneSquare
              : styles.uploadZoneThreeByFour,
          ]}
        >
          <View style={styles.uploadZoneSizingInner}>
            {photoSourceURL != null && displayMode === 'desktop' ? (
              <>
                {fileType === FileType.PDF ? (
                  <iframe style={styles.iframe} src={photoSourceURL} title="file upload" />
                ) : fileType === FileType.IMAGE ? (
                  <View style={styles.uploadZone}>
                    <Image
                      style={[styles.image, imageStyle]}
                      resizeMode={imageResizeMode}
                      source={{ uri: photoSourceURL }}
                    />
                  </View>
                ) : null}
              </>
            ) : (
              <Pressable
                onPress={async () => {
                  setCustomError(null);
                  if (photoSourceURL === null) {
                    await genChooseAndUploadFile(FileChooserSource.ALL);
                  } else {
                    setShowButtons(!showButtons);
                  }
                }}
                disabled={shouldDisable}
                style={styles.uploadZone}
                testID="upload-file"
              >
                {photoSourceURL === null ? (
                  !hasRetriableError &&
                  !hasProgress && (
                    <KHIcon
                      source="image-plus"
                      style={styles.icon}
                      size={32}
                      color={KHColors.iconSecondary}
                    />
                  )
                ) : fileType === FileType.PDF ? (
                  <KHIcon
                    source="file-pdf-box"
                    style={styles.icon}
                    size={64}
                    color={KHColors.iconSecondary}
                  />
                ) : (
                  <Image
                    style={[styles.image, imageStyle]}
                    resizeMode={imageResizeMode}
                    source={{ uri: photoSourceURL }}
                  />
                )}
              </Pressable>
            )}
          </View>
          {hasRetriableError ? (
            <View style={styles.centerView}>
              <KHButton
                primary
                icon="refresh"
                onPress={async () => {
                  if (errorUploadingFile != null) await genUploadFile(nullthrows(filePathToUpload));
                  else if (errorFetchingRemoteURL != null) await genFetchRemoteURL();
                }}
              />
            </View>
          ) : (
            hasProgress && (
              <View style={styles.centerView}>
                <KHIcon
                  source={
                    isChoosingFile
                      ? 'folder-open-outline'
                      : isUploadingFile
                      ? 'cloud-upload-outline'
                      : 'cloud-download-outline'
                  }
                  size={32}
                  color={KHColors.iconPrimary}
                />
                {progress != null && (
                  <KHProgressBar progress={progress} style={styles.progressBar} />
                )}
              </View>
            )
          )}
        </View>
        {(showButtons || displayMode === 'desktop') && (
          <View style={[styles.buttons, displayMode === 'desktop' && styles.buttonsRow]}>
            {Platform.OS === 'android' || Platform.OS === 'ios' ? (
              <>
                <KHButton
                  style={styles.button}
                  textOnly
                  disabled={shouldDisable}
                  onPress={async () => {
                    await genChooseAndUploadFile(FileChooserSource.CAMERA);
                  }}
                >
                  Open camera
                </KHButton>
                <KHButton
                  style={styles.button}
                  textOnly
                  disabled={shouldDisable}
                  onPress={async () => {
                    await genChooseAndUploadFile(FileChooserSource.GALLERY);
                  }}
                >
                  Open photo gallery
                </KHButton>
              </>
            ) : (
              <KHButton
                style={styles.button}
                textOnly
                disabled={shouldDisable}
                onPress={async () => {
                  setCustomError(null);
                  await genChooseAndUploadFile(FileChooserSource.ALL);
                }}
              >
                {photoSourceURL == null ? 'Upload' : 'Reupload'} file
              </KHButton>
            )}
            {photoSourceURL != null && (
              <KHButton
                style={styles.button}
                textOnly
                disabled={shouldDisable}
                onPress={async () => {
                  // TODO (iron_bucket) - update logic here to use just s3ObjectID
                  let remoteURLToOpen = photoSourceURL;
                  if (s3ObjectID != null) {
                    remoteURLToOpen = await genChildDocumentURL({
                      s3ObjectID,
                      parentID,
                      parentType,
                      logger: loggerRef.current,
                    });
                  }
                  await openFileURL(remoteURLToOpen, loggerRef.current);
                }}
              >
                {isTechNotScheduler ? 'View photo' : 'Open in new tab'}
              </KHButton>
            )}
            {onDeletePhoto != null && s3Object != null && (
              <KHButton
                style={styles.button}
                textOnly
                disabled={shouldDisable}
                onPress={() => {
                  // Hide buttons if not a Tech
                  if (!isTechNotScheduler) setShowButtons(false);
                  onDeletePhoto();
                }}
              >
                Delete
              </KHButton>
            )}
          </View>
        )}
      </View>
      {Boolean(caption) && <KHText.Caption style={styles.caption}>{caption}</KHText.Caption>}
      <KHErrorText
        style={styles.error}
        error={errorChoosingFile ?? errorUploadingFile ?? errorFetchingRemoteURL ?? customError}
      />
    </View>
  );
}

KHPhotoCard.fragments = {
  s3Object: gql`
    fragment KHPhotoCard_s3Object on S3Object {
      identityID
      key
      bucket
    }
  ` as TypedDocumentNode<KHPhotoCard_s3Object, Record<string, never>>,
};
