import KHColors from 'khshared/KHColors';
import * as React from 'react';
import { useContext } from 'react';
import { Platform, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import { useHistory } from 'react-router';

import filterFalseyChildren from '../utils/filterFalseyChildren';
import KHButton, { Props as KHButtonProps } from './KHButton';
import KHThemeContext from './KHThemeContext';

const styles = {
  action: {
    width: 40,
  } as ViewStyle,
  textAction: {
    width: Platform.OS === 'web' ? 'default' : undefined,
  } as ViewStyle,
  content: {
    flex: 1,
    paddingHorizontal: 12,
  },
  title: {
    fontSize: Platform.OS === 'ios' ? 17 : 20,
    fontWeight: Platform.OS === 'ios' ? '400' : '500',
  } as TextStyle,
  subtitle: {
    fontSize: Platform.OS === 'ios' ? 11 : 14,
  },
  appbar: {
    backgroundColor: KHColors.appBar,
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 4,
  } as ViewStyle,
  appbarV2: {
    backgroundColor: 'transparent',
  } as ViewStyle,
  spacing: {
    width: 48,
  } as ViewStyle,
};

function Action<E extends `${string}ButtonClick`>({
  style,
  ...restProps
}: Omit<KHButtonProps<E>, 'primary'>): JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <KHButton<E> textOnly style={[styles.action, style]} {...restProps} />;
}

function TextAction<E extends `${string}ButtonClick`>({
  style,
  ...restProps
}: Omit<KHButtonProps<E>, 'primary'>): JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <KHButton<E> textOnly style={[styles.textAction, style]} {...restProps} />;
}

function BackAction<E extends `${string}ButtonClick`>(props: KHButtonProps<E>): JSX.Element {
  const history = useHistory();
  return (
    <Action<E>
      icon={Platform.OS === 'ios' ? 'chevron-left' : 'arrow-left'}
      onPress={() => history.goBack()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

function Content({
  subtitle,
  contentStyle,
  title,
  titleStyle,
  ...restProps
}: Omit<React.ComponentPropsWithoutRef<typeof View>, 'children'> & {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  contentStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<ViewStyle>;
}): JSX.Element {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <View {...restProps} style={[styles.content, contentStyle]}>
      {React.isValidElement(title) ? (
        title
      ) : (
        <>
          <Text style={titleStyle} numberOfLines={1}>
            {title}
          </Text>
          {subtitle ? (
            <Text style={styles.subtitle} numberOfLines={1}>
              {subtitle}
            </Text>
          ) : null}
        </>
      )}
    </View>
  );
}

type Props = Partial<React.ComponentPropsWithRef<typeof View>> & {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

export default function KHAppBar({ children, style, ...restProps }: Props): JSX.Element {
  const theme = useContext(KHThemeContext);
  let shouldCenterContent = false;
  let shouldAddLeftSpacing = false;
  let shouldAddRightSpacing = false;
  if (Platform.OS === 'ios') {
    let hasAppBarContent = false;
    let leftItemsCount = 0;
    let rightItemsCount = 0;

    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === Content) {
          hasAppBarContent = true;
        } else if (hasAppBarContent) {
          rightItemsCount++;
        } else {
          leftItemsCount++;
        }
      }
    });

    shouldCenterContent = hasAppBarContent && leftItemsCount < 2 && rightItemsCount < 2;
    shouldAddLeftSpacing = shouldCenterContent && leftItemsCount === 0;
    shouldAddRightSpacing = shouldCenterContent && rightItemsCount === 0;
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <View style={[styles.appbar, theme === 'v2' && styles.appbarV2, style]} {...restProps}>
      {shouldAddLeftSpacing ? <View style={styles.spacing} /> : null}
      {filterFalseyChildren(children).map((child, i) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Type 'string' is not assignable to type
        if (!React.isValidElement(child) || ![Action, BackAction, Content].includes(child.type)) {
          return child;
        }

        const props: { style?: StyleProp<ViewStyle> } = {};
        if (child.type === Content) {
          props.style = [
            // Since content is not first item, add extra left margin
            i !== 0 && { marginLeft: 8 },
            shouldCenterContent && { alignItems: 'center' },
          ];
        }
        return React.cloneElement(child, props);
      })}
      {shouldAddRightSpacing ? <View style={styles.spacing} /> : null}
    </View>
  );
}

KHAppBar.Action = Action;
KHAppBar.BackAction = BackAction;
KHAppBar.Content = Content;
KHAppBar.TextAction = TextAction;
