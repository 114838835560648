import { GestureResponderEvent, Linking } from 'react-native';
import { useHistory } from 'react-router';

interface NavigateOptions<TState> {
  state?: TState | undefined;
  replace?: boolean | undefined; // Only relevant for relative paths within the app.
  event?: GestureResponderEvent; // Added to support Command-click opening in a new tab.
  openInNewTab?: boolean; // Overrides Command-click behavior.
}

// Modeled after React Router v6's useNavigate function.
interface NavigateFn<TState> {
  (to: string, options?: NavigateOptions<TState>): Promise<void>;
  (to: number, options?: undefined): Promise<void>;
}

/**
 * Returns a function that can be used to navigate to other pages, sites, or apps, while also
 * allowing Command+clicking to open in a new tab on desktop when passed an `event` option.
 *
 * @returns The `genNavigate` function
 */
export default function useGenNavigate<TState>(): NavigateFn<TState> {
  const history = useHistory();

  function genNavigate(to: string, options?: Partial<NavigateOptions<TState>>): Promise<void>;
  function genNavigate(to: number, options?: undefined): Promise<void>;
  async function genNavigate(
    to: string | number,
    options?: Partial<NavigateOptions<TState>> | undefined,
  ) {
    if (typeof to === 'number') {
      history.go(to);
      return;
    }

    if (
      options?.openInNewTab != null
        ? options.openInNewTab
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
          window.open != null && (options?.event as any)?.metaKey
    ) {
      window.open(to);
      return;
    }

    // This method is adapted from https://stackoverflow.com/questions/10687099
    let isRelativeURL = false;
    try {
      // eslint-disable-next-line no-new
      new URL(to); // Throws for malformed and non-absolute URLs.
    } catch {
      // eslint-disable-next-line no-new
      new URL(to, 'http://test.com'); // Throws for truly malformed URLs.
      isRelativeURL = true;
    }

    if (isRelativeURL) {
      if (options?.replace) history.replace(to, options?.state);
      else history.push(to, options?.state);
      return;
    }

    await Linking.openURL(to);
  }

  return genNavigate;
}
