import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

// TODO(dhruv) Pull out kinesis permissions into its own unauth identity pool + iam roles
// https://sprinter.clickup.com/t/866a7xt8h
// This is the ID for the wwwLocationAccess identity pool
// https://us-west-2.console.aws.amazon.com/cognito/federated?region=us-west-2
const COGNITO_FEDERATED_WWWLOCATIONACCESS_IDENTITY_POOL_ID =
  'us-west-2:9c95ed99-745a-4cf1-89be-e6d83f7d1950';

const credentials = fromCognitoIdentityPool({
  identityPoolId: COGNITO_FEDERATED_WWWLOCATIONACCESS_IDENTITY_POOL_ID,
  clientConfig: { region: 'us-west-2' },
});

export default credentials;
