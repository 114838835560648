import { S3ObjectParentType } from 'khshared/API';
import { Logger } from 'pino';

import { getApolloClientOrThrowDONOTUSE } from '../ApolloClient';
import getChildDocumentQueryByParentType from './getChildDocumentQueryByParentType';

export default async function genChildDocumentURL({
  s3ObjectID,
  parentID,
  parentType,
  logger,
  shouldFlatten = false,
}: {
  parentID: string;
  parentType: S3ObjectParentType;
  s3ObjectID: string;
  logger: Logger;
  shouldFlatten?: boolean;
}): Promise<string> {
  const query = getChildDocumentQueryByParentType(parentType);

  const result = await getApolloClientOrThrowDONOTUSE().query({
    query,
    variables: { parentID, s3ObjectID, shouldFlatten },
    fetchPolicy: 'no-cache',
  });

  const data = result.data as Record<string, { childDocumentURL: { url: string } }>;
  const url = data?.[Object.keys(data)?.[0]]?.childDocumentURL?.url;
  if (url == null) {
    logger?.error({
      eventName: 'childDocumentURLGenerationFailed',
      parentID,
      parentType,
      s3ObjectID,
    });
    throw new Error('Failed to fetch file');
  }

  return url;
}
