import { PartnerOrganization } from '../API';
import assertExhaustiveSwitch from '../assertExhaustiveSwitch';
import getAWSTimestampNow from '../getAWSTimestampNow';
import { parseBloodPressureOrThrow } from '../parseBloodPressure';
import { UrgencyThreshold, Vital, VitalRange, VitalReading, VitalThreshold } from '../types/Vitals';

export function parseVitalValues(value: string): VitalReading {
  // need try/catch block since JSON throws an error if we try to parse 120/80 str(old way to store BP)
  try {
    const parsedValue = JSON.parse(value) as VitalReading;
    if (typeof parsedValue === 'number') return { reading: parsedValue };
    return parsedValue;
  } catch (error) {
    const bloodPressure = parseBloodPressureOrThrow(value);
    return {
      reading: parseFloat(bloodPressure.diastolic),
      subreading: parseFloat(bloodPressure.systolic),
    };
  }
}

export function constructVitalString({
  reading,
  vitalType,
  subreading,
}: {
  reading: number;
  vitalType: Vital;
  subreading?: number;
}): string {
  if (vitalType !== Vital.SYSTOLIC_BLOOD_PRESSURE) {
    return reading.toString();
  }
  if (subreading != null) return `${subreading}/${reading}`;
  return '';
}

export const ordinal = (idx: number): string => {
  return idx === 0 ? '1st read' : idx === 1 ? '2nd read' : '3rd read';
};

export function adjustVitalsBeforeSaving(
  vitalType: Vital,
  value: string,
  readingType: 'reading' | 'subreading',
  previousValue: VitalReading | null,
): VitalReading {
  switch (vitalType) {
    case Vital.RESPIRATORY_RATE:
      return { reading: value ? parseFloat(value) * 2 : null, updatedAt: getAWSTimestampNow() };
    case Vital.SYSTOLIC_BLOOD_PRESSURE:
      return {
        reading:
          readingType === 'reading'
            ? value // if value is empty string set to null
              ? parseFloat(value)
              : null
            : previousValue?.reading != null
            ? previousValue.reading
            : null,
        ...(readingType === 'subreading'
          ? { subreading: value ? parseFloat(value) : undefined } // if value is empty string set subreading to undefined
          : previousValue?.subreading != null
          ? { subreading: previousValue.subreading }
          : {}),
        updatedAt: getAWSTimestampNow(),
      };
    default:
      return { reading: value ? parseFloat(value) : null, updatedAt: getAWSTimestampNow() };
  }
}

const DEFAULT_VITALS_URGENCY_THRESHOLD: VitalThreshold = {
  systolicBloodPressureThresholds: {
    normal: {
      min: 90,
      max: 139,
    },
    abnormal: {
      min: 140,
      max: 179,
    },
    abnormalEscalated: {
      min: 80,
      max: 89,
    },
    invalid: {
      min: 60,
      max: 300,
    },
  },
  diastolicBloodPressureThresholds: {
    normal: {
      min: 60,
      max: 89,
    },
    abnormal: {
      min: 90,
      max: 119,
    },
    invalid: {
      min: 40,
      max: 150,
    },
  },
  heartRateThresholds: {
    normal: {
      min: 60,
      max: 100,
    },
    abnormal: {
      min: 50,
      max: 109,
    },
    abnormalEscalated: {
      min: 110,
      max: 119,
    },
    invalid: {
      min: 20,
      max: 250,
    },
  },
  temperatureThresholds: {
    normal: {
      min: 96.8,
      max: 99.9,
    },
    abnormal: {
      min: 96,
      max: 102.9,
    },
    abnormalEscalated: {
      // Prevents readings from being critical emergent
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER,
    },
    invalid: {
      min: 91.4,
      max: 107.6,
    },
  },
  oxygenSaturationThresholds: {
    normal: {
      min: 93,
      max: 100,
    },
    abnormal: {
      min: 90,
      max: 92.9,
    },
    invalid: {
      min: 70,
      max: 100,
    },
  },
  respirationRateThresholds: {
    normal: {
      min: 12,
      max: 20,
    },
    abnormal: {
      min: 9,
      max: 25,
    },
    invalid: {
      min: 4,
      max: 45,
    },
  },
};

export const VITALS_CONFIG: {
  [partnerOrganization in PartnerOrganization]?: VitalThreshold;
} = {
  DISCOVERHEALTH: DEFAULT_VITALS_URGENCY_THRESHOLD,
  OSCAR: DEFAULT_VITALS_URGENCY_THRESHOLD,
  FIREFLYHEALTH: DEFAULT_VITALS_URGENCY_THRESHOLD,
  MATTAPAN: DEFAULT_VITALS_URGENCY_THRESHOLD,
  CODMAN: DEFAULT_VITALS_URGENCY_THRESHOLD,
  BMCEVANS: DEFAULT_VITALS_URGENCY_THRESHOLD,
  BMCFAMILYMEDICINE: DEFAULT_VITALS_URGENCY_THRESHOLD,
  ROSLINDALE: DEFAULT_VITALS_URGENCY_THRESHOLD,
  WELLSENSE: DEFAULT_VITALS_URGENCY_THRESHOLD,
};

export function getVitalConfigOrThrow(
  vitalType: Vital,
  partnerOrganization: PartnerOrganization | null,
): UrgencyThreshold {
  let config;
  if (partnerOrganization != null) {
    config = VITALS_CONFIG[partnerOrganization];
  }
  config = config ?? DEFAULT_VITALS_URGENCY_THRESHOLD;
  switch (vitalType) {
    case Vital.SYSTOLIC_BLOOD_PRESSURE:
      return config.systolicBloodPressureThresholds;
    case Vital.DIASTOLIC_BLOOD_PRESSURE:
      return config.diastolicBloodPressureThresholds;
    case Vital.OXYGEN_SATURATION:
      return config.oxygenSaturationThresholds;
    case Vital.PULSE:
      return config.heartRateThresholds;
    case Vital.TEMPERATURE:
      return config.temperatureThresholds;
    case Vital.RESPIRATORY_RATE:
      return config.respirationRateThresholds;
    default:
      return assertExhaustiveSwitch(vitalType);
  }
}

export function getVitalRange(
  vital: Vital,
  reading: number,
  partnerOrganization: PartnerOrganization | null,
): VitalRange {
  const vitalConfig = getVitalConfigOrThrow(vital, partnerOrganization);
  if (reading < vitalConfig.invalid.min || reading > vitalConfig.invalid.max)
    return VitalRange.INVALID;
  if (reading >= vitalConfig.normal.min && reading <= vitalConfig.normal.max)
    return VitalRange.NORMAL;
  if (reading >= vitalConfig.abnormal.min && reading <= vitalConfig.abnormal.max)
    return VitalRange.ABNORMAL;
  if (
    vitalConfig.abnormalEscalated &&
    reading >= vitalConfig.abnormalEscalated.min &&
    reading <= vitalConfig.abnormalEscalated.max
  )
    return VitalRange.ABNORMAL_ESCALATED;
  // every value out of critical urgent range is critical emergent
  return VitalRange.CRITICAL_EMERGENT;
}

export function isVitalRangeCritical(x: VitalRange): boolean {
  return x === VitalRange.CRITICAL_EMERGENT;
}

export function isVitalRangeAbnormal(x: VitalRange): boolean {
  return [VitalRange.ABNORMAL, VitalRange.ABNORMAL_ESCALATED].includes(x);
}

export function isVitalRangeEscalated(x: VitalRange): boolean {
  return [VitalRange.CRITICAL_EMERGENT, VitalRange.ABNORMAL_ESCALATED].includes(x);
}

export function isVitalRangeInvalid(x: VitalRange): boolean {
  return x === VitalRange.INVALID;
}
