export enum FileChooserFileType {
  IMAGE = 'IMAGE',
  PDF = 'PDF',
  CSV = 'CSV',
}

export enum FileChooserSource {
  CAMERA = 'CAMERA',
  GALLERY = 'GALLERY',
  ALL = 'ALL',
}

export interface FileChooserResult {
  uri: string;
  type: string;
  name: string;
  size: number;
}
