import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

import { AddressInput, ServiceType, TimeRangeRuleInclusion, TimeRangeRulePeriod } from './API';
import { TimezoneID } from './formatters/formatTimezone';
import TECH_MATCHMAKING_CONFIG from './metadata/statsig/TECH_MATCHMAKING_CONFIG.json';
import { AgeEligibilityRequirements } from './types/AgeEligibilityRequirements';

// All phones should be in E.164 format
export const CUSTOMER_SUPPORT_PHONE = '+12096777468';
export const ENGAGEMENT_CUSTOMER_SUPPORT_PHONE = '+18004608393';
export const OXOS_THOMAS_PHONE = '+17274151281';
export const OXOS_ONCALL_NURSE_PHONE = '+14043523522';

// Clinical managers
export const ANDREA_PHONE = '+14157059754';
export const SARAH_PHONE = '+14153856407';
export const ABBY_PHONE = '+18572028176';
export const KIM_PHONE = '+13853215238';
export const SHAREECE_PHONE = '+13135255589';

// Operations staff
export const ARIANA_PHONE = '+16507143294';
export const FORREST_PHONE = '+12066615457';

export const CAMERON_PHONE = '+16157146935';
export const EMERGENCY_NUMBER = '911';
export const SPRINTER_HEALTH_WESTFAX_PHONE = '+18886754006';
export const SPRINTER_HEALTH_WESTFAX_PHONE_TEST = '+12029648470';
export const SPRINTER_HEALTH_SMS_PHONE_PROD = '+18335941488';

export const OPS_TECHNICAL_SUPPORT_PHONE = '+16509550665';

// Staff Doctors
export const DR_ARUN_VILLIVALAM_PHONE_NUMBER = '+17733988185';
export const DR_ARUN_VILLIVALAM_NPI = '1639355944';
export const DR_RICKY_PHONE_NUMBER = '+12072329669';

export const SUPPORT_EMAIL = 'support@sprinterhealth.com';
export const PARTNER_SUPPORT_EMAIL = 'partnersupport@sprinterhealth.com';
export const SUPPORT_EMAIL_SHORT = 'help@sprinterhealth.com';
export const INTERNAL_ONLY_SPRINTER_HEALTH_EMAIL = 'test@sprinterhealth.com';
export const INTERNAL_ONLY_REROUTE_FAILURE_EMAIL = 'test+failures@sprinterhealth.com';
export const CLINICAL_MANAGER_EMAIL_GROUP = 'clinical-managers@sprinterhealth.com';
export const CANCELLATION_POLICY_URL = 'https://www.sprinterhealth.com/cancellation-policy';
export const HOME_PAGE_URL = 'https://app.sprinterhealth.com';
export const TESTNET_PAGE_URL = 'https://testnet.d1j7gv4p0wvp5e.amplifyapp.com';

/**
 * The latest we allow patients to book a new appointment ahead of its scheduled start.
 */
export const MINIMUM_APPOINTMENT_NEW_BOOKING_LEAD_TIME_HOURS = 10;

/**
 * The latest we allow anyone besides Ops to edit an appointment's patients, services, or
 * requisition uploads ahead the appointment's scheduled start.
 */
export const MINIMUM_APPOINTMENT_EDIT_LEAD_TIME_HOURS = 10;

/**
 * The latest we allow techs conducting confirmation calls to edit an appointment's time, location,
 * or comments ahead of its scheduled start.
 */
export const MINIMUM_APPOINTMENT_EDIT_LEAD_TIME_HOURS_FOR_TECHS = 10;

/**
 * The latest we allow anyone besides Ops to reschedule an appointment ahead of its current
 * scheduled start.
 */

export const MINIMUM_APPOINTMENT_RESCHEDULE_LEAD_TIME_HOURS = 10;
/**
 * The latest ahead of its scheduled start we allow anyone besides Ops to book or reschedule an
 * appointment without providing all required requisition uploads. This affects partners who either:
 *
 * 1. Are the ordering provider and we've allowed to defer uploads until after booking due to
 *    workflow constraints on their side (e.g. BMC, UCI, and some former partners like Intermountain
 *    and Stanford), or
 * 2. Rely on us to order the tests post-booking using an Electronic Data Interchange (EDI) like
 *    ChangeHealthcare, which can sometimes fail, leaving appointments without requisition uploads
 *    (e.g. all health plans doing gap closure)
 */
export const MINIMUM_DEFERRED_REQUISITION_UPLOAD_LEAD_TIME_HOURS = 36;

export const MINIMUM_APPOINTMENT_CANCELLATION_ALLOWED_HOURS = 24;
export const GRACE_CANCELLATION_TIME_HOURS = 1;
export const PARTNER_LATE_CANCELLATION_FEE_USD = 49;
export const FAILED_APPOINTMENT_FEE_USD = 49;

export const MILE_IN_METERS = 1609.344;
export const MINUTE_IN_SECONDS = 60;
export const MINUTE_IN_MILLISECONDS = MINUTE_IN_SECONDS * 1000;
export const SECOND_IN_MILLISECONDS = 1000;
export const HOUR_IN_MINUTES = 60;
export const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;
export const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;
export const MONTHS_IN_YEAR = 12;
export const DAY_IN_MINUTES = 24 * HOUR_IN_MINUTES;
export const DOLLAR_IN_CENTS = 100;
export const POUND_IN_KILOGRAMS = 0.453592;
export const INCHES_IN_METRES = 0.0254;

export const DEFAULT_TIMEZONE_PT: TimezoneID = 'America/Los_Angeles';
export const DATETIME_FORMAT_ISOLIKE_WITH_TIMEZONE_AND_NO_SECONDS = 'yyyy-MM-dd HH:mm ZZZZ';

export const MAXIMUM_TIMEOUT_TIME_IN_MILLISECONDS = 24 * HOUR_IN_MILLISECONDS;

export const MIN_DATE_TIME = DateTime.fromISO('1970-01-01T00:00:00Z');
export const MAX_DATE_TIME = DateTime.fromISO('2100-01-01T00:00:00Z');

export const UNIVERSAL_INCLUSION_RULE = {
  id: uuidv4(),
  inclusion: TimeRangeRuleInclusion.INCLUDE,
  start: '00:00',
  end: '23:59:59.999',
  recurrenceStart: '2022-01-01',
  recurrenceEnd: null,
  recurrencePeriod: TimeRangeRulePeriod.DAY,
  recurrenceMultiple: 1,
};

export const sentryFrontEndDSN =
  'https://2267b9cdf8f54634ba7b492a5d93758a@o1070873.ingest.sentry.io/6067269';
export const sentryBackEndDSN =
  'https://fb7006c21d784adcae53a46f33caf880@o1070873.ingest.sentry.io/6095678';
export const sentryTransactionSampleRate = 0.1;
export const sentryLowTransactionSampleRate = 0.01;
export const sentryErrorSampleRate = 1.0;

export const DEFAULT_SHARED_SLOT_RANK = 0.5;

export const isSlotShared = (sharedSlotRank: number): boolean =>
  sharedSlotRank < DEFAULT_SHARED_SLOT_RANK || sharedSlotRank > DEFAULT_SHARED_SLOT_RANK;

// The fixed time costs of an appointment (e.g. patient introduction, identification, situating, wrapping up)
export const APPOINTMENT_FIXED_TIME_COST_SECONDS = 10 * MINUTE_IN_SECONDS;

export const PERIOD_TO_DAYS = {
  [TimeRangeRulePeriod.DAY]: 1,
  [TimeRangeRulePeriod.WEEK]: 7,
};

export const MAX_DISTANCE_BETWEEN_SHARED_SLOT_APPOINTMENTS_METERS_DEFAULT =
  TECH_MATCHMAKING_CONFIG.maxDistanceDefaultThresholds.betweenSharedSlotAppointmentsMiles *
  MILE_IN_METERS;

export const MAX_DISTANCE_BETWEEN_CONSECUTIVE_SHARED_SLOT_SLOTS_METERS_DEFAULT =
  TECH_MATCHMAKING_CONFIG.maxDistanceDefaultThresholds.betweenConsecutiveSharedSlotSlotsMiles *
  MILE_IN_METERS;

export const MAX_DISTANCE_BETWEEN_NON_SHARED_SLOT_APPOINTMENTS_METERS_DEFAULT =
  TECH_MATCHMAKING_CONFIG.maxDistanceDefaultThresholds.betweenNonSharedSlotAppointmentsMiles *
  MILE_IN_METERS;

export const MAX_DISTANCE_BETWEEN_SHARED_SLOT_APPOINTMENT_NON_SHARED_SLOT_APPOINTMENT_METERS_DEFAULT =
  TECH_MATCHMAKING_CONFIG.maxDistanceDefaultThresholds
    .betweenSharedSlotAppointmentAndNonSharedSlotAppointmentMiles * MILE_IN_METERS;

export const MIN_DISTANCE_BETWEEN_HOME_AND_APPOINTMENT_TO_BE_PARACHUTE_DAY_METERS =
  TECH_MATCHMAKING_CONFIG.maxDistanceDefaultThresholds
    .betweenHomeAndAppointmentToBeParachuteDayMiles * MILE_IN_METERS;

export const ESTIMATED_METERS_PER_HOUR_DRIVING_SPEED =
  TECH_MATCHMAKING_CONFIG.estimatedDrivingSpeedMilesPerHour * MILE_IN_METERS;

// Maximum allowed patients in an appointment for speciality labs.
export const MAX_PATIENTS_PER_APPOINTMENT_SPECIALITY_LABS = 3;

// Maximum allowed patients in an appointment for all other partners.
export const MAX_PATIENTS_PER_APPOINTMENT = 2;

// Maximum allowed patients in an appointment for D2C.
export const MAX_PATIENTS_PER_APPOINTMENT_D2C = 10;

// polling interval to auto fetch tech appointments on tech appointments screen
export const TECH_APPOINTMENTS_POLLING_INTERVAL_MS = 60000;

// Used for demo partners
export const STRIPE_INVALID_CUSTOMER_ID = 'cus_INVALID';

// Minimum amount we can charge for an appointment (0.50 USD per https://stripe.com/docs/api/charges/object#charge_object-amount)
export const MIN_APPOINTMENT_CHARGE_CENTS = 0.5 * DOLLAR_IN_CENTS;

// Maximum amount we can charge for an appointment (1,000 USD)
export const MAX_APPOINTMENT_CHARGE_CENTS = 1000 * DOLLAR_IN_CENTS;

// https://terminology.hl7.org/CodeSystem-icd10.html
export const ICD_10_CODE_SYSTEM_URL = 'http://hl7.org/fhir/sid/icd-10';
export const LOINC_CODE_SYSTEM_URL = 'http://loinc.org';
export const CPT_CODE_SYSTEM_URL = 'http://www.ama-assn.org/go/cpt';
export const CPTII_CODE_SYSTEM_URL =
  'https://www.ama-assn.org/practice-management/cpt/category-ii-codes';
export const HCPCS_CODE_SYSTEM_URL = 'https://www.cms.gov/Medicare/Coding/HCPCSReleaseCodeSets';

export const REQUIRE_CAREGIVER_CONSENT_MAX_AGE_YEARS = 18;

export const BEST_SLOT_SCORE = -1000000;

export const WORST_SLOT_SCORE = 1000000;

// Multiplier to scale direct distance/time between adjacent appointments to convert to incremental distance/time
// if slot only has one adjacent appointment, slotScore = dist(self, adjacent) * multiplier
// multiplier is set to 1 after running simulations and understanding that
// the difference between expected and actual has a rough distribution of one
export const SUGGESTED_SLOTS_MULTIPLIER = 1;

export const RETINA_VUE_PROD_URL = 'https://www.retinavue.net/RN_CustomerPortal/default.aspx';

export const RETINA_VUE_SANDBOX_URL =
  'https://sandbox.retinavue.net/RN_CustomerPortal/default.aspx';

// if no services provided during booking in partner portal, add blood and urine as default service types
export const LAB_SERVICE_TYPES_ADD_BY_DEFAULT_DURING_BOOKING = [
  ServiceType.BLOOD,
  ServiceType.URINE,
];

export const SLACK_APP_DEEP_LINK = 'slack://';
export const SLACK_APPLE_STORE_REF = 'https://apps.apple.com/us/app/slack/id618783545';
export const SLACK_TEAM_ID = 'T033SV8CDD0';
// #phi-awv-issues channel ID. To get channel ID in slack: right click on channel -> View channel details -> scroll to bottom
export const SLACK_AWV_CHANNEL_ID = 'C06VA5T84BZ';
// #discuss-engineering channel ID
export const SLACK_DEV_AWV_CHANNEL_ID = 'C03P89ME21W';

export const OLDEST_BOOKABLE_AGE_IN_YEARS = 125;
// youngest bookable age is 9 months
export const YOUNGEST_BOOKABLE_AGE_IN_YEARS = 9 / 12;

// For partners using these standards, we will trust them to give us the eligible children (not adults)
export const STANDARD_LEAD_SCREENING_AGE_REQUIREMENTS: AgeEligibilityRequirements[] = [
  {
    lowerLimitMonths: YOUNGEST_BOOKABLE_AGE_IN_YEARS * MONTHS_IN_YEAR,
    upperLimitMonths: REQUIRE_CAREGIVER_CONSENT_MAX_AGE_YEARS * MONTHS_IN_YEAR,
  },
];

export const HEDIS_LEAD_SCREENING_AGE_REQUIREMENTS: AgeEligibilityRequirements[] = [
  { lowerLimitMonths: 9, upperLimitMonths: 24 },
];

/**
 * Per CDPH, if a child has not ever received a lead screening, even after the
 * 24-month guideline, they are mandated to get catch-up testing if they are
 * 24-72 months old (ages 2-6)
 * https://www.cdph.ca.gov/Programs/CCDPHP/DEODC/CLPPB/Pages/screen_regs_3.aspx
 */
export const CALIFORNIA_LEAD_SCREENING_AGE_REQUIREMENTS: AgeEligibilityRequirements[] = [
  { lowerLimitMonths: 9, upperLimitMonths: 6 * MONTHS_IN_YEAR },
];

export const TOPICAL_FLUORIDE_AGE_REQUIREMENTS: AgeEligibilityRequirements = {
  upperLimitMonths: 21 * MONTHS_IN_YEAR,
  lowerLimitMonths: 1 * MONTHS_IN_YEAR,
};

export const APM_AGE_REQUIREMENTS: AgeEligibilityRequirements = {
  upperLimitMonths: 17 * MONTHS_IN_YEAR,
  lowerLimitMonths: 7 * MONTHS_IN_YEAR,
};

// TODO(wendy): update areas of the code to use these constants
export const CONSENT_TO_CONTACT_FOR_MARKETING_BY_SMS_VERSION = 0;
export const CONSENT_TO_CONTACT_FOR_MARKETING_BY_EMAIL_VERSION = 0;
export const CONSENT_TO_CONTACT_FOR_MARKETING_BY_PHONE_VERSION = 0;

export const SPRINTER_HEALTH_OFFICE_ADDRESS: AddressInput = {
  lines: ['4600 Bohannon Dr'],
  subunit: 'Ste 100',
  city: 'Menlo Park',
  state: 'CA',
  postalCode: '94025',
};

// The increment of time into the hour that we allow remote appointments to be booked at.
// A value of 30 means we can book intervals starting at 9, 9:30, 10, 10:30, etc.
// A value of 15 means we can book intervals starting at 9, 9:15, 9:30, 9:45, 10, 10:15, etc.
export const STANDARD_REMOTE_APPOINTMENT_START_INCREMENT_MINUTES = 30;

//  The number of minutes after the start time that a remote appointment will fail if it has not been started
export const STANDARD_REMOTE_APPOINTMENT_FAILURE_THRESHOLD_MINUTES = 15;

// The number of hours before the start time that a remote tech may be matched to an in-home appointment
export const IN_HOME_REMOTE_TECH_EARLY_MATCH_HOURS = 6;
