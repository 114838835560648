import KHColors from 'khshared/KHColors';
import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

const styles = {
  track: {
    height: 4,
    borderRadius: 6,
    backgroundColor: KHColors.progressBarTrack,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  } as ViewStyle,
  trackSquare: {
    borderRadius: 0,
  } as ViewStyle,
  progressBar: {
    backgroundColor: KHColors.progressBar,
    transition: 'width 0.5s',
  } as ViewStyle,
};

interface Props {
  progress?: number;
  square?: boolean;
  style?: StyleProp<ViewStyle>;
}

export default function KHProgressBar({ progress = 0, square = false, style }: Props): JSX.Element {
  return (
    <View style={[styles.track, square && styles.trackSquare, style]}>
      <View style={[styles.progressBar, { width: `${100 * progress}%` }]} />
    </View>
  );
}
