import { captureException } from '@sentry/react';
import { LocationPositionInput } from 'khshared/API';
import Geolocation, { ErrorCallback, SuccessCallback } from 'react-native-geolocation-service';

let currentPosition: LocationPositionInput | null = null;

let timer: NodeJS.Timer | null = null;

async function genUpdateCurrentPosition(): Promise<void> {
  try {
    const result = await new Promise((resolve: SuccessCallback, reject: ErrorCallback) => {
      Geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 45000,
        maximumAge: 0,
      });
    });

    currentPosition = {
      latitude: result.coords.latitude,
      longitude: result.coords.longitude,
    };
  } catch (e: unknown) {
    captureException(e);
  }
}

/**
 * Initializes periodically updating the client's current position.
 *
 * @param pollingIntervalMS The time lapse between when the current position will be updated. Default is 5 seconds.
 * @returns void
 */
export function initCurrentPosition(pollingIntervalMS = 50000): void {
  if (timer != null) return;

  (async () => {
    await genUpdateCurrentPosition();
  })();

  timer = setInterval(() => {
    (async () => {
      await genUpdateCurrentPosition();
    })();
  }, pollingIntervalMS);
}

/** Stops polling the client's current position.
 *
 * @returns void
 */

export function stopCurrentPosition(): void {
  if (timer == null) return;
  clearInterval(timer);
  timer = null;

  // since this could be completely wrong by the next call to getCurrentPositionOrNull
  currentPosition = null;
}

/** Returns the client's current GPS coordinates.
 *
 * @returns the client's current coordinates
 */
export function getCurrentPositionOrNull(): LocationPositionInput | null {
  // Even if currentPosition is not null, we would not want to return it, since we do not know the last time it was updated
  if (timer == null) {
    initCurrentPosition();
    return null;
  }
  return currentPosition;
}

/** Returns the client's current GPS coordinates asynchronously.
 *
 * @returns the client's current coordinates
 */
export async function genGetCurrentPosition(): Promise<LocationPositionInput | null> {
  const result = await new Promise((resolve: SuccessCallback, reject: ErrorCallback) => {
    Geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: true,
      timeout: 45000,
      maximumAge: 0,
    });
  });

  return { latitude: result.coords.latitude, longitude: result.coords.longitude };
}
