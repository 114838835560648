export default function generateReleaseName(branchName: string, awsJobId: string): string {
  return branchName !== 'main'
    ? `${branchName}_AmplifyBuild_${awsJobId}`
    : `AmplifyBuild_${awsJobId}`;
}

export function releaseNameOrEmptyObject(): Record<string, never> | { release: string } {
  if (process.env.AWS_BRANCH != null && process.env.AWS_JOB_ID != null)
    return {
      release: generateReleaseName(process.env.AWS_BRANCH, process.env.AWS_JOB_ID),
    };
  return {};
}
