import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import KHAuth from '../utils/KHAuth';
import PublicRoute from './PublicRoute';

export default function PrivateRoute({
  authorizedGroups,
  ...props
}: React.ComponentProps<typeof PublicRoute> & {
  authorizedGroups: (
    | 'Schedulers'
    | 'Techs'
    | 'Coordinators'
    | 'Partners'
    | 'PatientEngagementCSRs'
    | 'RemoteTechs'
    | 'CaseManagers'
    | 'CMSManagers'
  )[];
}): JSX.Element {
  const location = useLocation();

  const userGroups = KHAuth.getGroupsOrNull();
  if (userGroups == null || !authorizedGroups.some((group) => userGroups.includes(group))) {
    return (
      <Redirect
        to={{
          pathname: '/signin',
          state: { from: location },
        }}
      />
    );
  }

  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <PublicRoute {...props} />;
}
