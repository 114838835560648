import KHColors from 'khshared/KHColors';
import React, { ReactNode, useContext } from 'react';
import { TextStyle, ViewStyle } from 'react-native';

import { LoggerProps } from '../utils/useLogger';
import KHButton, { Props as KHButtonProps } from './KHButton';
import KHThemeContext from './KHThemeContext';

const styles = {
  button: {
    borderColor: KHColors.chipBorder,
  } as ViewStyle,
  buttonCompact: {
    height: 32,
  } as ViewStyle,
  label: {
    fontSize: 13,
    fontWeight: 'normal',
    marginHorizontal: 10,
    color: KHColors.text,
  } as TextStyle,
  labelDisabled: {
    color: KHColors.chipLabelDisabled,
  } as TextStyle,
  labelCompact: {
    marginVertical: 7,
  } as TextStyle,
  labelContrast: {
    color: KHColors.textContrast,
  } as TextStyle,
  labelSelected: {
    color: KHColors.chipLabelSelected,
  } as TextStyle,
  labelSelectedDisabled: {
    color: KHColors.chipLabelSelectedDisabled,
  } as TextStyle,
  buttonDisabled: {
    borderColor: KHColors.chipBorderDisabled,
  } as ViewStyle,
  buttonSelected: {
    backgroundColor: KHColors.chipBackgroundSelected,
    borderColor: KHColors.chipBorderSelected,
  } as ViewStyle,
  buttonSelectedDisabled: {
    borderWidth: 1,
    borderColor: KHColors.chipBorderSelectedDisabled,
    backgroundColor: KHColors.chipBackgroundSelectedDisabled,
  } as ViewStyle,
  labelSelectedV2: {
    fontFamily: 'Red Hat Display',
    fontSize: 18,
    fontWeight: '700',
  } as TextStyle,
  labelV2: {
    fontFamily: 'Red Hat Display',
    fontSize: 18,
    fontWeight: '500',
  } as TextStyle,
};

interface Props<E extends `${string}ButtonClick`>
  extends Pick<
    KHButtonProps<E>,
    | 'compact'
    | 'disabled'
    | 'icon'
    | 'labelStyle'
    | 'onPress'
    | 'style'
    | 'testID'
    | keyof LoggerProps<string>
  > {
  label?: ReactNode;
  selected?: boolean;
  contrast?: boolean;
}

export default function KHChip<E extends `${string}ButtonClick`>({
  compact = true,
  disabled = false,
  icon,
  label,
  labelStyle,
  onPress,
  selected = false,
  contrast = false,
  style,
  ...buttonProps
}: Props<E>): JSX.Element {
  const theme = useContext(KHThemeContext);
  return (
    <KHButton<E>
      compact={compact}
      icon={icon}
      iconColor={selected ? KHColors.chipIconSelected : undefined}
      labelStyle={[
        styles.label,
        theme === 'v2' && styles.labelV2,
        compact && styles.labelCompact,
        contrast && styles.labelContrast,
        disabled && styles.labelDisabled,
        selected && (disabled ? styles.labelSelectedDisabled : styles.labelSelected),
        selected && theme === 'v2' && styles.labelSelectedV2,
        labelStyle,
      ]}
      onPress={onPress}
      style={[
        style,
        styles.button,
        compact && styles.buttonCompact,
        disabled && styles.labelDisabled,
        selected && (disabled ? styles.buttonSelectedDisabled : styles.buttonSelected),
      ]}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
    >
      {label}
    </KHButton>
  );
}
