import getErrorLoggingFields from 'khshared/getErrorLoggingFields';
import { Logger } from 'pino';
import { Linking } from 'react-native';

// eslint-disable-next-line local-rules/require-gen-prefix
export default async function openFileURL(urlToOpen: string, logger: Logger): Promise<void> {
  try {
    const canOpenURL = await Linking.canOpenURL(urlToOpen);
    if (canOpenURL) await Linking.openURL(urlToOpen);
    else throw new Error('Cannot open this file in new tab using Linking.');
  } catch (e) {
    logger.error({
      eventName: 'openPhotoCardInNewTabFailed',
      url: urlToOpen,
      ...getErrorLoggingFields(e),
    });
  }
}
