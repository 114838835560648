/* eslint-disable local-rules/require-gen-prefix */
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { captureException } from '@sentry/react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { Logger } from 'pino';

/* eslint-disable-next-line @typescript-eslint/require-await */
export async function genLastSession(): Promise<CognitoUserSession | null> {
  return null;
}

export async function genSignInSession(
  email: string,
  password: string,
): Promise<CognitoUserInterface> {
  return Auth.signIn(email, password) as Promise<CognitoUserInterface>;
}

export async function genSignOutSession(): Promise<void> {
  try {
    await Auth.signOut();
  } catch (e) {
    captureException(e);
  }
}

/* eslint-disable-next-line @typescript-eslint/require-await */
export async function isFingerprintEnabled(): Promise<boolean> {
  return false;
}

/* eslint-disable-next-line @typescript-eslint/require-await, @typescript-eslint/no-unused-vars */
export async function fingerprintSignInSuccessful(logger: Logger): Promise<boolean> {
  return false;
}
