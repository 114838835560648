import KHColors from 'khshared/KHColors';
import React, { ReactNode, useContext } from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';

import KHIcon from './KHIcon';
import KHThemeContext from './KHThemeContext';

const styles = {
  view: {
    flexDirection: 'row',
  } as ViewStyle,
  viewV2: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  } as ViewStyle,
  icon: {
    justifyContent: 'flex-start',
    marginRight: 16,
    marginTop: -3,
    marginBottom: -3,
  } as ViewStyle,
  iconV2: {
    marginRight: 8,
  } as ViewStyle,
  textV2: {
    fontFamily: 'Red Hat Display',
    fontSize: 16,
    fontWeight: '500',
  } as ViewStyle,
};

interface Props {
  icon: string;
  children: ReactNode;
  iconColor?: string;
  style?: StyleProp<ViewStyle>;
}

export default function KHIconText({ icon, style, children, iconColor }: Props): JSX.Element {
  const theme = useContext(KHThemeContext);
  return (
    <View style={[styles.view, theme === 'v2' && styles.viewV2, style]}>
      <KHIcon
        source={icon}
        size={24}
        style={[styles.icon, theme === 'v2' && styles.iconV2]}
        color={iconColor ?? KHColors.iconPrimary}
      />
      <Text style={theme === 'v2' && styles.textV2}>{children}</Text>
    </View>
  );
}
