import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Runs an imperative, possibly effectful function on updates, much like useEffect but without
 * running on mount.
 *
 * @param effect Imperative function that can return a cleanup function
 * @param deps If present, effect will only activate if the values in the list change.
 *
 * @see https://reactjs.org/docs/hooks-reference.html#useeffect
 */
export default function useUpdateEffect(effect: EffectCallback, deps?: DependencyList): void {
  const hasMountedRef = useRef(false);

  useEffect(() => {
    if (hasMountedRef.current) return effect();
    hasMountedRef.current = true;
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
