import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View, ViewStyle } from 'react-native';

const styles = {
  screen: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
};

export default function LoadingScreen(): JSX.Element {
  const [isSpinnerShown, setSpinnerShown] = useState<boolean>(false);

  useEffect(() => {
    // Only show the spinner after 200ms of loading so that it doesn't show unnecessarily when the resource loads quickly.
    const timeout = setTimeout(() => setSpinnerShown(true), 200);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <View style={styles.screen} testID="loading-spinner">
      {isSpinnerShown && <ActivityIndicator size="large" />}
    </View>
  );
}
