import PartnerOrganizationGroup from './PartnerOrganizationGroup';

export type PartnerGroupConfig = {
  displayName: string;
  areInvoiceEmailsConsolidated?: boolean;
};

export const PARTNER_GROUP_CONFIGS: {
  [partnerOrganizationGroup in PartnerOrganizationGroup]: PartnerGroupConfig;
} = {
  A1HOMEHEALTHANDHOSPICE: {
    displayName: 'A1 Home Health',
  },
  ABLHEALTHCARE: {
    displayName: 'ABL Health Care',
  },
  ACCOMPANYHEALTH: {
    displayName: 'Accompany Health',
  },
  AFFORDABLEPATERNITY: {
    displayName: 'Affordable Paternity',
  },
  ALPHACARE: {
    displayName: 'AlphaCare',
  },
  AMOREHOMEHEALTH: {
    displayName: 'Amore Home Health',
  },
  APOLLOHOMEHEALTH: {
    displayName: 'Apollo',
  },
  ASIANNETWORKPACIFICHOMECARE: {
    displayName: 'Asian Network Pacific Home Care',
  },
  ASSISTONCALL: {
    displayName: 'Assist On Call',
  },
  BESTHOMEHEALTH: {
    displayName: 'Best Home Health',
  },
  CAPITOLHEALTHCARE: {
    displayName: 'Capitol Healthcare',
  },
  CAREINTOUCHHOMEHEALTHAGENCY: {
    displayName: 'Care In Touch Home Health Agency',
  },
  CAREMUST: {
    displayName: 'Care Must',
  },
  CEDARSSINAI: {
    displayName: 'Cedars-Sinai',
  },
  COMFORTHOMEHEALTHCARE: {
    displayName: 'Comfort Home Health Care',
  },
  CONCIERGEMDLA: {
    displayName: 'Concierge MD LA',
  },
  DEMOLAB: {
    displayName: 'Widget Labs',
  },
  DEMOPROVIDER: {
    displayName: 'Widget MD',
  },
  DISCOVERHEALTH: {
    displayName: 'Discover Health MD',
  },
  DRIPHYDRATION: {
    displayName: 'Drip Hydration',
  },
  DRVILLIVALAM: {
    displayName: 'Dr. Villivalam',
  },
  ELDERCAREMANAGEMENT: {
    displayName: 'Elder Care Management',
  },
  EMMANUELHEALTHCAREINC: {
    displayName: 'Emmanuel Healthcare',
  },
  ESSENTIALSHOMEHEALTH: {
    displayName: 'Essentials Home Health',
  },
  FOUNDATIONMEDICINE: {
    displayName: 'Foundation Medicine',
  },
  FIRSTCHOICEHOMEHEALTH: {
    displayName: '1st Choice Home Health & Hospice',
  },
  EXACTSCIENCES: {
    displayName: 'Exact Sciences',
  },
  GEORGIAHANDSHOULDERELBOW: {
    displayName: 'Georgia Hand, Shoulder & Elbow',
  },
  GRAIL: {
    displayName: 'GRAIL',
  },
  GUARDANT: {
    displayName: 'Guardant',
  },
  GUARDIANANGELHOMECARE: {
    displayName: 'Guardian Angel Home Care',
  },
  HARMONYHH: {
    displayName: 'Harmony Home Health',
  },
  HOMEHEALTHBAYAREAINC: {
    displayName: 'Home Health Bay Area',
  },
  HEALTHFLEX: {
    displayName: 'HealthFlex',
  },
  JOHNMUIRHEALTH: {
    displayName: 'John Muir Health',
  },
  KANEHEALTH: {
    displayName: 'Kane Health',
  },
  KRISTARAMONASMD: {
    displayName: 'Krista Ramonas, MD',
  },
  INTOUCHHOMECARE: {
    displayName: 'InTouch Home Care',
  },
  LEBAE: {
    displayName: 'Lebae',
  },
  MARSHALLMEDICALCENTER: {
    displayName: 'Marshall Medical Center',
  },
  NATERA: {
    displayName: 'Natera',
  },
  PINNACLEHOMEHEALTH: {
    displayName: 'Pinnacle Home Health',
  },
  RUPAHEALTH: {
    displayName: 'Rupa Health',
  },
  SEHAJHOMEHEALTH: {
    displayName: 'Sehaj Home Health',
  },
  SILVERADOBELMONTMEMORYCARE: {
    displayName: 'Silverado Belmont Memory Care',
  },
  STPATRICKHOMEHEALTH: {
    displayName: 'St. Patrick Home Health Providers',
  },
  AAHCKINDRED: {
    displayName: 'Asian American Home Care',
  },
  HALESPORTS: {
    displayName: 'HALE Sports',
  },
  LONETREEPOSTACUTE: {
    displayName: 'Lone Tree Post Acute',
  },
  MERIDIANHOMEHEALTH: {
    displayName: 'Meridian Home Health',
  },
  MYDOCTORMEDICALGROUP: {
    displayName: 'My Doctor Medical Group',
  },
  NAVERIS: {
    displayName: 'Naveris',
  },
  NORTHBAYPRIVATEMEDICINE: {
    displayName: 'North Bay Private Medicine',
  },
  NURSINGANDREHABATHOME: {
    displayName: 'Nursing and Rehab at Home',
  },
  OPOHEALTH: {
    displayName: 'Opo Health',
  },
  FIREFLYHEALTH: {
    displayName: 'Firefly',
  },
  OSCAR: {
    displayName: 'Oscar',
  },
  OSCARDEMO: {
    displayName: 'Generic Demo',
  },
  PENINSULADOCTOR: {
    displayName: 'Peninsula Doctor',
  },
  PERPETUALCAREHOMEHEALTH: {
    displayName: 'Perpetual Care Home Health',
  },
  PRIMELIVINGHOMEHEALTH: {
    displayName: 'Primeliving Home Health',
  },
  PULSEDEMO: {
    displayName: 'Pulse Demo',
  },
  READY2NURSE: {
    displayName: 'Ready2Nurse',
  },
  SILVERLINESTAFF: {
    displayName: 'Silverline Staff',
  },
  SOCOLMD: {
    displayName: 'SocolMD',
  },
  SONDERHEALTHCARE: {
    displayName: 'Sonder Healthcare',
  },
  STANFORDCANCERCENTER: {
    displayName: 'Stanford Cancer Center',
  },
  SUNCRESTHOSPICE: {
    displayName: 'Suncrest Home Health & Hospice',
  },
  ALLIANCEHOMEHEALTH: {
    displayName: 'Alliance Home Health',
  },
  TRUEMED: {
    displayName: 'TrueMed',
  },
  UCSF: {
    displayName: 'UCSF',
  },
  VNHOMEHEALTHCARE: {
    displayName: 'VN Home Health Care',
  },
  YOURCHOICEHOMEHEALTH: {
    displayName: 'Your Choice Home Health',
  },
  BMCDEMO: {
    displayName: 'BMC Demo',
  },
  BOSTONMEDICALCENTER: {
    displayName: 'Boston Medical Center',
    areInvoiceEmailsConsolidated: true,
  },
  WELLSENSE: {
    displayName: 'WellSense',
  },
  BRADFORDRABINMDANDHIROYUHATANOMD: {
    displayName: 'Bradford Rabin MD and Hiroyu Hatano MD',
  },
  THEBODYWRX: {
    displayName: 'The Body WRX',
  },
  D2C: {
    displayName: 'D2C',
  },
  HOMEHEALTHRESOURCEGROUP: {
    displayName: 'Home Health Resource Group',
  },
  STORYHEALTH: {
    displayName: 'Story Health',
  },
  STANFORDRESEARCH: {
    displayName: 'Stanford Research',
  },
  TRAININGPARTNER: {
    displayName: 'Training Partner',
  },
  INTERMOUNTAINCANCERCENTER: {
    displayName: 'Intermountain Cancer Center',
  },
  ALIGNMENTHEALTH: {
    displayName: 'Alignment Health',
  },
  MOLINA: {
    displayName: 'Molina',
  },
  WELLSENSENH: {
    displayName: 'WellSense NH',
  },
  UCI: {
    displayName: 'UCI',
  },
  ELEVANCEHEALTH: {
    displayName: 'Elevance Health',
  },
  CENTENE: {
    displayName: 'Centene',
  },
  UHC: {
    displayName: 'UnitedHealthcare',
  },
  HUMANA: {
    displayName: 'Humana',
  },
};

export default function getPartnerGroupConfig(
  partnerOrganizationGroup: PartnerOrganizationGroup,
): PartnerGroupConfig {
  return PARTNER_GROUP_CONFIGS[partnerOrganizationGroup];
}
