import {
  DropOffOrganization,
  TimeRangeRuleInclusion,
  TimeRangeRuleInput,
  TimeRangeRulePeriod,
} from '../API';
import excludeHolidays from './createHolidayExclusionRule';

export const DEFAULT_COLLECTION_TIME_RULES: TimeRangeRuleInput[] = [
  // Monday to Friday every week, 7am - 4pm local time.
  // Saturday and Sunday every week, 8am - 12pm local time.
  {
    id: 'e4fb17f5-ba67-4ec9-a64d-73fb21aee91d',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '16:00:00',
    recurrenceStart: '2021-01-04', // Monday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '528d1421-8254-4abb-b9d2-3aa09dfe8e43',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '16:00:00',
    recurrenceStart: '2021-01-05', // Tuesday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: 'e7a4b356-9678-4516-bfa3-e7bef2aa987a',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '16:00:00',
    recurrenceStart: '2021-01-06', // Wednesday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '0c053c5f-4cbf-4e1f-9f38-b4b7c9955133',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '16:00:00',
    recurrenceStart: '2021-01-07', // Thursday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '3ddcfe1b-c1dd-4a41-8684-d0311d8ed181',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '16:00:00',
    recurrenceStart: '2021-01-01', // Friday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '4b32d5f1-4ba8-4e3b-95c8-65eb3069ea51',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '08:00:00',
    end: '12:00:00',
    recurrenceStart: '2021-01-02', // Saturday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '0f640898-8e8f-4bd7-bb76-de8578621379',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '08:00:00',
    end: '12:00:00',
    recurrenceStart: '2021-01-03', // Sunday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
];

export const EXTENDED_HOUR_TIME_RULES: TimeRangeRuleInput[] = [
  // Daily, 7am - 7pm local time.
  {
    id: '131991e5-aaa8-40ca-89aa-aa1a226b0bc2',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '19:00:00',
    recurrenceStart: '2024-08-09',
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.DAY,
  },
];

export const OSCAR_COLLECTION_TIME_RULES_WITH_SATURDAY: TimeRangeRuleInput[] = [
  // Monday to Saturday every week, 7am - 4pm local time.
  ...DEFAULT_COLLECTION_TIME_RULES,
  {
    id: '3ddcfe1b-c1dd-4a41-8684-d0311d8ed181',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '16:00:00',
    recurrenceStart: '2021-01-02', // Saturday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
];

const FEDEX_HOLIDAY_TIME_RULES: TimeRangeRuleInput[] = [
  // In order to ensure that samples are not left sitting in FedEx, specialty labs that require
  // shipping prohibit collection on days when FedEx is closed. Just to be safe, we also disallow
  // collection on days where FedEx has modified services. These rules must be updated each year.
  //
  // FedEx 2024 reference (using FedEx Express service schedule):
  //   https://www.fedex.com/content/dam/fedex/us-united-states/services/2024-Holiday-schedule-FedEx.pdf
  //
  // 2022
  excludeHolidays('78e5dfd3-4771-45c0-8041-217b1d3a9dc9', '2022-01-17', '2022-01-17'), // MLK
  excludeHolidays('53787693-e80d-47e9-86a8-4fc5bd719b07', '2022-02-21', '2022-02-21'), // President's Day
  excludeHolidays('d0f3bb26-d12d-491f-9e74-72188b137d67', '2022-04-15', '2022-04-15'), // Good Friday
  excludeHolidays('0d5b2137-2929-40f9-8523-864b213b8803', '2022-04-17', '2022-04-17'), // Easter Sunday
  excludeHolidays('3f0ae95b-9202-4325-a5b6-a01b4ff346b8', '2022-05-29', '2022-05-30'), // Memorial Day & day before
  excludeHolidays('1d91ba28-1277-413c-bd52-e59dd18f1279', '2022-07-01', '2022-07-04'), // Independence Day & surrounding days
  excludeHolidays('bde47cb1-db4d-4e4f-a030-f797d54489e0', '2022-09-04', '2022-09-05'), // Labor Day & day before
  excludeHolidays('9db600de-eb03-48ae-a674-fe1f1a91dba4', '2022-11-23', '2022-11-27'), // Thanksgiving & surrounding days
  excludeHolidays('5bb65108-ab71-44fe-9388-55757b6c9394', '2022-12-18', '2022-12-18'), // Sunday before Christmas Eve
  excludeHolidays('78fb7088-cc4d-4d13-8080-41a801b66cce', '2022-12-23', '2022-12-26'), // Christmas & surrounding days
  excludeHolidays('857aa2f5-52fe-4ef3-9686-c13f74b42f5c', '2022-12-30', '2023-01-02'), // New Year Eve & Day

  // 2023
  excludeHolidays('db804287-4477-4328-9041-12c001c39f9d', '2023-09-04', '2023-09-04'), // Labor Day
  excludeHolidays('46e6b4f7-16fe-426b-ba54-a30287eb9379', '2023-11-23', '2023-11-23'), // Thanksgiving
  excludeHolidays('fbb48154-3240-4efb-9561-2220381aff0a', '2023-12-25', '2023-12-25'), // Christmas day
  excludeHolidays('4fda7b27-b942-4b83-8245-c159585601e6', '2024-01-01', '2024-01-01'), // New Year's Day

  // 2024
  excludeHolidays('eff34a28-e4c2-5094-b43b-4d20317a57a1', '2024-01-15', '2024-01-15'), // MLK
  excludeHolidays('06f5c6cd-7d6a-58fc-82d3-4a3c16a19989', '2024-02-19', '2024-02-19'), // Presidents' Day
  excludeHolidays('5a24af4c-6fab-5526-8ded-f5438e8369f4', '2024-03-29', '2024-03-29'), // Good Friday
  excludeHolidays('58560f42-3d36-5417-b090-f490f377e7c2', '2024-05-27', '2024-05-27'), // Memorial Day
  excludeHolidays('32f759d8-db5c-5420-9aa0-56e0211c5b4d', '2024-07-03', '2024-07-07'), // Independence Day & surrounding days
  excludeHolidays('36336337-5c6d-52a0-bc33-fc52407b017f', '2024-09-02', '2024-09-02'), // Labor Day
  excludeHolidays('bac0ec37-966f-5e25-92f2-58c324e625b5', '2024-10-14', '2024-10-14'), // Columbus Day
  excludeHolidays('cf7c9ec1-7b8e-5307-bf7c-a5c2fa329e8e', '2024-11-10', '2024-11-11'), // Veterans Day & surrounding days
  excludeHolidays('8eb1f3bf-b22f-5d6b-9ef4-00a8cc0c0590', '2024-11-27', '2024-12-01'), // Thanksgiving & surrounding days
  excludeHolidays('bb3ff3ae-df03-5361-8b92-21f05e262239', '2024-12-22', '2024-12-25'), // Christmas Day & surrounding days
  excludeHolidays('c1e19a80-9145-5211-837e-7a4758e81784', '2024-12-30', '2025-01-01'), // New Year's Day & surrounding days
];

const USPS_HOLIDAY_TIME_RULES: TimeRangeRuleInput[] = [
  // USPS current year reference:
  // https://about.usps.com/newsroom/events/
  // 2023
  excludeHolidays('286125e7-73fb-473b-af82-ec3a4bd08028', '2023-09-04', '2023-09-04'), // Labor Day
  excludeHolidays('f47872a2-1142-4f03-ac72-b51518808c01', '2023-10-09', '2023-10-09'), // Columbus Day
  excludeHolidays('d6c3abdc-0da0-4f91-928b-ccf80c5fe6f3', '2023-11-10', '2023-11-10'), // Veterans Day Friday before
  excludeHolidays('523040c8-c052-4431-9677-4e4471ac95e6', '2023-11-23', '2023-11-23'), // Thanksgiving
  excludeHolidays('c8b250e0-b834-46ff-aa83-da3efff26f55', '2023-12-25', '2023-12-25'), // Christmas day
  excludeHolidays('ec276e9c-12fd-4e47-bf73-7ca255d85043', '2024-01-01', '2024-01-01'), // New Year's Day

  // 2024
  excludeHolidays('04bfc05f-564a-53bd-b003-f2a5f2042a01', '2024-01-15', '2024-01-15'), // MLK
  excludeHolidays('b3b72854-656a-51bd-982b-66bd4f552ca9', '2024-02-19', '2024-02-19'), // Presidents' Day
  excludeHolidays('b6e7982f-f38f-5a9a-93c6-0dc0be4ca0b7', '2024-05-27', '2024-05-27'), // Memorial Day
  excludeHolidays('a803a2c9-b56d-5de0-8aa1-a13b7ee75c93', '2024-06-19', '2024-06-19'), // Juneteenth
  excludeHolidays('8db38884-d9da-5cb2-a550-8ec682ebf290', '2024-07-04', '2024-07-04'), // Independence Day
  excludeHolidays('ae7460d7-736a-55f0-b23a-3b56bea5cfbc', '2024-09-02', '2024-09-02'), // Labor Day
  excludeHolidays('fae741d2-677c-5cc9-b5f2-b5e58c106a94', '2024-10-14', '2024-10-14'), // Columbus Day
  excludeHolidays('1a003c2c-5117-51ea-b3e7-64b3dbc45145', '2024-11-11', '2024-11-11'), // Veterans Day
  excludeHolidays('75bc1b4c-d8f8-5ab1-acac-badbfd17970f', '2024-11-28', '2024-11-28'), // Thanksgiving
  excludeHolidays('5fb08a6f-3f2d-5c28-b923-66972f50158f', '2024-12-25', '2024-12-25'), // Christmas day
  excludeHolidays('1b3f433f-96f9-560f-93b9-1f296b4b44b4', '2025-01-01', '2025-01-01'), // New Year's Day
];

const DROP_OFF_ORGANIZATION_TIME_RANGE_RULES: {
  [key in DropOffOrganization]: TimeRangeRuleInput[];
} = {
  [DropOffOrganization.BIO_REFERENCE]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.FEDEX]: [...DEFAULT_COLLECTION_TIME_RULES, ...FEDEX_HOLIDAY_TIME_RULES],
  [DropOffOrganization.LABCORP]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.MARSHALL_LABORATORY]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.MUIRLAB]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.QUEST]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.SPRINTER_HEALTH]: EXTENDED_HOUR_TIME_RULES,
  [DropOffOrganization.STANFORD]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.STANFORD_CANCER_CENTER]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.UCSF_BLOOD_DRAW_LAB]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.DOTHOUSE_LAB]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.CODMAN_LAB]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.BMC_LAB]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.ROSLINDALE_LAB]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.WEST_PAC]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.KAISER]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.STANFORD_RESEARCH]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.TRAINING_PARTNER]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.INTERMOUNTAIN]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.INTERMOUNTAIN_CANCER_CENTER]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.UCI_LAB]: DEFAULT_COLLECTION_TIME_RULES,
  [DropOffOrganization.USPS]: [...DEFAULT_COLLECTION_TIME_RULES, ...USPS_HOLIDAY_TIME_RULES],
};

export default DROP_OFF_ORGANIZATION_TIME_RANGE_RULES;
