import KHColors from 'khshared/KHColors';
import React, { ComponentProps, ReactNode } from 'react';
import { StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';

import KHIcon from './KHIcon';

const styles = {
  view: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  } as ViewStyle,
  icon: {
    marginBottom: 48,
  } as ViewStyle,
  iconCompact: {
    marginBottom: 16,
  } as ViewStyle,
  title: {
    textAlign: 'center',
    fontSize: 24,
    marginBottom: 32,
    color: KHColors.text,
  } as TextStyle,
  message: {
    textAlign: 'center',
    marginBottom: 32,
    maxWidth: 300,
    color: KHColors.text,
  } as TextStyle,
  textCompact: {
    marginBottom: 16,
  } as ViewStyle,
};

interface Props {
  children?: ReactNode;
  icon?: ComponentProps<typeof KHIcon>['source'];
  compact?: boolean;
  message?: ReactNode;
  style?: StyleProp<TextStyle>;
  title?: ReactNode;
  testID?: string;
}

export default function KHMessageView({
  children,
  icon,
  compact = false,
  message,
  style,
  title,
  testID,
}: Props): JSX.Element | null {
  return (
    <View style={[styles.view, style]} testID={testID}>
      {icon != null && (
        <KHIcon
          source={icon}
          size={96}
          color={KHColors.iconPrimary}
          style={[styles.icon, compact && styles.iconCompact]}
        />
      )}
      {Boolean(title) && <Text style={[styles.title, compact && styles.textCompact]}>{title}</Text>}
      {Boolean(message) && (
        <Text style={[styles.message, compact && styles.textCompact]}>{message}</Text>
      )}
      {children}
    </View>
  );
}
