import KHColors from 'khshared/KHColors';
import React, { ReactNode, useContext } from 'react';
import { StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import { Switch } from 'react-native-paper';

import KHThemeContext from './KHThemeContext';

const styles = {
  view: {
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: 24,
    minWidth: 24,
    flex: 1,
  } as ViewStyle,
  container: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
  } as ViewStyle,
  label: {
    color: KHColors.checkboxLabel,
    marginLeft: 12,
    flex: 1,
    flexWrap: 'wrap',
  } as TextStyle,
  labelV2: {
    color: KHColors.checkboxLabel,
    marginLeft: 12,
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Red Hat Display',
    lineHeight: 18,
    letterSpacing: 0.16,
  } as TextStyle,
  bold: {
    fontWeight: '500',
  } as TextStyle,
  labelDisabled: {
    color: KHColors.checkboxLabelDisabled,
  } as TextStyle,
};

interface Props {
  label: string;
  value: boolean | null | undefined;
  disabled?: boolean;
  bold?: boolean;
  onChange?: (value: boolean) => void;
  testID?: string;
  style?: StyleProp<ViewStyle>;
  labelAlignment?: 'left' | 'right';
}

function constructTestID(testID: string | undefined, label: ReactNode): string | undefined {
  if (testID) return testID;

  if (label && typeof label === 'string')
    return `${label.replace(/[\W_]+/g, '')}-toggle`.toLowerCase();

  return undefined;
}

export default function KHSwitch({
  label,
  value,
  disabled,
  bold,
  onChange,
  testID,
  style,
  labelAlignment = 'right',
}: Props): JSX.Element {
  const theme = useContext(KHThemeContext);
  const checked = value || false;
  const rest = { accessibilityChecked: checked };

  const Label = (
    <Text
      style={[
        styles.label,
        theme === 'v2' && styles.labelV2,
        bold && styles.bold,
        disabled && styles.labelDisabled,
      ]}
      testID="kh-switch-label"
    >
      {label}
    </Text>
  );

  return (
    <View style={[styles.view, style]} testID={constructTestID(testID, label)}>
      <View style={styles.container}>
        {labelAlignment === 'left' && Label}
        <Switch
          value={value ?? false}
          disabled={disabled}
          color={KHColors.iconPrimary}
          onValueChange={onChange}
          accessibilityRole="togglebutton"
          accessibilityState={{ checked, disabled }}
          testID="kh-switch-toggle-button"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
        {labelAlignment === 'right' && Label}
      </View>
    </View>
  );
}
