import {
  PartnerOrganization,
  TimeRangeRuleInclusion,
  TimeRangeRuleInput,
  TimeRangeRulePeriod,
} from '../API';
import { DEFAULT_COLLECTION_TIME_RULES } from './DROP_OFF_ORGANIZATION_TIME_RANGE_RULES';

// Allow scheduling M-F 7AM - 3PM starting 9/12/2022
const OSCAR_TIME_RANGE_RULE: TimeRangeRuleInput[] = [
  {
    id: 'e4fb17f5-ba67-4ec9-a64d-73fb21aee91d',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-09-12', // Monday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '528d1421-8254-4abb-b9d2-3aa09dfe8e43',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-09-13', // Tuesday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: 'e7a4b356-9678-4516-bfa3-e7bef2aa987a',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-09-14', // Wednesday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '0c053c5f-4cbf-4e1f-9f38-b4b7c9955133',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-09-15', // Thursday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '3ddcfe1b-c1dd-4a41-8684-d0311d8ed181',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-09-16', // Friday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '3ddcfe1b-c1dd-4a41-8684-d0311d8ed182',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-09-17', // Saturday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
];

const D2C_TIME_RANGE_RULES: TimeRangeRuleInput[] = [
  {
    id: '97885b04-9c0e-4dd7-8be8-ee35adbb5a74',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-12-05', // Monday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '5ea63181-d7b4-452c-bc1b-9a25eaa8e56b',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-12-06', // Tuesday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '02901f3e-41ee-434a-9c97-862abdcbbc39',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-12-07', // Wednesday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '5b5c8422-aeda-40fc-9b01-47932870f061',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-12-08', // Thursday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
  {
    id: '3b03b9cd-b645-46c8-ac43-08820da087b8',
    inclusion: TimeRangeRuleInclusion.INCLUDE,
    start: '07:00:00',
    end: '15:00:00',
    recurrenceStart: '2022-12-09', // Friday.
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.WEEK,
  },
];

export const SCHEDULING_TIME_RANGE_CONFIGS: {
  [partnerOrganization in PartnerOrganization]?: TimeRangeRuleInput[];
} = {
  OSCAR: OSCAR_TIME_RANGE_RULE,
  EXACTSCIENCES: [
    {
      id: '4bf10956-4328-4462-851f-ffbed5d7c177',
      inclusion: TimeRangeRuleInclusion.EXCLUDE,
      start: '00:00:00',
      end: '24:00:00',
      recurrenceStart: '2022-01-07', // Block Friday.
      recurrenceMultiple: 1,
      recurrencePeriod: TimeRangeRulePeriod.WEEK,
    },
    {
      id: '67b178d1-efe1-4e06-b80a-a2b894d04490',
      inclusion: TimeRangeRuleInclusion.EXCLUDE,
      start: '00:00:00',
      end: '24:00:00',
      recurrenceStart: '2022-01-08', // Block Saturday.
      recurrenceMultiple: 1,
      recurrencePeriod: TimeRangeRulePeriod.WEEK,
    },
    {
      id: 'd719c323-184b-4938-b062-e3526dfa8b07',
      inclusion: TimeRangeRuleInclusion.EXCLUDE,
      start: '00:00:00',
      end: '24:00:00',
      recurrenceStart: '2022-01-09', // Block Sunday.
      recurrenceMultiple: 1,
      recurrencePeriod: TimeRangeRulePeriod.WEEK,
    },
    ...DEFAULT_COLLECTION_TIME_RULES,
  ],
};

export const D2C_TIME_RANGE_CONFIGS = D2C_TIME_RANGE_RULES;

export default SCHEDULING_TIME_RANGE_CONFIGS;
