export function hasSchedulerGroup(groups: string[] | null | undefined): boolean {
  if (!groups) return false;
  return groups.includes('Schedulers');
}

export function hasPartnerGroup(groups: string[] | null | undefined): boolean {
  if (!groups) return false;
  return groups.includes('Partners');
}

export function hasTechGroup(groups: string[] | null | undefined): boolean {
  if (!groups) return false;
  return groups.includes('Techs');
}

export function hasRemoteTechGroup(groups: string[] | null | undefined): boolean {
  if (!groups) return false;
  return groups.includes('RemoteTechs');
}

export function hasCoordinatorGroup(groups: string[] | null | undefined): boolean {
  if (!groups) return false;
  return groups.includes('Coordinators');
}

export function hasPatientEngagementCSRGroup(groups: string[] | null | undefined): boolean {
  if (!groups) return false;
  return groups.includes('PatientEngagementCSRs');
}

export function hasTrickleManagerGroup(groups: string[] | null | undefined): boolean {
  if (!groups) return false;
  return groups.includes('TrickleManagers');
}

export function hasCaseManagerGroup(groups: string[] | null | undefined): boolean {
  if (!groups) return false;
  return groups.includes('CaseManagers');
}

export function hasCMSManagerGroup(groups: string[] | null | undefined): boolean {
  if (!groups) return false;
  return groups.includes('CMSManagers');
}
