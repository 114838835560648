import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation as faLightCircleExlamation } from '@fortawesome/pro-light-svg-icons/faCircleExclamation';
import { faComment as faLightComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faSparkles as faLightSparkles } from '@fortawesome/pro-light-svg-icons/faSparkles';
import { faStar as faLightStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faTriangleExclamation as faLightTriangleExlamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation';
import { faAngleLeft as faRegularAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faAngleRight as faRegularAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faBan as faRegularBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faBriefcaseMedical as faRegularBriefcaseMedical } from '@fortawesome/pro-regular-svg-icons/faBriefcaseMedical';
import { faCheck as faRegularCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCircleXmark as faRegularCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faClock as faRegularClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faCopy as faRegularCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faEnvelopes as faRegularEnvelopes } from '@fortawesome/pro-regular-svg-icons/faEnvelopes';
import { faFilter as faRegularFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faHandHoldingHand as faRegularHandHoldingHand } from '@fortawesome/pro-regular-svg-icons/faHandHoldingHand';
import { faHeadset as faRegularHeadset } from '@fortawesome/pro-regular-svg-icons/faHeadset';
import { faMap as faRegularMap } from '@fortawesome/pro-regular-svg-icons/faMap';
import { faSirenOn as faRegularSirenOn } from '@fortawesome/pro-regular-svg-icons/faSirenOn';
import { faUserPlus as faRegularUserPlus } from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import { faXmark as faRegularXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faBone as faSolidBone } from '@fortawesome/pro-solid-svg-icons/faBone';
import { faBox as faSolidBox } from '@fortawesome/pro-solid-svg-icons/faBox';
import { faCalendarClock as faSolidCalendarClock } from '@fortawesome/pro-solid-svg-icons/faCalendarClock';
import { faCalendarPlus as faSolidCalendarPlus } from '@fortawesome/pro-solid-svg-icons/faCalendarPlus';
import { faCircleXmark as faSolidCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { faClipboardQuestion as faSolidClipboardQuestion } from '@fortawesome/pro-solid-svg-icons/faClipboardQuestion';
import { faEnvelopeOpenText as faSolidEnvelopeOpenText } from '@fortawesome/pro-solid-svg-icons/faEnvelopeOpenText';
import { faFileChartColumn as faSolidFileChartColumn } from '@fortawesome/pro-solid-svg-icons/faFileChartColumn';
import { faGaugeSimpleLow as faSolidGaugeSimpleLow } from '@fortawesome/pro-solid-svg-icons/faGaugeSimpleLow';
import { faHeadset as faSolidHeadset } from '@fortawesome/pro-solid-svg-icons/faHeadset';
import { faHeartPulse as faSolidHeartPulse } from '@fortawesome/pro-solid-svg-icons/faHeartPulse';
import { faParachuteBox as faSolidParachuteBox } from '@fortawesome/pro-solid-svg-icons/faParachuteBox';
import { faPhone as faSolidPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faQuoteLeft as faSolidQuoteLeft } from '@fortawesome/pro-solid-svg-icons/faQuoteLeft';
import { faSocks as faSolidSocks } from '@fortawesome/pro-solid-svg-icons/faSocks';
import { faSparkles as faSolidSparkles } from '@fortawesome/pro-solid-svg-icons/faSparkles';
import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faStethoscope as faSolidStethoscope } from '@fortawesome/pro-solid-svg-icons/faStethoscope';
import { faStreetView as faSolidStreetView } from '@fortawesome/pro-solid-svg-icons/faStreetView';
import { faToothbrush as faSolidToothbrush } from '@fortawesome/pro-solid-svg-icons/faToothbrush';
import { faUserDoctorMessage as faSolidUserDoctorMessage } from '@fortawesome/pro-solid-svg-icons/faUserDoctorMessage';
import { faVial as faSolidVial } from '@fortawesome/pro-solid-svg-icons/faVial';
import { faVirusCovid as faSolidVirusCovid } from '@fortawesome/pro-solid-svg-icons/faVirusCovid';
import { faVrCardboard as faSolidVrCardboard } from '@fortawesome/pro-solid-svg-icons/faVrCardboard';
import { faWeightScale as faSolidWeightScale } from '@fortawesome/pro-solid-svg-icons/faWeightScale';
import { faXRay as faSolidXRay } from '@fortawesome/pro-solid-svg-icons/faXRay';
import { captureException } from '@sentry/react';

import isProduction from './isProduction';

// Add font awesome icons here for them to be included in the bundle.
// Once included in the bundle, they can be used anywhere by their string name.

const addedIcons = new Set();

export default function setupFontAwesome(): void {
  for (const icon of [
    faLightStar,
    faSolidStar,
    faLightSparkles,
    faSolidSparkles,
    faRegularHeadset,
    faRegularFilter,
    faSolidStreetView,
    faSolidHeadset,
    faRegularXmark,
    faRegularSirenOn,
    faRegularClock,
    faRegularAngleLeft,
    faRegularAngleRight,
    faRegularMap,
    faRegularCircleXmark,
    faSolidCircleXmark,
    faRegularCheck,
    faLightCircleExlamation,
    faLightTriangleExlamation,
    faRegularUserPlus,
    faSolidQuoteLeft,
    faRegularBan,
    faSolidPhone,
    faRegularBriefcaseMedical,
    faRegularHandHoldingHand,
    faSolidEnvelopeOpenText,
    faSolidFileChartColumn,
    faRegularCopy,
    faRegularEnvelopes,
    faLightComment,
    faSolidVrCardboard,
    faSolidHeartPulse,
    faSolidVial,
    faSolidVirusCovid,
    faSolidGaugeSimpleLow,
    faSolidXRay,
    faSolidBox,
    faSolidParachuteBox,
    faSolidClipboardQuestion,
    faSolidCalendarPlus,
    faSolidCalendarClock,
    faSolidUserDoctorMessage,
    faSolidToothbrush,
    faSolidBone,
    faSolidStethoscope,
    faSolidWeightScale,
    faSolidSocks,
  ]) {
    library.add(icon);
    addedIcons.add(`${icon.prefix}|${icon.iconName}`);
  }
}

export function alertIfFontAwesomeIconNotImported(prefix: string, iconName: string): void {
  if (!addedIcons.has(`${prefix}|${iconName}`)) {
    const error = `Icon used without being imported. Add the icon in setupFontAwesome.ts. prefix: ${prefix}, icon name: ${iconName}`;
    if (isProduction()) {
      captureException(error);
    } else {
      throw new Error(error);
    }
  }
}
