import { Kinesis, PutRecordsInput } from '@aws-sdk/client-kinesis';
import { CognitoIdentityCredentials } from '@aws-sdk/credential-provider-cognito-identity';
import { camelCase } from 'change-case';
import { releaseNameOrEmptyObject } from 'khshared/generateReleaseName';
import getPartnerConfig from 'khshared/metadata/getPartnerConfig';
import { CampaignEventFields } from 'khshared/types/campaigns/CampaignEvent';
import pino, { Logger } from 'pino';
import { MutableRefObject, useRef } from 'react';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { v4 as uuidv4 } from 'uuid';

import KHAuth from './KHAuth';
import mapCredentials from './mapCredentials';

export interface LoggerProps<E extends string> {
  logger?: Logger;
  loggerEventName?: E;
  logCampaignEvent?: (campaignEvent: CampaignEventFields & { eventName: E }) => void;
}

const PRODUCTION_KINESIS_DATA_STREAM_NAME = 'LoggingStream';
const DEVELOPMENT_KINESIS_DATA_STREAM_NAME = 'LoggingStream-dev';

function getUTMParams() {
  if (global.location == null) return {};

  const params = new URLSearchParams(global.location.search);
  const utm: Record<string, string> = {};
  for (const [key, value] of params) {
    if (!key.startsWith('utm_')) continue;
    const formattedKey = camelCase(key);
    utm[formattedKey ?? key] = value;
  }
  return utm;
}
let kinesis: Kinesis | null = null;

// eslint-disable-next-line @typescript-eslint/ban-types
let logStack: object[] = [];
let logger = pino({
  browser: {
    write: (o) => {
      // eslint-disable-next-line no-console
      if (__DEV__) console.log(`Logged ${(o as { eventName: string }).eventName}:`, o);
      logStack.push(o);
    },
  },
}).child({
  isFrontend: true,
  platform: Platform.OS,
  // The type of Platform.Version depends on Android or iOS; convert to string for all logging to prevent Kibana dropping the event
  platformVersion: Platform.Version?.toString(),
  ...(Platform.OS === 'ios'
    ? {
        iosAppVersion: DeviceInfo.getVersion(),
        iosAppBuildNumber: DeviceInfo.getBuildNumber(),
      }
    : {}),
  environment: __DEV__ ? 'development' : 'production',
  sessionID: uuidv4(),
  userAgent: global.navigator?.userAgent,
  deviceLanguages: global.navigator?.languages,
  ...getUTMParams(),
});

let credentials: CognitoIdentityCredentials | null = null;

(async () => {
  if (credentials == null) credentials = await mapCredentials();

  logger = logger.child({ identityID: credentials.identityId });
})();

let loggerInterval: number | null = null;
const NON_BACKOFF_DELAY = 3000;
let delayBetweenRequests = NON_BACKOFF_DELAY;
function sendLogsToServer() {
  if (logStack.length > 0) {
    if (kinesis == null)
      kinesis = new Kinesis({ credentials: mapCredentials, region: 'us-west-2' });
    kinesis.putRecords(
      {
        Records: logStack.map((log) => ({
          Data: new Uint8Array(
            Buffer.from(
              JSON.stringify({
                ...log,
                loggingEventUUID: uuidv4(),
              }),
            ),
          ),
          PartitionKey: 'logging',
        })),
        StreamName: __DEV__
          ? DEVELOPMENT_KINESIS_DATA_STREAM_NAME
          : PRODUCTION_KINESIS_DATA_STREAM_NAME,
      } as PutRecordsInput,
      (err, data) => {
        if (err != null || data?.FailedRecordCount !== 0) {
          if (delayBetweenRequests === NON_BACKOFF_DELAY) {
            delayBetweenRequests = 1000;
          } else if (delayBetweenRequests >= 32000) {
            delayBetweenRequests = 32000;
          } else {
            // exponential backoff + random jitter
            delayBetweenRequests += delayBetweenRequests + Math.random() * 1000;
          }
          if (loggerInterval != null) clearInterval(loggerInterval);
          loggerInterval = window.setInterval(sendLogsToServer, delayBetweenRequests);
          return;
        }

        // if there's no error, we've succeeded, clear all sent objects.
        logStack = data.Records != null ? logStack.slice(data.Records.length) : [];

        if (delayBetweenRequests !== NON_BACKOFF_DELAY) {
          delayBetweenRequests = NON_BACKOFF_DELAY;
          if (loggerInterval != null) clearInterval(loggerInterval);
          loggerInterval = window.setInterval(sendLogsToServer, delayBetweenRequests);
        }
      },
    );
  }
}
loggerInterval = window.setInterval(sendLogsToServer, delayBetweenRequests);

export default function useLogger(fields: { [field: string]: unknown } = {}): Logger {
  return logger.child({
    viewerGroups: KHAuth.getGroupsOrNull(),
    viewerID: KHAuth.getViewerIDOrNull(),
    viewerEmail: KHAuth.getEmailOrNull(),
    viewerPartnerOrganizations: KHAuth.getPartnerOrganizations(),
    viewerPartnerOrganizationGroup: getPartnerConfig(KHAuth.getPartnerOrganizations()[0])
      .partnerOrganizationGroup,
    clientVersion: process.env.REACT_APP_COMMIT_ID,
    ...releaseNameOrEmptyObject(),
    ...fields,
  });
}

// Useful when you want to log from within a hook that uses dependency lists for memoization (e.g.
// useEffect and useCallback).
export function useLoggerRef(fields: { [field: string]: unknown } = {}): MutableRefObject<Logger> {
  const latestLogger = useLogger(fields);
  const loggerRef = useRef(latestLogger);
  loggerRef.current = latestLogger;
  return loggerRef;
}
