import KHColors from 'khshared/KHColors';
import React, { ReactNode } from 'react';
import { StyleProp, Text, TextStyle } from 'react-native';

import KHConstants from './KHConstants';

const styles = {
  text: {
    marginHorizontal: KHConstants.GUTTER_WIDTH,
    marginTop: 16,
    marginBottom: 4,
    fontWeight: '500',
    color: KHColors.textChecklistTitle,
  } as TextStyle,
};

interface Props {
  style?: StyleProp<TextStyle>;
  title?: ReactNode;
}

export default function KHTitleField({ style, title }: Props): JSX.Element {
  return <Text style={[styles.text, style]}>{title}</Text>;
}
