export default function uploadFileToURL(
  file: Blob,
  url: string,
  contentType: string,
  handleProgress: (progress: number | null) => void,
): Promise<{ status: number; statusText: string }> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', contentType);
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve({ status: xhr.status, statusText: xhr.statusText });
      } else {
        reject(new Error(`Upload failed with status ${xhr.status}`));
      }
    };
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = event.loaded / event.total;
        handleProgress(progress);
      }
    };
    xhr.upload.onloadend = () => {
      handleProgress(1);
    };
    xhr.upload.onerror = () => {
      reject(new Error('Upload failed'));
    };

    xhr.send(file);
  });
}
