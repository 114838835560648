import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  sentryErrorSampleRate,
  sentryFrontEndDSN,
  sentryTransactionSampleRate,
} from 'khshared/constants';
import { releaseNameOrEmptyObject } from 'khshared/generateReleaseName';
import { AppRegistry } from 'react-native';

import App from './App';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import i18n from './utils/i18n';
import isProduction from './utils/isProduction';
import EnvXAPI from './xapis/EnvXAPI';

const sentryOptions: Record<string, string | number | Integrations.BrowserTracing[] | undefined> = {
  dsn: sentryFrontEndDSN,
  integrations: [new Integrations.BrowserTracing()],
  // Do not use process.env.NODE_ENV as it is always set to 'production' by the 'yarn run build' command ran in our Amplify pipeline.
  // Instead, please use the value of the environment variable 'REACT_APP_NODE_ENV'.
  // See: https://seanconnolly.dev/dont-be-fooled-by-node-env
  // And: https://stackoverflow.com/questions/42523175/using-node-env-with-multiple-environments-in-javascript-projects#:~:text=NODE_ENV%20is%20used%20to%20differentiate,'production'%20.
  environment: EnvXAPI.REACT_APP_NODE_ENV || 'development',
  // Transactions events sampling rate.
  tracesSampleRate: isProduction() ? sentryTransactionSampleRate : 0,
  // Sampling error events.
  sampleRate: isProduction() ? sentryErrorSampleRate : 0,
  ...releaseNameOrEmptyObject(),
};

Sentry.init(sentryOptions);

/* eslint-disable */
const MaterialCommunityIcons = require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf');

const iconFontStyles = `
@font-face {
  src: url('${MaterialCommunityIcons}');
  font-family: MaterialCommunityIcons;
}
`;
/* eslint-enable */

const style = document.createElement('style');
style.type = 'text/css';
style.appendChild(document.createTextNode(iconFontStyles));

// Inject stylesheet
document.head.appendChild(style);

const mapcss = document.createElement('link');
mapcss.rel = 'stylesheet';
mapcss.href = 'https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css';
document.head.appendChild(mapcss);

AppRegistry.registerComponent('sprinterhealthapp', () => App);
AppRegistry.runApplication('sprinterhealthapp', {
  rootTag: document.getElementById('root'),
});
