import KHColors from 'khshared/KHColors';
import * as React from 'react';
import { useContext } from 'react';
import {
  Modal,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import KHButton, { Props as KHButtonProps } from './KHButton';
import KHConstants from './KHConstants';
import KHTextInput from './KHTextInput';
import KHThemeContext from './KHThemeContext';

const styles = {
  closeButton: {
    alignSelf: 'flex-end',
    marginTop: -34,
    marginRight: -22,
  } as ViewStyle,
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: KHConstants.GUTTER_WIDTH,
  } as ViewStyle,
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: KHColors.overlayBackground,
  } as ViewStyle,
  dialog: {
    padding: KHConstants.GUTTER_WIDTH,
    minWidth: 300,
    maxWidth: 550,
    backgroundColor: KHColors.dialogBackground,
    borderRadius: 8,
  } as ViewStyle,
  dialogV2: {
    paddingHorizontal: 36,
    paddingVertical: 48,
    borderRadius: 24,
  } as ViewStyle,
  title: {
    fontSize: 18,
    marginBottom: 16,
  } as TextStyle,
  titleV2: {
    color: KHColors.referralBookingTitle,
    fontFamily: 'Domine',
    fontWeight: '700',
    fontSize: 24,
    letterSpacing: 0.16,
    lineHeight: 32,
    marginBottom: 16,
  } as TextStyle,
  paragraph: {
    marginVertical: 8,
  } as TextStyle,
  paragraphV2: {
    color: KHColors.referralText,
    fontFamily: 'Red Hat Display',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 22,
    letterSpacing: 0.16,
  } as TextStyle,
  content: {
    marginBottom: 8,
  } as ViewStyle,
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
    marginLeft: -8,
  } as ViewStyle,
  action: {
    marginLeft: 12,
  } as ViewStyle,
};

type Props = Pick<React.ComponentProps<typeof Modal>, 'onRequestClose'> & {
  closable?: boolean;
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  testID?: string;
};

export default function KHModalDialog({
  closable,
  children,
  onRequestClose,
  style,
  testID,
}: Props): JSX.Element {
  const theme = useContext(KHThemeContext);
  return (
    <Modal transparent animationType="fade" onRequestClose={onRequestClose}>
      <View style={styles.centered} testID={testID}>
        <Pressable style={styles.overlay} onPress={onRequestClose}>
          {null /* None needed */}
        </Pressable>
        <View style={[styles.dialog, theme === 'v2' && styles.dialogV2, style]}>
          {closable && (
            <KHButton
              textOnly
              icon="close"
              iconColor={KHColors.iconSecondary}
              onPress={onRequestClose}
              style={styles.closeButton}
            />
          )}
          {children}
        </View>
      </View>
    </Modal>
  );
}

KHModalDialog.Title = function KHModalDialogTitle({ children }: { children: React.ReactNode }) {
  const theme = useContext(KHThemeContext);
  return <Text style={[styles.title, theme === 'v2' && styles.titleV2]}>{children}</Text>;
};

KHModalDialog.Content = function KHModalDialogContent({ children }: { children: React.ReactNode }) {
  return <View style={styles.content}>{children}</View>;
};

KHModalDialog.Paragraph = function KHModalDialogParagraph({
  children,
  testID,
}: {
  children: React.ReactNode;
  testID?: string;
}) {
  const theme = useContext(KHThemeContext);
  return (
    <Text style={[styles.paragraph, theme === 'v2' && styles.paragraphV2]} testID={testID}>
      {children}
    </Text>
  );
};

KHModalDialog.TextInput = function KHModalDialogTextInput(
  props: React.ComponentProps<typeof KHTextInput>,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <KHTextInput {...props} />;
};

KHModalDialog.Actions = function KHModalDialogActions({
  primary,
  secondary,
  style,
}: {
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View style={[styles.actions, style]}>
      {secondary}
      {primary}
    </View>
  );
};

KHModalDialog.Action = function KHModalDialogAction<E extends `${string}ButtonClick`>({
  style,
  ...restProps
}: KHButtonProps<E>): JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <KHButton<E> style={[styles.action, style]} {...restProps} />;
};
