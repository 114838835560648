import KHColors from 'khshared/KHColors';
import React, { ReactNode } from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleProp,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { Route, useHistory } from 'react-router';

import logo from '../assets/logo_32h.png';
import KHAuth from '../utils/KHAuth';
import useLogger from '../utils/useLogger';
import generateURI from '../xapis/generateURIXAPI';
import KHIcon from './KHIcon';

const logoURI = generateURI(logo);

const styles = {
  sideBar: {
    width: 250,
    backgroundColor: KHColors.backgroundSideBar,
    height: '100%',
    paddingBottom: 24,
  } as ViewStyle,
  image: {
    marginVertical: 42,
    width: 250,
    height: 48,
    resizeMode: 'contain',
  } as ImageStyle,
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 24,
    marginTop: 32,
    height: 56,
  } as ViewStyle,
  itemMatched: {
    backgroundColor: KHColors.sideBarSelected,
  } as ViewStyle,
  itemLabel: {
    fontSize: 16,
    marginLeft: 16,
  } as TextStyle,
  spring: {
    flex: 1,
  } as ViewStyle,
};

function Item({
  icon,
  label,
  style,
  onPress,
  selected = false,
  testID,
}: {
  icon: string;
  label: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  selected?: boolean;
  testID?: string;
}): JSX.Element {
  return (
    <Pressable
      onPress={onPress}
      style={[styles.item, selected && styles.itemMatched, style]}
      testID={testID}
    >
      <KHIcon
        size={24}
        source={icon}
        color={selected ? KHColors.iconContrast : KHColors.iconPrimary}
      />
      <Text
        numberOfLines={1}
        style={[
          styles.itemLabel,
          { color: selected ? KHColors.textContrast : KHColors.textAccent },
        ]}
      >
        {label}
      </Text>
    </Pressable>
  );
}

function RouteItem({
  icon,
  to,
  label,
  exact = false,
  selected,
  style,
}: {
  icon: string;
  to: string;
  label: React.ReactNode;
  exact?: boolean;
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
}): JSX.Element {
  const history = useHistory();
  return (
    <Route path={to} exact={exact}>
      {({ match }) => (
        <Item
          selected={selected != null ? selected : match != null}
          icon={icon}
          label={label}
          style={style}
          onPress={() => history.push(to)}
        />
      )}
    </Route>
  );
}

// this element should be passed in in children
function Spring(): JSX.Element {
  return <View style={styles.spring} />;
}

function KHSideBar({ children, footer }: { children: ReactNode; footer?: ReactNode }): JSX.Element {
  const history = useHistory();
  const logger = useLogger();
  return (
    <View style={styles.sideBar}>
      <Image source={{ uri: logoURI }} style={styles.image} />
      {children}

      <Item
        onPress={async () => {
          await KHAuth.genSignOut(logger);
          history.push('/signin');
        }}
        label="Sign out"
        icon="exit-to-app"
        testID="sidebar-sign-out-item"
      />

      {footer}
    </View>
  );
}

export default Object.assign(KHSideBar, { Item, RouteItem, Spring });
