import KHColors from 'khshared/KHColors';
import React, { ReactNode, useContext } from 'react';
import { Linking, StyleProp, Text, TextStyle } from 'react-native';

import { LoggerProps } from '../utils/useLogger';
import KHThemeContext from './KHThemeContext';

const styles = {
  link: {
    color: KHColors.textAccent,
    textDecorationLine: 'underline',
  } as TextStyle,
  linkV2: {
    fontFamily: 'Red Hat Display',
    fontWeight: '500',
    letterSpacing: 0.16,
    textDecorationLine: 'none',
    color: KHColors.referralBookingContactText,
  } as TextStyle,
  linkSecondary: {
    color: KHColors.textSecondary,
    textDecorationLine: 'underline',
  } as TextStyle,
};

type Props<E extends `${string}LinkClick`> = LoggerProps<E> & {
  children?: ReactNode;
  style?: StyleProp<TextStyle>;
  href?: string;
  onPress?: () => void;
  secondary?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  testID?: string;
};

function constructTestIDForLink<E extends `${string}LinkClick`>(
  props: Props<E>,
): string | undefined {
  if (props.testID) return props.testID;
  if (typeof props.children === 'string') return `${props.children}-link`.toLowerCase();
  return undefined;
}

export default function KHLink<E extends `${string}LinkClick`>(
  props: Props<E>,
): JSX.Element | null {
  const {
    children,
    style,
    href,
    onPress,
    secondary,
    logger,
    loggerEventName,
    logCampaignEvent,
  } = props;
  const theme = useContext(KHThemeContext);
  return (
    <Text
      onPress={async () => {
        if (loggerEventName != null) {
          logger?.info({ eventName: loggerEventName });
          logCampaignEvent?.({ eventName: loggerEventName });
        }
        if (onPress) onPress();
        if (href) await Linking.openURL(href);
      }}
      style={[
        styles.link,
        theme === 'v2' && styles.linkV2,
        secondary && styles.linkSecondary,
        style,
      ]}
      testID={constructTestIDForLink(props)}
    >
      {children}
    </Text>
  );
}
