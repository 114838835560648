enum PartnerOrganizationGroup {
  'A1HOMEHEALTHANDHOSPICE' = 'A1HOMEHEALTHANDHOSPICE',
  'ABLHEALTHCARE' = 'ABLHEALTHCARE',
  'AFFORDABLEPATERNITY' = 'AFFORDABLEPATERNITY',
  'ALPHACARE' = 'ALPHACARE',
  'AMOREHOMEHEALTH' = 'AMOREHOMEHEALTH',
  'ASIANNETWORKPACIFICHOMECARE' = 'ASIANNETWORKPACIFICHOMECARE',
  'ASSISTONCALL' = 'ASSISTONCALL',
  'BESTHOMEHEALTH' = 'BESTHOMEHEALTH',
  'CAPITOLHEALTHCARE' = 'CAPITOLHEALTHCARE',
  'CAREINTOUCHHOMEHEALTHAGENCY' = 'CAREINTOUCHHOMEHEALTHAGENCY',
  'CAREMUST' = 'CAREMUST',
  'CEDARSSINAI' = 'CEDARSSINAI',
  'COMFORTHOMEHEALTHCARE' = 'COMFORTHOMEHEALTHCARE',
  'CONCIERGEMDLA' = 'CONCIERGEMDLA',
  'DEMOLAB' = 'DEMOLAB',
  'DEMOPROVIDER' = 'DEMOPROVIDER',
  'DISCOVERHEALTH' = 'DISCOVERHEALTH',
  'DRIPHYDRATION' = 'DRIPHYDRATION',
  'DRVILLIVALAM' = 'DRVILLIVALAM',
  'ELDERCAREMANAGEMENT' = 'ELDERCAREMANAGEMENT',
  'EMMANUELHEALTHCAREINC' = 'EMMANUELHEALTHCAREINC',
  'ESSENTIALSHOMEHEALTH' = 'ESSENTIALSHOMEHEALTH',
  'FOUNDATIONMEDICINE' = 'FOUNDATIONMEDICINE',
  'FIRSTCHOICEHOMEHEALTH' = 'FIRSTCHOICEHOMEHEALTH',
  'EXACTSCIENCES' = 'EXACTSCIENCES',
  'GEORGIAHANDSHOULDERELBOW' = 'GEORGIAHANDSHOULDERELBOW',
  'GRAIL' = 'GRAIL',
  'GUARDANT' = 'GUARDANT',
  'GUARDIANANGELHOMECARE' = 'GUARDIANANGELHOMECARE',
  'HARMONYHH' = 'HARMONYHH',
  'HOMEHEALTHBAYAREAINC' = 'HOMEHEALTHBAYAREAINC',
  'HEALTHFLEX' = 'HEALTHFLEX',
  'JOHNMUIRHEALTH' = 'JOHNMUIRHEALTH',
  'KANEHEALTH' = 'KANEHEALTH',
  'KRISTARAMONASMD' = 'KRISTARAMONASMD',
  'INTOUCHHOMECARE' = 'INTOUCHHOMECARE',
  'LEBAE' = 'LEBAE',
  'MARSHALLMEDICALCENTER' = 'MARSHALLMEDICALCENTER',
  'NATERA' = 'NATERA',
  'PINNACLEHOMEHEALTH' = 'PINNACLEHOMEHEALTH',
  'RUPAHEALTH' = 'RUPAHEALTH',
  'SEHAJHOMEHEALTH' = 'SEHAJHOMEHEALTH',
  'SILVERADOBELMONTMEMORYCARE' = 'SILVERADOBELMONTMEMORYCARE',
  'STPATRICKHOMEHEALTH' = 'STPATRICKHOMEHEALTH',
  'AAHCKINDRED' = 'AAHCKINDRED',
  'HALESPORTS' = 'HALESPORTS',
  'LONETREEPOSTACUTE' = 'LONETREEPOSTACUTE',
  'MERIDIANHOMEHEALTH' = 'MERIDIANHOMEHEALTH',
  'MYDOCTORMEDICALGROUP' = 'MYDOCTORMEDICALGROUP',
  'NAVERIS' = 'NAVERIS',
  'NORTHBAYPRIVATEMEDICINE' = 'NORTHBAYPRIVATEMEDICINE',
  'NURSINGANDREHABATHOME' = 'NURSINGANDREHABATHOME',
  'OPOHEALTH' = 'OPOHEALTH',
  'FIREFLYHEALTH' = 'FIREFLYHEALTH',
  'OSCAR' = 'OSCAR',
  'OSCARDEMO' = 'OSCARDEMO',
  'PENINSULADOCTOR' = 'PENINSULADOCTOR',
  'PERPETUALCAREHOMEHEALTH' = 'PERPETUALCAREHOMEHEALTH',
  'PRIMELIVINGHOMEHEALTH' = 'PRIMELIVINGHOMEHEALTH',
  'PULSEDEMO' = 'PULSEDEMO',
  'READY2NURSE' = 'READY2NURSE',
  'SILVERLINESTAFF' = 'SILVERLINESTAFF',
  'SOCOLMD' = 'SOCOLMD',
  'SONDERHEALTHCARE' = 'SONDERHEALTHCARE',
  'SUNCRESTHOSPICE' = 'SUNCRESTHOSPICE',
  'ALLIANCEHOMEHEALTH' = 'ALLIANCEHOMEHEALTH',
  'TRUEMED' = 'TRUEMED',
  'UCSF' = 'UCSF',
  'VNHOMEHEALTHCARE' = 'VNHOMEHEALTHCARE',
  'YOURCHOICEHOMEHEALTH' = 'YOURCHOICEHOMEHEALTH',
  'BMCDEMO' = 'BMCDEMO',
  'BOSTONMEDICALCENTER' = 'BOSTONMEDICALCENTER',
  'D2C' = 'D2C',
  'APOLLOHOMEHEALTH' = 'APOLLOHOMEHEALTH',
  'STANFORDCANCERCENTER' = 'STANFORDCANCERCENTER',
  'WELLSENSE' = 'WELLSENSE',
  'BRADFORDRABINMDANDHIROYUHATANOMD' = 'BRADFORDRABINMDANDHIROYUHATANOMD',
  'THEBODYWRX' = 'THEBODYWRX',
  'HOMEHEALTHRESOURCEGROUP' = 'HOMEHEALTHRESOURCEGROUP',
  'STANFORDRESEARCH' = 'STANFORDRESEARCH',
  'STORYHEALTH' = 'STORYHEALTH',
  'INTERMOUNTAINCANCERCENTER' = 'INTERMOUNTAINCANCERCENTER',
  'TRAININGPARTNER' = 'TRAININGPARTNER',
  'ALIGNMENTHEALTH' = 'ALIGNMENTHEALTH',
  'MOLINA' = 'MOLINA',
  'WELLSENSENH' = 'WELLSENSENH',
  'UCI' = 'UCI',
  'ELEVANCEHEALTH' = 'ELEVANCEHEALTH',
  'CENTENE' = 'CENTENE',
  'UHC' = 'UHC',
  'HUMANA' = 'HUMANA',
  'ACCOMPANYHEALTH' = 'ACCOMPANYHEALTH',
  'UCLA' = 'UCLA',
}

export default PartnerOrganizationGroup;
