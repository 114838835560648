type DeeplyMutableWithoutTypename<T> = {
  -readonly [P in keyof T]: P extends '__typename' ? never : DeeplyMutableWithoutTypename<T[P]>;
};

function omitTypename<K extends string, V>(key: K, value: V): V | undefined {
  return key === '__typename' ? undefined : value;
}

export default function cloneAsInput<T>(object: T): DeeplyMutableWithoutTypename<T> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return JSON.parse(JSON.stringify(object), omitTypename);
}
