import { PartnerOrganization } from './API';
import { hasPatientEngagementCSRGroup } from './cognitoUtils';
import { SUPPORT_EMAIL } from './constants';
import getPartnerConfig from './metadata/getPartnerConfig';

export default function getPartnerOrganizationsFromCognitoGroups(
  cognitoGroups: string[],
): PartnerOrganization[] {
  const partnerOrganizations = cognitoGroups.filter((cognitoGroup) =>
    Object.values(PartnerOrganization).find((partnerOrg) => cognitoGroup === partnerOrg),
  ) as PartnerOrganization[];

  // Allow CSRs to access multiple partners
  if (hasPatientEngagementCSRGroup(cognitoGroups)) return partnerOrganizations;

  if (partnerOrganizations.length <= 0) {
    return [];
  }
  const { partnerOrganizationGroup } = getPartnerConfig(partnerOrganizations[0]);

  const areAuthorizedPartnerOrganizationsMutuallyCompatible = partnerOrganizations.every(
    (partnerOrg) =>
      getPartnerConfig(partnerOrg).partnerOrganizationGroup === partnerOrganizationGroup,
  );

  if (!areAuthorizedPartnerOrganizationsMutuallyCompatible)
    throw new Error(
      `Your account is not authorized to access these patients. Please reach out to Sprinter Health Support at ${SUPPORT_EMAIL}.`,
    );

  return partnerOrganizations;
}
