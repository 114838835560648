import KHColors from 'khshared/KHColors';
import React, { useContext } from 'react';
import { TextStyle, View, ViewStyle } from 'react-native';

import KHButton, { Props as KHButtonProps } from './KHButton';
import KHThemeContext from './KHThemeContext';

const styles = {
  button: {
    borderRadius: 0,
  } as ViewStyle,
  tabButtonLabelV2: {
    fontWeight: '500',
    fontSize: 14,
    fontFamily: 'Red Hat Text',
    paddingVertical: 4,
    letterSpacing: 0.5,
    marginHorizontal: 0,
    marginBottom: 0,
    paddingBottom: 0,
    color: KHColors.tabButtonTextV2,
  } as TextStyle,
  underline: {
    height: 2,
    backgroundColor: KHColors.tabButtonUnderline,
  } as ViewStyle,
  underlineV2: {
    visibility: 'hidden',
  } as ViewStyle,
  underlineSelected: {
    backgroundColor: KHColors.tabButtonUnderlineSelected,
  } as ViewStyle,
  underlineSelectedV2: {
    visibility: 'visible',
    backgroundColor: KHColors.tabButtonTextSelectedV2,
  } as ViewStyle,
  textSelected: {
    color: KHColors.tabButtonTextSelected,
  } as TextStyle,
  textSelectedV2: {
    color: KHColors.tabButtonTextSelectedV2,
  } as TextStyle,
};

type Props<E extends `${string}ButtonClick`> = Omit<KHButtonProps<E>, 'primary'> & {
  selected?: boolean;
};

export default function KHTabButton<E extends `${string}ButtonClick`>({
  selected,
  style,
  ...khButtonProps
}: Props<E>): JSX.Element {
  const theme = useContext(KHThemeContext);
  return (
    <View style={style}>
      <KHButton<E>
        textOnly
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...khButtonProps}
        labelStyle={[
          theme === 'v2' && styles.tabButtonLabelV2,
          khButtonProps.labelStyle,
          selected && (theme === 'v2' ? styles.textSelectedV2 : styles.textSelected),
        ]}
        style={styles.button}
      />
      <View
        style={[
          styles.underline,
          theme === 'v2' && styles.underlineV2,
          selected && (theme === 'v2' ? styles.underlineSelectedV2 : styles.underlineSelected),
        ]}
      />
    </View>
  );
}
