import React, { ComponentProps, ReactNode } from 'react';
import { StyleProp, TextStyle } from 'react-native';

import formatError from '../utils/formatError';
import KHErrorText from './KHErrorText';
import KHIcon from './KHIcon';
import KHMessageView from './KHMessageView';

const styles = {
  error: {
    fontSize: 14,
    marginBottom: 24,
    width: '100%',
  } as TextStyle,
};

interface Props {
  children?: ReactNode;
  error: Parameters<typeof formatError>[0];
  icon?: ComponentProps<typeof KHIcon>['source'];
  message: ReactNode;
  style?: StyleProp<TextStyle>;
  verbose?: boolean;
}

export default function KHErrorView({
  children,
  error,
  icon,
  message,
  style,
  verbose = __DEV__ && process.env.AWS_BRANCH !== 'demonet',
}: Props): JSX.Element | null {
  return error == null ? null : (
    <KHMessageView icon={icon} title="Uh-Oh" message={message} style={style}>
      {verbose && <KHErrorText error={error} verbose={verbose} style={styles.error} />}
      {children}
    </KHMessageView>
  );
}
