/**
 * This function currently is a placeholder for the react native version `genResizedImageURLOrNullXAPI.native.ts`.
 *
 * Currently, we do not resize images on the web. We may want to add this in the future to optimize Sprinter checklist performance.
 *
 */

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/require-await */
export default async function genResizedImageURLOrNullXAPI(
  _uri: string,
  _maxWidth: number,
  _maxHeight: number,
  _quality: number,
  _rotation?: number,
): Promise<string | null> {
  /* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/require-await */
  return null;
}
