import KHColors from 'khshared/KHColors';
import React, { ReactNode, useState } from 'react';
import { Pressable, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import { Menu } from 'react-native-paper';

import useComponentSize from '../utils/useComponentSize';
import KHErrorText from './KHErrorText';
import KHIcon from './KHIcon';
import KHText from './KHText';

const styles = {
  frame: {
    minHeight: 24,
    minWidth: 32,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: KHColors.backgroundContrast,
    padding: 12,
  } as ViewStyle,
  outline: {
    borderWidth: 1,
    borderColor: KHColors.textInputOutline,
    borderRadius: 4,
    backgroundColor: KHColors.backgroundContrast,
  } as ViewStyle,
  menu: {
    marginTop: 48,
  } as ViewStyle,
  icon: {
    marginLeft: 4,
    alignSelf: 'center',
  } as ViewStyle,
  label: {
    marginBottom: 4,
  } as TextStyle,
  disabled: {
    backgroundColor: KHColors.dropdownBackgroundDisabled,
  } as ViewStyle,
  content: {
    borderWidth: 1,
    borderColor: KHColors.textInputOutline,
    borderRadius: 4,
  } as ViewStyle,
  item: {
    padding: 12,
  } as ViewStyle,
  hovered: {
    backgroundColor: KHColors.dropDownHovered,
  } as ViewStyle,
  caption: {
    marginTop: 4,
  } as ViewStyle,
  error: {
    marginTop: 4,
  } as ViewStyle,
};

export interface Option<V> {
  label: string;
  value: V;
}

interface Props<V> {
  options: Option<V>[];
  onChange: (value: V) => void;
  selectedOption: V | null;
  disabled?: boolean;
  title?: ReactNode;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  outline?: 'outlined' | 'none';
  placeholder?: string;
  expansionMode?: 'overlay' | 'inline';
  testID?: string;
  caption?: string; //  todo: test this on mobile
  error?: string; //  todo: test this on mobile
}

function InlineItem({
  onPress,
  label,
  style,
}: {
  onPress: () => void;
  label: string;
  style?: StyleProp<ViewStyle>;
}): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Pressable
      onPress={onPress}
      style={[style, styles.item, isHovered && styles.hovered]}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
    >
      <Text>{label}</Text>
    </Pressable>
  );
}

function OverlayItem({
  onPress,
  label,
  style,
  menuItemStyle,
}: {
  onPress: () => void;
  label: string;
  style: StyleProp<ViewStyle>;
  menuItemStyle: StyleProp<ViewStyle>;
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Pressable
      style={[isHovered && styles.hovered, style]}
      onHoverIn={() => setIsHovered(true)}
      onHoverOut={() => setIsHovered(false)}
      onPress={onPress}
    >
      <Menu.Item title={label} contentStyle={menuItemStyle} />
    </Pressable>
  );
}

export default function KHDropDown<V>({
  options,
  onChange,
  selectedOption,
  disabled = false,
  title,
  style,
  outline = 'outlined',
  placeholder,
  expansionMode = 'overlay',
  testID,
  titleStyle,
  caption,
  error,
}: Props<V>): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  const label = options.find((opt) => opt.value === selectedOption)?.label ?? placeholder;

  const { height, width, onLayout } = useComponentSize();

  // This allows the inner menu item to fill the entire width.
  const menuItemMaxWidth = width !== null && width > 20 ? width - 20 : null;

  return (
    <View style={style} testID={testID}>
      {title && <Text style={[styles.label, titleStyle]}>{title}</Text>}

      <Menu
        visible={isExpanded && !disabled}
        onDismiss={() => setIsExpanded(false)}
        anchor={
          <Pressable
            style={[
              styles.frame,
              outline === 'outlined' && styles.outline,
              disabled && styles.disabled,
            ]}
            onPress={() => setIsExpanded((e) => !e)}
            disabled={disabled}
            onLayout={onLayout}
          >
            <Text style={disabled && { color: KHColors.buttonDisabledLabel }}>{label}</Text>
            <KHIcon
              size={16}
              source={isExpanded ? 'chevron-up' : 'chevron-down'}
              style={styles.icon}
            />
          </Pressable>
        }
        contentStyle={[styles.content, outline === 'outlined' && { borderTopWidth: 0 }]}
        style={{ marginTop: height } as ViewStyle}
      >
        {expansionMode === 'overlay' &&
          options.map((option, i) => (
            <OverlayItem
              label={option.label}
              key={String(option.value)}
              onPress={() => {
                onChange(option.value);
                setIsExpanded(false);
              }}
              style={[
                { width } as ViewStyle,
                i === 0 && { marginTop: -10 },
                i === options.length - 1 && { marginBottom: -10 },
              ]}
              menuItemStyle={{ maxWidth: menuItemMaxWidth } as ViewStyle}
            />
          ))}
      </Menu>
      {expansionMode === 'inline' && isExpanded && (
        <View style={outline === 'outlined' && styles.outline}>
          {options.map((option) => (
            <InlineItem
              label={option.label}
              key={String(option.value)}
              style={{ width: '100%' }}
              onPress={() => {
                onChange(option.value);
                setIsExpanded(false);
              }}
            />
          ))}
        </View>
      )}
      {caption && <KHText.Caption style={styles.caption}>{caption}</KHText.Caption>}
      {error && <KHErrorText style={styles.error} error={error} />}
    </View>
  );
}
