import KHColors from 'khshared/KHColors';
import React, { ReactNode } from 'react';
import {
  ImageSourcePropType,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { Route, useHistory } from 'react-router';

import KHIcon from './KHIcon';

const styles = {
  view: {
    flexDirection: 'row',
    padding: 16,
    backgroundColor: KHColors.bottomNavigationBackground,
    borderTopColor: KHColors.separator,
    borderTopWidth: StyleSheet.hairlineWidth,
  } as ViewStyle,
  button: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  buttonLabel: {
    fontSize: 12,
    fontWeight: '500',
    marginTop: 2,
  } as TextStyle,
};

interface Props {
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
}

export default function KHBottomNavigation({ children, style }: Props): JSX.Element {
  return <View style={[styles.view, style]}>{children}</View>;
}

interface ButtonProps {
  icon: string | ImageSourcePropType;
  label: string;
  to: string;
}

KHBottomNavigation.Button = function KHBottomNavigationButton({
  icon,
  label,
  to,
}: ButtonProps): JSX.Element {
  const history = useHistory();
  return (
    <Route path={to}>
      {({ match }) => (
        <Pressable onPress={() => history.push(to)} style={styles.button}>
          <KHIcon
            size={24}
            source={icon}
            color={match ? KHColors.bottomNavigationSelected : KHColors.bottomNavigation}
          />
          <Text
            numberOfLines={1}
            style={[
              styles.buttonLabel,
              { color: match ? KHColors.bottomNavigationSelected : KHColors.bottomNavigation },
            ]}
          >
            {label}
          </Text>
        </Pressable>
      )}
    </Route>
  );
};
