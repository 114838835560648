import { TimeRangeRuleInclusion, TimeRangeRuleInput, TimeRangeRulePeriod } from '../API';

export default function createHolidayExclusionRule(
  id: string,
  startDateISO: string,
  endDateISO: string,
): TimeRangeRuleInput {
  return {
    id,
    inclusion: TimeRangeRuleInclusion.EXCLUDE,
    recurrenceStart: startDateISO,
    recurrenceEnd: endDateISO,
    start: '00:00:00',
    end: '24:00:00',
    recurrenceMultiple: 1,
    recurrencePeriod: TimeRangeRulePeriod.DAY,
  };
}
