import { TypedDocumentNode, gql } from '@apollo/client';
import { S3ObjectParentType } from 'khshared/API';
import assertExhaustiveSwitch from 'khshared/assertExhaustiveSwitch';
import {
  genChildDocumentURL_appointment,
  genChildDocumentURL_appointmentQueryVariables,
  genChildDocumentURL_care_navigation_episode,
  genChildDocumentURL_care_navigation_episodeQueryVariables,
  genChildDocumentURL_encounter,
  genChildDocumentURL_encounterQueryVariables,
  genChildDocumentURL_patient,
  genChildDocumentURL_patientQueryVariables,
  genChildDocumentURL_referral,
  genChildDocumentURL_referralQueryVariables,
  genChildDocumentURL_remote_tech,
  genChildDocumentURL_remote_techQueryVariables,
  genChildDocumentURL_tech,
  genChildDocumentURL_techQueryVariables,
  genChildDocumentURL_unlinkable_report,
  genChildDocumentURL_unlinkable_reportQueryVariables,
} from 'khshared/graphql/types';

const GET_APPOINTMENT_CHILD_DOCUMENT_URL: TypedDocumentNode<
  genChildDocumentURL_appointment,
  genChildDocumentURL_appointmentQueryVariables
> = gql`
  query genChildDocumentURL_appointment($parentID: ID!, $s3ObjectID: ID!, $shouldFlatten: Boolean) {
    getAppointment(id: $parentID) {
      childDocumentURL(s3ObjectID: $s3ObjectID, shouldFlatten: $shouldFlatten) {
        url
      }
    }
  }
`;
const GET_ENCOUNTER_CHILD_DOCUMENT_URL: TypedDocumentNode<
  genChildDocumentURL_encounter,
  genChildDocumentURL_encounterQueryVariables
> = gql`
  query genChildDocumentURL_encounter($parentID: ID!, $s3ObjectID: ID!, $shouldFlatten: Boolean) {
    getEncounter(id: $parentID) {
      childDocumentURL(s3ObjectID: $s3ObjectID, shouldFlatten: $shouldFlatten) {
        url
      }
    }
  }
`;
const GET_PATIENT_CHILD_DOCUMENT_URL: TypedDocumentNode<
  genChildDocumentURL_patient,
  genChildDocumentURL_patientQueryVariables
> = gql`
  query genChildDocumentURL_patient($parentID: ID!, $s3ObjectID: ID!, $shouldFlatten: Boolean) {
    getPatient(id: $parentID) {
      childDocumentURL(s3ObjectID: $s3ObjectID, shouldFlatten: $shouldFlatten) {
        url
      }
    }
  }
`;

const GET_TECH_CHILD_DOCUMENT_URL: TypedDocumentNode<
  genChildDocumentURL_tech,
  genChildDocumentURL_techQueryVariables
> = gql`
  query genChildDocumentURL_tech($parentID: ID!, $s3ObjectID: ID!, $shouldFlatten: Boolean) {
    getTech(id: $parentID) {
      childDocumentURL(s3ObjectID: $s3ObjectID, shouldFlatten: $shouldFlatten) {
        url
      }
    }
  }
`;

const GET_REMOTE_TECH_CHILD_DOCUMENT_URL: TypedDocumentNode<
  genChildDocumentURL_remote_tech,
  genChildDocumentURL_remote_techQueryVariables
> = gql`
  query genChildDocumentURL_remote_tech($parentID: ID!, $s3ObjectID: ID!, $shouldFlatten: Boolean) {
    getRemoteTech(id: $parentID) {
      childDocumentURL(s3ObjectID: $s3ObjectID, shouldFlatten: $shouldFlatten) {
        url
      }
    }
  }
`;

const GET_CARE_NAVIGATION_EPISODE_CHILD_DOCUMENT_URL: TypedDocumentNode<
  genChildDocumentURL_care_navigation_episode,
  genChildDocumentURL_care_navigation_episodeQueryVariables
> = gql`
  query genChildDocumentURL_care_navigation_episode(
    $parentID: ID!
    $s3ObjectID: ID!
    $shouldFlatten: Boolean
  ) {
    getCareNavigationEpisode(id: $parentID) {
      childDocumentURL(s3ObjectID: $s3ObjectID, shouldFlatten: $shouldFlatten) {
        url
      }
    }
  }
`;

const GET_UNLINKABLE_REPORT_CHILD_DOCUMENT_URL: TypedDocumentNode<
  genChildDocumentURL_unlinkable_report,
  genChildDocumentURL_unlinkable_reportQueryVariables
> = gql`
  query genChildDocumentURL_unlinkable_report(
    $parentID: ID!
    $s3ObjectID: ID!
    $shouldFlatten: Boolean
  ) {
    getUnlinkableReport(id: $parentID) {
      childDocumentURL(s3ObjectID: $s3ObjectID, shouldFlatten: $shouldFlatten) {
        url
      }
    }
  }
`;

const GET_REFERRAL_CHILD_DOCUMENT_URL: TypedDocumentNode<
  genChildDocumentURL_referral,
  genChildDocumentURL_referralQueryVariables
> = gql`
  query genChildDocumentURL_referral($parentID: ID!, $s3ObjectID: ID!, $shouldFlatten: Boolean) {
    getReferral(id: $parentID) {
      childDocumentURL(s3ObjectID: $s3ObjectID, shouldFlatten: $shouldFlatten) {
        url
      }
    }
  }
`;

type QueryType =
  | typeof GET_APPOINTMENT_CHILD_DOCUMENT_URL
  | typeof GET_ENCOUNTER_CHILD_DOCUMENT_URL
  | typeof GET_PATIENT_CHILD_DOCUMENT_URL
  | typeof GET_TECH_CHILD_DOCUMENT_URL
  | typeof GET_REMOTE_TECH_CHILD_DOCUMENT_URL
  | typeof GET_CARE_NAVIGATION_EPISODE_CHILD_DOCUMENT_URL
  | typeof GET_UNLINKABLE_REPORT_CHILD_DOCUMENT_URL
  | typeof GET_REFERRAL_CHILD_DOCUMENT_URL;

export default function getChildDocumentQueryByParentType(
  parentType: S3ObjectParentType,
): QueryType {
  switch (parentType) {
    case S3ObjectParentType.APPOINTMENT:
      return GET_APPOINTMENT_CHILD_DOCUMENT_URL;
    case S3ObjectParentType.ENCOUNTER:
      return GET_ENCOUNTER_CHILD_DOCUMENT_URL;
    case S3ObjectParentType.PATIENT:
      return GET_PATIENT_CHILD_DOCUMENT_URL;
    case S3ObjectParentType.TECH:
      return GET_TECH_CHILD_DOCUMENT_URL;
    case S3ObjectParentType.REMOTE_TECH:
      return GET_REMOTE_TECH_CHILD_DOCUMENT_URL;
    case S3ObjectParentType.CARE_NAVIGATION_EPISODE:
      return GET_CARE_NAVIGATION_EPISODE_CHILD_DOCUMENT_URL;
    case S3ObjectParentType.UNLINKABLE_REPORT:
      return GET_UNLINKABLE_REPORT_CHILD_DOCUMENT_URL;
    case S3ObjectParentType.REFERRAL:
      return GET_REFERRAL_CHILD_DOCUMENT_URL;
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return assertExhaustiveSwitch(parentType);
  }
}
