import { useCallback, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';

/**
 * A hook to get a component's size on runtime
 *
 * @returns width, height, onLayout callback
 */

export default function useComponentSize(): {
  width: number | null;
  height: number | null;
  onLayout: (event: LayoutChangeEvent) => void;
} {
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { width: newWidth, height: newHeight } = event.nativeEvent.layout;
    setWidth(newWidth);
    setHeight(newHeight);
  }, []);

  return { width, height, onLayout };
}
