import { DateTime } from 'luxon';
import React, { MutableRefObject, forwardRef, useEffect, useState } from 'react';
import { TextInput } from 'react-native';
import { Masks, formatWithMask } from 'react-native-mask-input';

import KHTextInput from './KHTextInput';

/* eslint-disable react/require-default-props */
type Props = Omit<React.ComponentProps<typeof KHTextInput>, 'value' | 'onChangeText'> & {
  onChangeDate?: (date: DateTime | null) => void;
  date?: DateTime | null;
  placeholder?: string;
  errorMessage?: string;
};
/* eslint-enable react/require-default-props */
const nextYear = parseInt(DateTime.now().plus({ year: 1 }).year.toString().slice(2), 10);

const KHDateTextInput = forwardRef<TextInput, Props>(
  (
    {
      onChangeDate,
      date,
      placeholder = 'mm/dd/yyyy',
      errorMessage = 'Please enter a valid date in the form mm/dd/yyyy',
      ...khTextInputProps
    }: Props,
    ref: ((instance: TextInput | null) => void) | MutableRefObject<TextInput | null> | null,
  ): JSX.Element => {
    const [dateString, setDateString] = useState<string>('');
    const [dateError, setDateError] = useState<string | null>(null);

    useEffect(() => {
      if (date) setDateString(date.toFormat('MM/dd/yyyy'));
      // way to externally change the state since otherwise there's no way to clear this from outside this component
      else if (date === undefined) setDateString('');
    }, [date]);

    return (
      <KHTextInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...khTextInputProps}
        placeholder={placeholder}
        ref={ref}
        keyboardType="number-pad"
        onChangeText={(input) => {
          let text = input;
          // if the first character isn't a 0 or 1, assume it should be a zero
          if (input.length === 1 && parseInt(input, 10) >= 2) {
            text = `0${input}`;
          }
          // if the first character of the day isn't the first digit, inject a 0 as well
          if (input.length === 4 && parseInt(input[3], 10) >= 4) {
            text = `${input.slice(0, 3)}0${input[3]}`;
          }
          // if a slash is cutting off a single digit, inject a 0
          if (input.length === 2 && input[1] === '/') {
            text = `0${input[0]}/`;
          }
          // same for day
          if (input.length === 5 && input[4] === '/') {
            text = `${input.slice(0, 3)}0${input[3]}/`;
          }

          const maskedDate = formatWithMask({ text, mask: Masks.DATE_MMDDYYYY }).masked;
          setDateString(maskedDate);
          const dateObject = DateTime.fromFormat(maskedDate, 'MM/dd/yyyy');
          if (dateObject.isValid) {
            onChangeDate?.(dateObject);
            setDateError(null);
          } else onChangeDate?.(null);
        }}
        onBlur={() => {
          // two digit year hack
          if (dateString.length === 8) {
            const year = parseInt(dateString.slice(6), 10);
            const alteredDateString = `${dateString.slice(0, 6)}${
              year > nextYear ? '19' : '20'
            }${dateString.slice(6, 8)}`;
            const dateObject = DateTime.fromFormat(alteredDateString, 'MM/dd/yyyy');
            if (dateObject.isValid) {
              setDateString(alteredDateString);
              onChangeDate?.(dateObject);
              setDateError(null);
            } else setDateError(errorMessage);
            return;
          }

          if (date == null && dateString.length !== 0) setDateError(errorMessage);
          else setDateError(null);
        }}
        error={dateError ?? khTextInputProps.error}
        caption={khTextInputProps.caption}
        value={dateString}
      />
    );
  },
);

export default KHDateTextInput;
