import { S3ObjectParentType } from 'khshared/API';
import { Logger } from 'pino';
import { useEffect, useState } from 'react';

import genChildDocumentURL from './genChildDocumentURL';

type FetchManyFilesParams = {
  mode: 'fetchMany';
  list:
    | {
        parentID: string;
        parentType: S3ObjectParentType;
        s3ObjectID?: string | null;
      }[]
    | null
    | undefined;
  setParentToChildURLMap: (map: Record<string, string>) => void;
};

type FetchSingleFileParams = {
  mode: 'fetchSingle';
  s3ObjectID: string | null | undefined;
  parentID: string | null | undefined;
  parentType: S3ObjectParentType | null | undefined;
  setFileURL: (url: string) => void;
};

type FetchFileURLParams = (FetchManyFilesParams | FetchSingleFileParams) & { logger: Logger };

export default function useFetchFileURLsByS3ObjectIDs(
  params: FetchFileURLParams,
): { isLoading: boolean; error: Error | null } {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const shouldFetchKey =
    params.mode === 'fetchMany'
      ? params.list?.length
        ? `${params.mode}|${params.list
            .map((item) => item.s3ObjectID)
            .sort()
            .join('|')}`
        : null
      : params.s3ObjectID != null
      ? `${params.mode}|${params.s3ObjectID}`
      : null;

  useEffect(() => {
    if (!shouldFetchKey) return;
    let fetchFunction;
    if (params.mode === 'fetchMany') {
      const { list, setParentToChildURLMap } = params;
      fetchFunction = async () => {
        if (list == null) return;
        try {
          setIsLoading(true);
          const listWithURLs = (
            await Promise.allSettled(
              list.map(async (item) => {
                if (item.s3ObjectID == null) return null;
                const url = await genChildDocumentURL({
                  parentID: item.parentID,
                  parentType: item.parentType,
                  s3ObjectID: item.s3ObjectID,
                  logger: params.logger,
                });
                return { id: item.parentID, url };
              }),
            )
          ).filter(
            (result): result is PromiseFulfilledResult<{ id: string; url: string }> =>
              result.status === 'fulfilled',
          );

          const map = listWithURLs.reduce((agg, item) => {
            // eslint-disable-next-line no-param-reassign
            agg[item.value.id] = item.value.url;
            return agg;
          }, {} as Record<string, string>);

          setParentToChildURLMap(map);
        } catch (e: unknown) {
          setError(e as Error);
        } finally {
          setIsLoading(false);
        }
      };
    } else {
      const { parentID, parentType, s3ObjectID, setFileURL } = params;
      if (!s3ObjectID || !parentID || !parentType) return;
      fetchFunction = async () => {
        try {
          setIsLoading(true);
          const url = await genChildDocumentURL({
            parentID,
            parentType,
            s3ObjectID,
            logger: params.logger,
          });
          setFileURL(url);
        } catch (e: unknown) {
          setError(e as Error);
        } finally {
          setIsLoading(false);
        }
      };
    }
    void fetchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchKey]);

  return { isLoading, error };
}
