import 'intl-pluralrules';

import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { captureException } from '@sentry/react';
import getErrorLoggingFields from 'khshared/getErrorLoggingFields';
import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { StatsigProvider, StatsigUser } from 'statsig-react-native';

import AppRouter from './AppRouter';
import LoadingScreen from './screens/LoadingScreen';
import { initializeApolloClient } from './utils/ApolloClient';
import { initCurrentPosition } from './utils/getCurrentPositionOrNull';
import { i18nPromise } from './utils/i18n';
import isProduction from './utils/isProduction';
import KHAuth from './utils/KHAuth';
import setupFontAwesome from './utils/setupFontAwesome';
import useLogger from './utils/useLogger';
import EnvXAPI from './xapis/EnvXAPI';

const theme = {
  ...DefaultTheme,
  dark: false,
};

setupFontAwesome();

export default function App(): React.ReactElement {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(
    null,
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const [bottomScreenOffset, setBottomScreenOffset] = useState<string>('');
  const logger = useLogger();

  function updateApolloClient() {
    setApolloClient(initializeApolloClient());
  }

  useEffect(() => {
    // stale chunk retry
    window.addEventListener?.('error', (e) => {
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        window.location.reload();
      }
    });

    function handleResize() {
      if (window.innerHeight)
        setBottomScreenOffset(`calc(100vh - ${window.innerHeight + window.scrollY}px)`);
    }

    window.addEventListener?.('resize', handleResize);
    window.addEventListener?.('scroll', handleResize);
    handleResize();

    (async () => {
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        initCurrentPosition();
      }

      await KHAuth.genInitialize(logger);
      updateApolloClient();
      try {
        await i18nPromise;
      } catch (error) {
        logger.error({ eventName: 'i18nInitializationError', ...getErrorLoggingFields(error) });
        captureException(error);
      }

      KHAuth.eventEmitter.on('signIn', updateApolloClient);
      KHAuth.eventEmitter.on('signOut', updateApolloClient);

      setIsInitialized(true);
    })();

    return () => {
      KHAuth.eventEmitter.off('signIn', updateApolloClient);
      KHAuth.eventEmitter.off('signOut', updateApolloClient);
      window.removeEventListener?.('resize', handleResize);
      window.removeEventListener?.('scroll', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (apolloClient == null || !isInitialized) return <LoadingScreen />;

  const user: StatsigUser = {
    privateAttributes: {
      email: KHAuth.getEmailOrNull() ?? undefined,
    },
  };
  // client-*** is the filler key to pass to statsig in case our key does not work so that an error is not thrown
  const statsigApiKey =
    process.env.REACT_APP_STATSIG_CLIENT_KEY_PROD ??
    EnvXAPI.REACT_APP_STATSIG_CLIENT_KEY_PROD ??
    'client-e';

  return (
    <StatsigProvider
      sdkKey={statsigApiKey}
      waitForInitialization={false}
      user={user}
      options={{
        environment: { tier: isProduction() ? 'production' : 'development' },
        disableCurrentPageLogging: true,
        disableErrorLogging: true,
        disableDiagnosticsLogging: true,
        disableAutoMetricsLogging: true,
      }}
    >
      <ApolloProvider client={apolloClient}>
        <PaperProvider theme={theme}>
          <AppRouter />
          <View style={bottomScreenOffset.length > 0 && { height: bottomScreenOffset }} />
        </PaperProvider>
      </ApolloProvider>
    </StatsigProvider>
  );
}
