import { captureException } from '@sentry/react';
import { HOME_PAGE_URL, HOUR_IN_MILLISECONDS, TESTNET_PAGE_URL } from 'khshared/constants';
import nullThrows from 'nullthrows';
import { useState } from 'react';
import { Platform } from 'react-native';

import getAppVersionOrNull from './getAppVersionOrNull';
import isProduction from './isProduction';

const DEFAULT_POLLING_INTERVAL = 2 * HOUR_IN_MILLISECONDS;

const VERSION_PATH = `${isProduction() ? HOME_PAGE_URL : TESTNET_PAGE_URL}/versions.json`;

type LatestAppVersions = { minimumPreferred: number | null };

let timer: NodeJS.Timer | null = null;

async function genFetchPreferredAppVersions(): Promise<LatestAppVersions> {
  try {
    const response = await fetch(VERSION_PATH);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = await response.json();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const latestAppVersion = result[Platform.OS][isProduction() ? 'prod' : 'dev'];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return nullThrows(latestAppVersion, 'Unable to fetch app version');
  } catch (e) {
    captureException(e);
    return { minimumPreferred: null };
  }
}

export default function useAppVersion(): [
  showWarningDialog: boolean,
  setDismissWarningDialog: () => void,
] {
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  async function genFetchAppVersionsWithUpdate() {
    const { minimumPreferred } = await genFetchPreferredAppVersions();
    const currentAppVersion = getAppVersionOrNull();

    const doesAppNeedUpdate =
      currentAppVersion == null || minimumPreferred == null
        ? false
        : minimumPreferred > currentAppVersion;

    if (doesAppNeedUpdate) {
      setShowWarningDialog(true);
    }
  }

  if (timer == null) {
    void genFetchAppVersionsWithUpdate();

    timer = setInterval(() => {
      void genFetchAppVersionsWithUpdate();
    }, DEFAULT_POLLING_INTERVAL);
  }

  const dismissWarning = () => setShowWarningDialog(false);

  return [showWarningDialog, dismissWarning];
}
