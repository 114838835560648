import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { TextStyle, View, ViewStyle } from 'react-native';

import KHConstants from './KHConstants';

const styles = {
  container: {
    rowGap: 24,
  } as ViewStyle,
  gutter: {
    marginHorizontal: KHConstants.GUTTER_WIDTH,
  } as ViewStyle,
  row: {
    flexDirection: 'row',
    columnGap: 24,
  } as ViewStyle,
  cell: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  } as ViewStyle,
};

type Props = ComponentPropsWithoutRef<typeof View> & {
  children?: ReactNode;
  center?: boolean;
};

type ContainerProps = Props & {
  columnCount?: number;
  gutter?: boolean;
};

type CellProps = Props & {
  columnCount?: number;
};

function Container({
  center,
  columnCount,
  gutter = true,
  ...restProps
}: ContainerProps): JSX.Element {
  const style = {
    ...styles.container,
    ...(columnCount && { flex: columnCount }),
    ...(gutter && styles.gutter),
    ...(center && { textAlign: 'center' }),
  } as TextStyle;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <View style={style} {...restProps} />
  );
}

function Row({ center, style, ...restProps }: Props): JSX.Element {
  const computedStyle = {
    ...styles.row,
    ...(center && { textAlign: 'center' }),
  } as TextStyle;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <View style={[style, computedStyle]} {...restProps} />
  );
}

function Cell({ center, style, columnCount = 1, ...restProps }: CellProps): JSX.Element {
  const computedStyle = {
    ...styles.cell,
    ...(style as ViewStyle),
    ...{ flex: columnCount },
    ...(center && { textAlign: 'center', justifyContent: 'center' }),
  } as TextStyle;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <View style={computedStyle} {...restProps} />
  );
}

export default { Container, Row, Cell };
